import { SalesFetchProps } from '../../types/api'
import { TIME } from '../../utils/misc'
import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '../../constants/query-keys'
import { fetchSalesHourly } from '../../queries/sales/hourly'

export function useSalesHourly({
    env,
    accessToken,
    location,
    filters,
    dynamicSubPath,
}: SalesFetchProps): any {
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.SALES_HOURLY, location],
        () =>
            fetchSalesHourly({
                env,
                accessToken,
                location,
                filters,
                dynamicSubPath,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
