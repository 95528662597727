import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollLocationWeeklyData } from 'queries/payroll/location/location'
import { PayrollFetchProps } from 'types/api'
import { IResponse, apiNotEnabled } from 'utils/api'
import { isStore } from 'utils/location'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function usePayrollLocationWeekly({
    env,
    accessToken,
    location,
    dynamicSubPath,
}: PayrollFetchProps): IResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const enabled = isStore(location)
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.PAYROLL_LOCATION_WEEKLY, location],
        () =>
            fetchPayrollLocationWeeklyData({
                env,
                accessToken,
                location,
                dynamicSubPath,
                trackEvent,
            }),
        {
            enabled,
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
        },
    )

    if (!enabled) {
        return apiNotEnabled()
    }

    return { data, isLoading, isError, refetch, remove, error }
}
