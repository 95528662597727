import { createContext, useContext, useState } from 'react'

import { NearbyLocation } from '../../queries/getNearbyLocationsQuery/getNearbyLocationsQuery'

interface NearbyLocationsContextType {
    nearbyLocations: NearbyLocation[]
    setNearbyLocations: (nearbyLocations: NearbyLocation[]) => void
}

const NearbyLocationsContext = createContext<
    NearbyLocationsContextType | undefined
>(undefined)

interface NearbyLocationsProps {
    children: JSX.Element | JSX.Element[] | string
}

export const NearbyLocationsProvider = ({ children }: NearbyLocationsProps) => {
    const [nearbyLocations, setNearbyLocations] = useState<NearbyLocation[]>([])
    const nearbyLocationsProvider = {
        nearbyLocations,
        setNearbyLocations,
    }

    return (
        <NearbyLocationsContext.Provider value={nearbyLocationsProvider}>
            {children}
        </NearbyLocationsContext.Provider>
    )
}

export const useNearbyLocations = () => {
    const nearbyLocationsContext = useContext(NearbyLocationsContext)

    if (nearbyLocationsContext === undefined) {
        throw new Error(
            'useNearbyLocations must be used within a NearbyLocationsProvider',
        )
    }

    return nearbyLocationsContext
}
