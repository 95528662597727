import { format, parseISO } from 'date-fns'

export const stripInitialTAndLeadingZeroes = (input: string) =>
    input.replace(/^t?/i, '').replace(/^0+/, '')

export const convertToDisplayFriendlyDateString = (timestamp: string) => {
    try {
        const parsedDate = parseISO(timestamp)
        return format(parsedDate, 'MMM d, yyyy').toLowerCase()
    } catch {
        return timestamp
    }
}

export const decodeHtmlEntities = (str: string) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = str
    return textArea.value
}
