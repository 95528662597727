import { Table } from '@enterprise-ui/canvas-ui-react'
import { Container } from 'components/container/Container'
import { buildDataTableObject } from 'utils/data'
import {
    addHyperLinkToFirstColumn,
    getDynamicSubPath,
    setFirstColumnInHeaders,
} from './helpers'
import { useSafetyLagging } from 'hooks/safety/useSafetyLagging'
import { getTableFormat } from 'utils/table'
import { BasicPlaceholder } from 'components/placeholder'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback/error-alert'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { translateTableData } from 'locales/utils'
import { useEssentials } from 'hooks/utils/useEssentials'

export const SafetyLagging = () => {
    const { t, currentLocation, pathname, env, logout, session } =
        useEssentials()

    const dynamicSubPath =
        getDynamicSubPath(currentLocation, false) || 'Company'
    let { data, isLoading, isError, refetch, remove, error } = useSafetyLagging(
        {
            env,
            accessToken: session?.accessToken ?? '',
            location: currentLocation,
            dynamicSubPath,
        },
    )
    useRefresh(refetch, remove)
    const count = useCountdown(5, logout, error as Error)

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="table" rows={5} columns={5} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    const { safety } = TRANSLATION_KEYS
    const { nonStoreLabel, lagging } = safety
    const { title } = lagging

    let derivedData = { ...data }
    derivedData = translateTableData({
        t,
        table: derivedData,
        translation: lagging,
    })
    derivedData.headings = setFirstColumnInHeaders(derivedData, 'Location')
    derivedData = addHyperLinkToFirstColumn(derivedData, pathname)

    return (
        <Container>
            <p>{t(nonStoreLabel)}</p>
            <Table
                data={buildDataTableObject({
                    currentData: derivedData,
                    widths: [
                        100,
                        ...getTableFormat(data?.rows?.length ?? 20, 100),
                    ],
                    pinFirstColumn: true,
                })}
                name={t(title)}
                scrollableWidth={true}
            />
        </Container>
    )
}
