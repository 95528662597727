import { BasicPlaceholder } from 'components/placeholder'
import { useAssetsProtectionSecureStore } from 'hooks/assets-protection/useAssetsProtectionSecureStore'
import { useEssentials } from 'hooks/utils/useEssentials'
import { getLocationFilters } from 'utils/api'
import { Grid, Table } from '@enterprise-ui/canvas-ui-react'
import { buildDataTableObject, TitledTableData } from 'utils/data'
import { ErrorAlert } from 'components/error-fallback'
import useCountdown from 'hooks/utils/useCountdown'

export const SecureStore = () => {
    const { t, currentLocation, env, session, logout } = useEssentials()
    const filters = getLocationFilters(currentLocation)
    let { data, isLoading, isError, error } = useAssetsProtectionSecureStore({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        filters,
    })
    const count = useCountdown(5, logout, error as Error)

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="table" rows={3} columns={3} />
            </div>
        )
    }
    if (isError) return <ErrorAlert error={error as Error} count={count} />
    return (
        <div>
            {data?.map((titledTable: TitledTableData) => {
                const { title, table } = titledTable
                return (
                    <Grid.Container className="hc-pv-md">
                        <Grid.Item xs={12} className="hc-pb-none">
                            <p className="hc-pa-min hc-lh-expanded hc-txt-lowercase">
                                {t(title)}
                            </p>
                        </Grid.Item>
                        <Grid.Item xs={12} className="hc-pt-none">
                            <Table
                                data={buildDataTableObject({
                                    currentData: table,
                                    widths: [200, 100, 100],
                                })}
                                name={t(title)}
                            />
                        </Grid.Item>
                    </Grid.Container>
                )
            })}
        </div>
    )
}
