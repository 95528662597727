import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSafetyLeading } from 'queries/safety/safety'
import { ShortageFetchProps } from 'types/api'
import { IResponse } from 'utils/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function useSafetyLeading({
    env,
    accessToken,
    location,
    dynamicSubPath,
}: ShortageFetchProps): IResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.SAFETY_LEADING, location],
        () =>
            fetchSafetyLeading({
                env,
                accessToken,
                location,
                dynamicSubPath,
                trackEvent,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
