import { useState } from 'react'
import { Table } from '@enterprise-ui/canvas-ui-react'
import {
    usePayrollWorkgroup,
    usePayrollWorkgroupDrill,
} from 'hooks/payroll/workgroup'
import {
    TableData,
    addLinkToFirstColumn,
    buildDataTableObject,
    insertRows,
    removeLinkFromFirstColumn,
} from 'utils/data'
import { getTableFormat } from 'utils/table'
import { Container } from 'components/container/Container'
import { BasicPlaceholder } from 'components/placeholder'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const Workgroup = () => {
    const [parent, setParent] = useState<string>('')
    const { t, currentLocation, env, logout, session } = useEssentials()
    const { payroll } = TRANSLATION_KEYS
    let { data, isLoading, isError, refetch, remove, error } =
        usePayrollWorkgroup({
            env,
            accessToken: session?.accessToken ?? '',
            location: currentLocation,
        })
    useRefresh(refetch, remove)
    const count = useCountdown(5, logout, error as Error)

    const { data: subData } = usePayrollWorkgroupDrill({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        dynamicSubPath: parent,
    })

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
                <BasicPlaceholder type="table" rows={8} columns={3} />
            </div>
        )
    }

    if (isError) {
        return <ErrorAlert error={error as Error} count={count} />
    }
    const widths = [120, ...getTableFormat(data?.rows?.length ?? 20, 105)]

    const addChildRows = (data: TableData, subData: TableData) => {
        subData.rows = removeLinkFromFirstColumn(subData.rows)
        subData.rows = subData.rows.map((row) => {
            row[0].childOf = parent

            return row
        })
        const insertIndex =
            data.rows.findIndex((row) => row[0].value === parent) + 1
        const newData = { ...data }
        let newDataRows = [...data.rows]
        insertRows(newDataRows, insertIndex, subData.rows)
        newData.rows = newDataRows
        data = newData

        return data
    }
    if (data) {
        data.rows = addLinkToFirstColumn(data.rows)
        if (subData) {
            data = addChildRows(data, subData)
        }
    }

    const handleClick = (parentValue: string) => {
        if (data) {
            const match = data?.rows.filter(
                (row) => row[0].childOf === parentValue,
            )
            if (parent === parentValue || match.length > 0) {
                const filtered = data?.rows.filter(
                    (row) => row[0].childOf !== parentValue,
                )
                data.rows = filtered
                setParent('')
            } else {
                setParent(parentValue)
            }
        }
    }

    return (
        <Container>
            <p className="hc-fs-md">{t(payroll.workgroup.title)}</p>
            <Table
                data={buildDataTableObject({
                    currentData: data!,
                    widths,
                    handleClick,
                    pinFirstColumn: true,
                })}
                scrollableWidth={true}
            />
        </Container>
    )
}
