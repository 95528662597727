import { Layout } from 'components/layout/layout'
import { t } from 'i18next'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { TrailerSummaryTab } from './trailer-summary'
import { TrailerScheduleTab } from './trailer-schedule'
import { isStore } from 'utils/location'
import { useEssentials } from 'hooks/utils/useEssentials'
import { NonStore } from './NonStore'

export const TrailerPage = () => {
    const { trailer } = TRANSLATION_KEYS
    const { tabs: tabsKeys } = trailer
    const tabs = [t(tabsKeys.summary), t(tabsKeys.schedule)]
    const { currentLocation } = useEssentials()

    const [activeTab, setActiveTab] = useState<string>(tabs[0])
    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)
    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(trailer.title)}
                />
            )}
        >
            <Layout heading={t(trailer.title)}>
                {isStore(currentLocation) ? (
                    <Tabs
                        activeTab={activeTab}
                        className="tabs hc-pv-dense"
                        justified
                        disableAdaptive={true}
                        onTabSelect={handleTabSelect}
                    >
                        {tabs.map((tab: string) => (
                            <Tabs.Item
                                className="hc-bg-tabsItem hc-tw-balance hc-pr-sm hc-pl-sm hc-lh-dense"
                                key={tab}
                                name={tab}
                            >
                                {t(tab)}
                            </Tabs.Item>
                        ))}
                        <Tabs.Content className="tabsContent" name={tabs[0]}>
                            {activeTab === tabs[0] && <TrailerSummaryTab />}
                        </Tabs.Content>
                        <Tabs.Content className="tabsContent" name={tabs[1]}>
                            {activeTab === tabs[1] && <TrailerScheduleTab />}{' '}
                        </Tabs.Content>
                    </Tabs>
                ) : (
                    <NonStore key={currentLocation} />
                )}
            </Layout>
        </ErrorBoundary>
    )
}
