import { ApiSubUrls, Apis } from 'enums/api-constants'
import { NotesData } from 'pages/notes/utils/notes'
import { fetchGeneralData } from 'queries/fetchData'

export const fetchNotes = fetchGeneralData<NotesData>(
    `${ApiSubUrls[Apis.NOTES]}`,
)
export const addNoteItem = fetchGeneralData<NotesData>(
    `${ApiSubUrls[Apis.NOTES]}`,
)
export const updateNoteItem = fetchGeneralData<NotesData>(
    `${ApiSubUrls[Apis.NOTES]}`,
)
