import { TFunction } from 'i18next'
import { Cell, TableData, TitledTableData } from 'utils/data'
import { TRANSLATION_KEYS as baseKeys } from './translation-keys'
import { ITranslationKeys } from './ITranslationKeys'

interface ITranslate {
    t: TFunction<'translation', undefined>
    translation: object
}
interface ITranslateItem extends ITranslate {
    item: string
}
interface ITranslateTableData extends ITranslate {
    table: TableData
}
interface ITranslateTitledTableData extends ITranslate {
    data: TitledTableData
}
type StringKeysInObject<T> = {
    [K in keyof T]: T[K] extends string ? K : never
}[keyof T]

export const translateItem = ({ t, item, translation }: ITranslateItem) => {
    type TranslationStringKeys = StringKeysInObject<ITranslationKeys>
    if (item in translation) {
        return t(translation[item as keyof typeof translation])
    } else if (item in baseKeys) {
        const key = item as TranslationStringKeys
        return t(baseKeys[key])
    }

    return item
}

export const translateTableData = ({
    t,
    table,
    translation,
}: ITranslateTableData) => {
    table.headings = table.headings?.map((heading: Cell) => {
        const key = heading.value
        const translatedValue = translateItem({ t, item: key, translation })
        return { ...heading, value: translatedValue }
    })
    table.rows = table.rows.map((row: Cell[]) => {
        let firstCell = row[0]
        let otherCells = row.slice(1)
        const key = firstCell.value
        const translatedValue = translateItem({ t, item: key, translation })
        firstCell.value = translatedValue

        return [firstCell, ...otherCells]
    })

    return table
}
export const translateTitledTableData = ({
    t,
    data,
    translation,
}: ITranslateTitledTableData) => {
    let { title, table } = data
    table = translateTableData({ t, table, translation })
    title = translateItem({ t, item: title, translation })

    return { title, table }
}
