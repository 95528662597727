import { useState } from 'react'
import { GeneralizedComponent } from 'components/generalized-component/generalized-component'
import { useTargetCircleCard } from 'hooks/loyalty/useTargetCircleCard'
import { useEssentials } from 'hooks/utils/useEssentials'
import {
    addHyperLinkToFirstColumn,
    buildTableDataForTargetCircleCardNonStore,
    pickColumns,
} from './helpers'
import { BasicPlaceholder } from 'components/placeholder'
import { ErrorAlert } from 'components/error-fallback'
import useCountdown from 'hooks/utils/useCountdown'
import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { format, parseISO } from 'date-fns'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { messages } from './messages'

export const TargetCircleCardNonStore = () => {
    const [timePeriodIndex, setTimePeriodIndex] = useState<number>(0)
    const [tabsIndex, setTabsIndex] = useState<number>(0)
    const { currentLocation, logout, env, pathname, session, t } =
        useEssentials()
    const { data, isLoading, isError, error } = useTargetCircleCard({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
    })
    const count = useCountdown(5, logout, error as Error)

    const { loyalty } = TRANSLATION_KEYS
    const { circleCard } = loyalty

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
                <BasicPlaceholder type="table" rows={8} columns={3} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    const { recentData, archiveData } = data
    const { current_week, previous_week } = recentData
    const { month_to_date, year_to_date } = archiveData

    const currentWeekErrorState = !current_week.metrics.all.goal
    const previousWeekErrorState = !previous_week.metrics.all.goal
    const isDataErrorState =
        (timePeriodIndex === 0 && currentWeekErrorState) ||
        (timePeriodIndex === 1 && previousWeekErrorState)

    let currentWeekData =
        buildTableDataForTargetCircleCardNonStore(current_week)
    let previousWeekData =
        buildTableDataForTargetCircleCardNonStore(previous_week)
    let monthToDateData =
        buildTableDataForTargetCircleCardNonStore(month_to_date)
    let yearToDateData = buildTableDataForTargetCircleCardNonStore(year_to_date)

    if (!isDataErrorState) {
        currentWeekData = addHyperLinkToFirstColumn(
            currentWeekData,
            currentLocation,
            pathname,
        )
        previousWeekData = addHyperLinkToFirstColumn(
            previousWeekData,
            currentLocation,
            pathname,
        )
    }
    monthToDateData = addHyperLinkToFirstColumn(
        monthToDateData,
        currentLocation,
        pathname,
    )

    yearToDateData = addHyperLinkToFirstColumn(
        yearToDateData,
        currentLocation,
        pathname,
    )
    const dataByTimePeriod = [
        { title: '', data: currentWeekData },
        { title: '', data: previousWeekData },
        { title: '', data: monthToDateData },
        { title: '', data: yearToDateData },
    ]
    const viewInPercent = dataByTimePeriod.map(({ title, data }) => ({
        title,
        subtitle: '',
        data: {
            summary: pickColumns(data, [0, 1, 2, 3]),
            store: pickColumns(data, [0, 4, 5, 6]),
            online: pickColumns(data, [0, 7, 8, 9]),
        },
    }))
    const payload = {
        'view in %': viewInPercent,
    }
    const timePeriods = [
        'current week',
        'last week',
        'month to date',
        'year to date',
    ]
    const currentTimePeriod = timePeriods[timePeriodIndex]

    const objectsByTimePeriod = [
        current_week,
        previous_week,
        month_to_date,
        year_to_date,
    ]

    type Metrics = {
        goal: number
        total_applications: number
        variance_percentage: number
    }
    const totals = Object.values(objectsByTimePeriod[timePeriodIndex].metrics)[
        tabsIndex
    ] as Metrics
    const totalApplications = totals.total_applications?.toLocaleString()
    const forecastApplications = totals.goal?.toLocaleString()

    let lastApplication = ''
    if (timePeriodIndex === 0 && !isDataErrorState) {
        lastApplication = format(
            parseISO(current_week.last_updated),
            'MMM d, h:mma',
        )
    }
    const completionPercent = (totals.total_applications / totals.goal) * 100

    return (
        <GeneralizedComponent
            payload={payload}
            isLoading={isLoading}
            scrollableWidth={false}
            widths={[90, 90, 90, 100]}
            expandededWidth={false}
            parentHandleClick={(timePeriodIndex: number, tabsIndex: number) => {
                setTimePeriodIndex(timePeriodIndex)
                setTabsIndex(tabsIndex)
            }}
            isError={isDataErrorState}
            title={currentTimePeriod}
            titleClassName={'hc-fs-lg'}
        >
            <div className="hc-mv-md">
                {lastApplication.length > 0 && (
                    <p className="hc-mb-md hc-ta-center">{`Last application received ${lastApplication}`}</p>
                )}
                <p className="flex-sb hc-mb-xs">
                    <span>{t(circleCard.applications)}</span>
                    <span>
                        {messages.applicationNumbers(
                            totalApplications,
                            forecastApplications,
                        )}
                    </span>
                </p>
                <ProgressBar
                    percentComplete={completionPercent}
                    className={
                        timePeriodIndex > 0 && completionPercent < 100
                            ? 'incomplete'
                            : ''
                    }
                />
            </div>
        </GeneralizedComponent>
    )
}
