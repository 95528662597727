import { Layout } from 'components/layout'
import { isStore } from 'utils/location'
import { SafetyNonStore } from './safety-nonstore'
import { SafetyStore } from './safety-store'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const SafetyPage = () => {
    const { t, currentLocation } = useEssentials()
    const isStoreFlag = isStore(currentLocation)

    const safetyLayoutClass = isStoreFlag ? '' : 'hc-pa-none'
    const { safety } = TRANSLATION_KEYS
    const { title } = safety

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(title)}
                />
            )}
        >
            <Layout heading={t(title)} className={safetyLayoutClass}>
                {isStoreFlag ? <SafetyStore /> : <SafetyNonStore />}
            </Layout>
        </ErrorBoundary>
    )
}
