import { Grid } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon from '@enterprise-ui/icons'
import { SplitRowProps } from './types'

/**
 * A Simple dual content row for use within a Canvas Grid.Container.
 * The basic layout is
 * --------------------------------------------
 * | icon |  start content  |   end content   |
 * --------------------------------------------
 * The icon + start content width will be equal to the end content width.
 *
 * @param props {@link SplitRowProps}
 * @returns JsxElement
 */
export const SplitRow = ({
    icon,
    start,
    end,
    paddingClass = 'hc-pv-xs',
}: SplitRowProps) => {
    const rowPadding = paddingClass
    return (
        <>
            <Grid.Item xs={6} className={rowPadding}>
                <Grid.Container justify="flex-start" align="center">
                    <Grid.Item className="hc-pr-none">
                        <EnterpriseIcon
                            icon={icon}
                            size="md"
                            className="hc-icon-center"
                        />
                    </Grid.Item>
                    <Grid.Item className="hc-pv-min">{start}</Grid.Item>
                </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={6} className={rowPadding}>
                {end}
            </Grid.Item>
        </>
    )
}
