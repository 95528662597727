import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchNotes } from 'queries/notes/notes'
import { NotesFetchProps } from 'types/api'
import { IResponseNotes } from 'utils/api'
import { isStore } from 'utils/location'
import { TIME } from 'utils/misc'
import { isToday, isBefore, parseISO } from 'date-fns'

export function useNotes({
    env,
    accessToken,
    location,
    date,
}: NotesFetchProps): IResponseNotes {
    const { tenMinutes } = TIME
    const enabled = isStore(location)

    const parsedDate = parseISO(date)
    const today = new Date()

    const isPastDate =
        isBefore(parsedDate, today) && !isToday(parsedDate) ? 'true' : 'false'

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.NOTES, location, date],
        () =>
            fetchNotes({
                env,
                accessToken,
                filters: {
                    location_id: location.slice(1),
                    is_past_date: isPastDate,
                    date,
                },
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            enabled,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
