import { useState } from 'react'
import { useSwipeToIndex } from '../../components/generalized-component/useSwipeToIndex'
import { Card } from '@enterprise-ui/canvas-ui-react'
import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'

interface CardSliderProps {
    placements: {
        block: string
        aisle: string
        schematic_number: string
        section: string
        capacity: string
        is_home_location: boolean
    }[]
}

export const CardSlider: React.FC<CardSliderProps> = ({ placements }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const { t } = useEssentials()
    const { itemSearch } = TRANSLATION_KEYS
    const { placementCapacityDescription } = itemSearch

    const { handleTouchStart, handleTouchMove, handleTouchEnd } =
        useSwipeToIndex({
            timePeriods: placements.map((_: any, index: any) =>
                index.toString(),
            ),
            setTimePeriodIndex: setCurrentIndex,
        })

    return (
        <div
            className="hc-mt-normal hc-mh-normal card-slider-container"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {placements.map((placement: any, index: number) => (
                <div
                    key={index}
                    // cannot be moved to scss file due to string literal template in transform
                    style={{
                        transform: `translateX(-${currentIndex * 100}%)`,
                    }}
                    className="card-slider"
                >
                    <Card className="hc-mr-normal placements-card">
                        <span className="hc-pt-normal hc-fs-2x">
                            <strong>
                                {placement.block}
                                {placement.aisle}
                            </strong>
                        </span>
                        <span>{placement.schematic_number}</span>
                        <span className="hc-pt-dense hc-pb-normal">
                            {placement.capacity}
                            {t(placementCapacityDescription)}
                        </span>
                    </Card>
                </div>
            ))}
        </div>
    )
}
