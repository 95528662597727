import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Layout } from 'components/layout'
import { clearLocationParam } from 'utils/helpers'
import { BasicPlaceholder } from 'components/placeholder'
import { Card, Input, ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { ErrorAlert, ErrorFallback } from 'components/error-fallback'
import { useEssentials } from 'hooks/utils/useEssentials'
import { useNotes } from 'hooks/notes/useNotes'
import { parseStoreMetrics } from '../../pages/notes/utils/parseStoreMetrics'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { ErrorBoundary } from 'react-error-boundary'
import { NoteItem } from './note-types'
import { NotesItems } from './notes-items'

import './notes.css'
import './notes.scss'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addNoteItem, updateNoteItem } from 'queries/notes/notes'
import { MethodConstants } from 'constants/constants'
import { QueryKeys } from 'constants/query-keys'
import { getLegacyNotes } from './helpers'

export const NotesPage = () => {
    const { t, params, setParams, currentLocation } = useEssentials()
    const { notes } = TRANSLATION_KEYS

    window.onpopstate = () => {
        clearLocationParam({ params, setParams })
    }

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t('notes')}
                />
            )}
        >
            <Layout heading={t(notes.notes)} breadcrumbsEnabled={false}>
                <Notes key={currentLocation} />
            </Layout>
        </ErrorBoundary>
    )
}

const Notes = () => {
    const { t, currentLocation, env, session } = useEssentials()
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
    const formattedDate = formatSelectedDate(selectedDate)
    const { notes: notesKeys } = TRANSLATION_KEYS
    const { items } = notesKeys
    const { todo, attendance, notes: notesItems } = items

    const { data, isLoading, isError, error } = useNotes({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        date: formattedDate,
    })

    const [notes, setNotes] = useState<NoteItem[]>([])

    useEffect(() => {
        if (data) {
            setNotes(data.notes_items)
        }
    }, [data])

    const queryClient = useQueryClient()
    const noteItemAddMutation = useMutation({
        mutationFn: (body: NoteItem) => {
            return addNoteItem({
                env,
                accessToken: session?.accessToken ?? '',
                filters: {
                    location_id: currentLocation.slice(1),
                    is_past_date: 'false',
                    date: formattedDate,
                },
                method: MethodConstants.POST,
                body,
            })
        },
        onSuccess: () =>
            queryClient.invalidateQueries([
                QueryKeys.NOTES,
                currentLocation,
                formattedDate,
            ]),
    })

    const noteItemUpdateMutation = useMutation({
        mutationFn: (body: NoteItem) => {
            return updateNoteItem({
                env,
                accessToken: session?.accessToken ?? '',
                filters: {
                    location_id: currentLocation.slice(1),
                    is_past_date: 'false',
                    date: formattedDate,
                },
                dynamicSubPath: '/' + body.id,
                method: MethodConstants.PUT,
                body,
            })
        },
        onSuccess: () =>
            queryClient.invalidateQueries([
                QueryKeys.NOTES,
                currentLocation,
                formattedDate,
            ]),
    })

    if (isLoading) {
        return (
            <div data-testid="placeholder">
                <BasicPlaceholder type="text" rows={4} />
            </div>
        )
    }
    if (isError || !data) {
        return <ErrorAlert error={error as Error} />
    }

    const metrics = parseStoreMetrics(data)

    const isMutationInProgress =
        noteItemAddMutation.isLoading || noteItemUpdateMutation.isLoading

    const legacyNotes = data.legacy_notes[0].notes
    const legacyNotesExist = legacyNotes.length > 0

    return (
        <div>
            <CustomDatePicker
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
            />
            <ProgressBars metrics={metrics} />
            <Weather metrics={metrics} />
            <KeyStatistics metrics={metrics} />

            <div>
                {[todo, attendance, notesItems].map((item, index) => (
                    <NotesItems
                        title={t(item.title)}
                        addLabel={t(item.label)}
                        notes={notes}
                        setNotes={setNotes}
                        handleAdd={noteItemAddMutation.mutate}
                        handleUpdate={noteItemUpdateMutation.mutate}
                        formattedDate={formattedDate}
                        isInProgress={isMutationInProgress}
                        type={t(item.key)}
                        key={t(item.key) + JSON.stringify(notes)}
                        isCheckboxEnabled={index === 0}
                    />
                ))}
            </div>

            {legacyNotesExist && (
                <div className="legacy-notes">
                    <h3 className="hc-mt-lg">
                        {t(notesKeys.legacy.title)}{' '}
                        <small>{t(notesKeys.legacy.subtitle)}</small>
                    </h3>
                    <Input.Textarea
                        id="legacy_notes"
                        value={getLegacyNotes([legacyNotes[0]])}
                        disabled
                        grow
                    />
                </div>
            )}
        </div>
    )
}

const formatSelectedDate = (selectedDate: Date | null) => {
    return selectedDate
        ? format(selectedDate, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd')
}

const CustomDatePicker = ({
    selectedDate,
    setSelectedDate,
}: {
    selectedDate: Date | null
    setSelectedDate: (date: Date | null) => void
}) => {
    const { t } = useEssentials()
    const { notes } = TRANSLATION_KEYS
    return (
        <div className="custom-datepicker-wrapper">
            <DatePicker
                id="notes-date-picker"
                label={''}
                onUpdate={(id, value) => setSelectedDate(value)}
                value={selectedDate}
                errorText={t(notes.errorText)}
            />
            <h3>{format(selectedDate ?? new Date(), 'MMMM d, yyyy')}</h3>
        </div>
    )
}

const ProgressBars = ({ metrics }: { metrics: any }) => {
    const { t } = useEssentials()
    const { notes } = TRANSLATION_KEYS

    return (
        <>
            <p className="progress-bar-row">
                <span>{t(notes.sales)}</span>
                <span>{`$${metrics.salesValue.toLocaleString()} ${t(notes.of)} $${metrics.forecastValue.toLocaleString()}`}</span>
            </p>
            <ProgressBar
                percentComplete={Math.min(metrics.salesProgress, 100)}
            />
            <p>
                <span>
                    {'comp: '}
                    <span className={metrics.compPercStyle}>
                        {metrics.formattedCompPerc}
                    </span>
                </span>
            </p>
            <p className="progress-bar-row">
                <span>{t(notes.payroll)}</span>
                <span>{`${metrics.payrollUsed} ${t(notes.of)} ${metrics.payrollPlanned} ${t(notes.hours)}`}</span>
            </p>
            <ProgressBar
                percentComplete={Math.min(metrics.payrollProgress, 100)}
            />
            <p>
                <span className={metrics.flexPlanStyle}>
                    {metrics.formattedFlexPlan}
                </span>
                {' ' + t(notes.flexPlan)}
            </p>
        </>
    )
}

const Weather = ({ metrics }: { metrics: any }) => {
    const { t } = useEssentials()
    const { notes } = TRANSLATION_KEYS

    return (
        <div>
            <h3 style={{ marginBottom: '0px' }}>{t(notes.weather)}</h3>
            <Card>
                <div className="weather-section">
                    <div className="weather-content">
                        {metrics.weatherIconUrl && (
                            <img
                                src={metrics.weatherIconUrl}
                                alt=""
                                className="weather-icon"
                            />
                        )}
                        <div>
                            <p className="hc-mb-none">
                                {`${t(notes.high)}:`} {`${metrics.maxTemp}° F`}
                            </p>
                            <p className="hc-mb-none">
                                {`${t(notes.low)}:`} {`${metrics.minTemp}° F`}
                            </p>
                            <p className="hc-mb-none">
                                {metrics.weatherDescription.toLocaleLowerCase()}
                            </p>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

const KeyStatistics = ({ metrics }: { metrics: any }) => {
    const { t } = useEssentials()
    const { notes } = TRANSLATION_KEYS
    const getHasValue = (value: number): number | string => {
        if (value > 0) {
            return value
        }

        return '-'
    }
    const noValueDisplay = '-'

    return (
        <div>
            <h3 className="key-statistics-heading">{t(notes.keyStatistics)}</h3>
            <Card>
                <div className="key-statistics">
                    <div className="key-statistics-grid">
                        {[
                            [
                                t(notes.sfsInf),
                                getHasValue(metrics.sfsInf),
                                metrics.sfsInfColor,
                                metrics.sfsStatus,
                            ],
                            [
                                t(notes.opuInf),
                                getHasValue(metrics.opuInf),
                                metrics.opuInfColor,
                                metrics.opuStatus,
                            ],
                            [
                                t(notes.pickOnTime),
                                metrics.pickOnTime,
                                metrics.pickOnTimeColor,
                                metrics.pickOnTimeStatus,
                            ],
                        ].map(([label, value, color, status]) => (
                            <React.Fragment key={label}>
                                <span className="hc-mb-none">{label}</span>
                                <span className="hc-ta-right">
                                    {value === noValueDisplay
                                        ? value
                                        : `${value}%`}
                                </span>
                                <span
                                    className={`${value === noValueDisplay ? '' : color} hc-ta-right`}
                                >
                                    {value === noValueDisplay
                                        ? noValueDisplay
                                        : status}
                                </span>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default NotesPage
