import { useEffect, useState } from 'react'
import {
    Button,
    ButtonGroup,
    Grid,
    Table,
} from '@enterprise-ui/canvas-ui-react'
import { TableData, buildDataTableObject } from 'utils/data'
import { useSwipeToIndex } from './useSwipeToIndex'
import './generalized-component.scss'
import { ErrorAlert } from 'components/error-fallback'

export type GeneralizedComponentViewByOption =
    | 'view in $'
    | 'view in %'
    | 'comp %'
    | 'show FDC'
    | 'show RDC'
    | 'show stores'
    | 'show districts'
    | ''
export type GeneralizedComponentPayloadType = Partial<{
    [key in GeneralizedComponentViewByOption]: Array<{
        title: string
        subtitle: string
        data: {
            [key: string]: TableData
        }
    }>
}>

interface IGeneralizedComponentProps {
    payload: GeneralizedComponentPayloadType
    title?: string
    titleClassName?: string
    subTitle?: string
    scrollableWidth?: boolean
    sortable?: boolean
    widths?: number[]
    pinFirstColumn?: boolean
    parentHandleClick?: (timePeriodIndex: number, tabsIndex: number) => void
    selectedTimeIndex?: number
    selectedTabIndex?: number
    onDrillUpdate?: (data: any) => void
    onIndexUpdate?: (index: number) => void
    isLoading?: boolean
    loadingComponent?: React.ReactNode
    expandededWidth?: boolean
    isError?: boolean
    children?: React.ReactNode
    alternateRowColor?: boolean
    onSort?: (sortDirection: 'asc' | 'desc', field: string) => void
    columnSize?: number
}

export const GeneralizedComponent = ({
    payload,
    title,
    titleClassName = '',
    subTitle,
    scrollableWidth = true,
    sortable = false,
    widths = [100, 100, 100, 100, 100, 100, 100, 100, 100],
    pinFirstColumn = true,
    parentHandleClick,
    selectedTimeIndex = 0,
    selectedTabIndex = 0,
    onDrillUpdate,
    onIndexUpdate,
    isLoading,
    loadingComponent,
    expandededWidth = true,
    isError = false,
    children = null,
    alternateRowColor = true,
    onSort,
    columnSize,
}: IGeneralizedComponentProps) => {
    const views = Object.keys(payload)
    const [viewIndex, setViewIndex] = useState<number>(0)
    const currentView = views[viewIndex] as GeneralizedComponentViewByOption
    const currentViewData = payload[currentView]!
    const [selectedCell, setSelectedCell] = useState<string>('')
    const [drills, setDrills] = useState<string[]>([])

    const timePeriods = Object.values(currentViewData).map(
        (timePeriod) => timePeriod.title,
    )
    const [timePeriodIndex, setTimePeriodIndex] =
        useState<number>(selectedTimeIndex)

    useEffect(() => {
        onIndexUpdate?.(timePeriodIndex)
    }, [timePeriodIndex, onIndexUpdate])

    // Accessing the selected time period (including title, subtitle, and data)
    const currentViewDataForTimePeriod = currentViewData[timePeriodIndex]
    const { title: viewTitle, subtitle, data } = currentViewDataForTimePeriod

    // Accessing the data inside the time period (e.g., sales, goals) via `data` property
    const tabs = Object.keys(data)
    const [tabsIndex, setTabsIndex] = useState<number>(selectedTabIndex)
    const currentTab = tabs[tabsIndex]

    // Accessing the specific data for the selected tab (e.g., sales data or goals data)
    const currentViewDataForTimePeriodAndTab = data[currentTab]

    const handleClick = (selected: string) => {
        setSelectedCell(selected)
        const selectedIndex = dataObject?.rowData?.findIndex(
            (row) => row.merch.cellValue === selected,
        )
        const firstNonInteractiveIndex = dataObject?.rowData?.findIndex(
            (row) => row.merch.isLink === false,
        )

        let newDrills = []
        if (selectedIndex < firstNonInteractiveIndex) {
            newDrills = [...drills].slice(0, selectedIndex)
        } else {
            newDrills = [...drills, selected]
        }

        setDrills(newDrills)
        onDrillUpdate?.(newDrills)
    }
    const nextIndex = (viewIndex + 1) % views.length

    const dataObject = buildDataTableObject({
        currentData: currentViewDataForTimePeriodAndTab,
        widths,
        pinFirstColumn,
        sortable: sortable,
        handleClick,
        selectedCell,
        isLoading: isLoading,
        onSort: onSort,
    })

    const { handleTouchStart, handleTouchMove, handleTouchEnd } =
        useSwipeToIndex({
            timePeriods,
            setTimePeriodIndex,
        })

    useEffect(() => {
        if (parentHandleClick) {
            parentHandleClick(timePeriodIndex, tabsIndex)
        }
    }, [timePeriodIndex, tabsIndex, parentHandleClick])

    useEffect(() => {
        const tabsIndexWithDefaultFallback =
            tabs.length <= tabsIndex ? 0 : tabsIndex
        setTabsIndex(tabsIndexWithDefaultFallback)
    }, [timePeriodIndex, tabsIndex, tabs.length])

    useEffect(() => {
        const tabsIndexWithDefaultFallback =
            tabs.length <= tabsIndex ? 0 : tabsIndex
        setTabsIndex(tabsIndexWithDefaultFallback)
    }, [timePeriodIndex, tabsIndex, tabs.length])

    const renderTimePeriodTitle = viewTitle.length > 0
    const renderTimePeriodSubtitle = subtitle?.length > 0
    const renderTimePeriods = timePeriods.length > 1
    const renderDataTabs = tabs && tabs.length > 1
    const renderHorizontalLineAndBelow =
        renderTimePeriodTitle ||
        renderTimePeriodSubtitle ||
        renderDataTabs ||
        renderTimePeriods
    return (
        <div
            className="generalized-component"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <Grid.Container align="center" justify="space-between">
                <Grid.Item
                    className={`${titleClassName} title-container`}
                    data-testid={title}
                    xs={columnSize !== undefined ? columnSize : 8}
                >
                    <span>{title}</span>
                    <small className="hc-fs-sm">{subTitle}</small>
                </Grid.Item>
                {views.length > 1 && (
                    <Grid.Item xs={4} className="hc-pr-none">
                        <Button
                            type="ghost"
                            onClick={() => setViewIndex(nextIndex)}
                            className="hc-fs-sm hc-ph-none hc-pr-dense"
                        >
                            {views[nextIndex]}
                        </Button>
                    </Grid.Item>
                )}
            </Grid.Container>
            {renderHorizontalLineAndBelow && (
                <>
                    <Grid.Container
                        align="center"
                        justify="center"
                        className="time-periods hc-mt-min"
                    >
                        <Grid.Item xs={4}>
                            <p>
                                {viewTitle}
                                {renderTimePeriodSubtitle && (
                                    <small>{subtitle}</small>
                                )}
                            </p>
                        </Grid.Item>
                        <Grid.Item xs={4}>
                            {renderTimePeriods && (
                                <GeneralizedComponent.Buttons
                                    items={timePeriods}
                                    selected={timePeriodIndex}
                                    setSelected={setTimePeriodIndex}
                                    key="time-periods"
                                    className="time-periods"
                                    testId="time-period-container"
                                />
                            )}
                        </Grid.Item>
                        <Grid.Item xs={4}></Grid.Item>
                    </Grid.Container>
                    {renderDataTabs && (
                        <Grid.Container
                            align="center"
                            justify="center"
                            className="hc-mb-sm hc-mt-xs"
                        >
                            <Grid.Item>
                                <GeneralizedComponent.Buttons
                                    items={tabs}
                                    selected={tabsIndex}
                                    setSelected={setTabsIndex}
                                    key="data-tabs"
                                    className="data-tabs"
                                />
                            </Grid.Item>
                        </Grid.Container>
                    )}
                </>
            )}
            {!isError && children && <>{children}</>}
            {isError ? (
                <ErrorAlert className="hc-mv-lg" />
            ) : (
                <Grid.Container justify="center" className="gc-table-container">
                    <Grid.Item
                        xs={12}
                        className={expandededWidth ? 'hc-ph-none' : ''}
                    >
                        {isLoading && loadingComponent}
                        {!isLoading && dataObject?.rowData && (
                            <Table
                                data={dataObject}
                                name={title ?? 'Generalized Component'}
                                scrollableWidth={scrollableWidth}
                                alternateRowColor={alternateRowColor}
                            />
                        )}
                    </Grid.Item>
                </Grid.Container>
            )}
        </div>
    )
}

type SharedProps = {
    items: string[]
    selected: number
    setSelected: (value: number) => void
    className?: string
    testId?: string
}

GeneralizedComponent.Buttons = ({
    items,
    selected,
    setSelected,
    className,
    testId,
}: SharedProps) => {
    return (
        <ButtonGroup
            className={className ? className : ''}
            data-testid={testId}
        >
            {items.map((item: string, index: number) => {
                return (
                    <Button
                        type={index === selected ? 'primary' : 'secondary'}
                        onClick={() => setSelected(index)}
                        key={items[index]}
                    >
                        {item}
                    </Button>
                )
            })}
        </ButtonGroup>
    )
}
