import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { getLoyaltyFiltersForTargetCircleCard } from 'pages/loyalty/helpers'
import {
    fetchTargetCircleCard,
    fetchTargetCircleCardArchive,
} from 'queries/loyalty/targetCircleCard'
import { LoyaltyFetchProps } from 'types/api'
import { isStore } from 'utils/location'
import { TIME } from 'utils/misc'

export function useTargetCircleCard({
    env,
    accessToken,
    location,
}: LoyaltyFetchProps) {
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.TARGET_CIRCLE_CARD, location],
        async () => {
            const [recentData, archiveData] = await Promise.all([
                fetchTargetCircleCard({
                    env,
                    accessToken,
                    filters: getLoyaltyFiltersForTargetCircleCard(location),
                    dynamicSubPath: isStore(location)
                        ? '/stores'
                        : '/non_stores',
                }),
                fetchTargetCircleCardArchive({
                    env,
                    accessToken,
                    filters: getLoyaltyFiltersForTargetCircleCard(location),
                }),
            ])

            return { recentData, archiveData }
        },
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
