import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchAssetsProtectionTheftData } from 'queries/assets-protection'
import { ShortageFetchProps } from 'types/api'
import { ITitleListResponse } from 'utils/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function useTheftStore({
    env,
    accessToken,
    location,
    filters,
}: ShortageFetchProps): ITitleListResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.ASSETS_PROTECTION_THEFT_STORE, location],
        () =>
            fetchAssetsProtectionTheftData({
                env,
                accessToken,
                filters,
                trackEvent,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
