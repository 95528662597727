import { Table } from '@enterprise-ui/canvas-ui-react'
import { Container } from 'components/container/Container'
import { ErrorAlert } from 'components/error-fallback'
import { BasicPlaceholder } from 'components/placeholder'
import { format } from 'date-fns'
import { useRecall } from 'hooks/operations/recall/useRecall'
import useCountdown from 'hooks/utils/useCountdown'
import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { buildDataTableObject, TableData } from 'utils/data'
import { Constants } from '../constants'

export const Recall = () => {
    const { t, currentLocation, env, logout, session } = useEssentials()
    const { operationalMetrics } = TRANSLATION_KEYS
    const { recall } = operationalMetrics
    const { headings } = recall
    const { data, isLoading, isError, error } = useRecall({
        env,
        accessToken: session?.accessToken ?? '',
        filters: {
            district_id: currentLocation.slice(1),
        },
    })
    const count = useCountdown(5, logout, error as Error)

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="table" rows={5} columns={3} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    const now = new Date()
    const formattedDate = format(now, Constants.DATE_FORMAT)

    const tableData: TableData = {
        headings: [
            { value: t(headings.location) },
            { value: t(headings.irNumber) },
            { value: t(headings.status) },
        ],
        rows: data.map((item: any) =>
            Object.entries(item)
                .filter(([key]) => key !== Constants.KEY_BK_COLOR)
                .map(([key, value]) => {
                    let color = ''
                    switch (item.bk_color) {
                        case Constants.RED_STRING:
                            color = Constants.RED_HEX
                            break
                        case Constants.YELLOW_STRING:
                            color = Constants.YELLOW_HEX
                            break
                        default:
                            color = ''
                    }
                    return {
                        value: String(value),
                        color: key === Constants.KEY_STATUS ? color : undefined,
                        className:
                            key === Constants.KEY_STATUS
                                ? Constants.CSS_TEXT_LOWERCASE
                                : '',
                    }
                }),
        ),
    }

    return (
        <Container>
            <p className="hc-mb-sm">{formattedDate}</p>
            <Table
                data={buildDataTableObject({
                    currentData: tableData,
                    widths: [100, 100, 100, 100],
                })}
                scrollableWidth={false}
                alternateRowColor={true}
            />
        </Container>
    )
}
