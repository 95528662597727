import EnterpriseIcon, { EnterpriseIconProps } from '@enterprise-ui/icons'

import './floating-action-button.css'

interface IFloatingActionButtonProps {
    children: string
    icon?: EnterpriseIconProps['icon']
}

export const FloatingActionButton = ({
    children,
    icon,
}: IFloatingActionButtonProps) => (
    <button className="floating-action-button">
        {icon ? <EnterpriseIcon icon={icon} size="lg" /> : null}
        {children}
    </button>
)

interface IFloatingActionButtonContainerProps {
    children: JSX.Element | JSX.Element[] | string
}

export const FloatingActionButtonContainer = ({
    children,
}: IFloatingActionButtonContainerProps) => (
    <div className="floating-action-buttons-container">{children}</div>
)
