import { Divider } from '@enterprise-ui/canvas-ui-react'
import {
    addHyperLinkToFirstColumn,
    getDynamicSubPath,
    getFormattedDateMonthandYear,
    getFormattedReportDate,
    getHeaderText,
    getLocationFlexHours,
} from '../helpers'
import { isStore as isStoreCheck } from 'utils/location'
import { Cell } from 'utils/data'
import { Container } from 'components/container/Container'
import { usePayrollLocationMonthly } from 'hooks/payroll/location'
import { NonStoreLocation } from './NonStoreLocation'
import { StoreLocation } from './StoreLocation'
import { GridData } from './GridData'
import { getLocationFilters } from 'utils/api'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { useEssentials } from 'hooks/utils/useEssentials'

export const Location = () => {
    const { t, currentLocation, pathname, env, logout, session } =
        useEssentials()

    let projectedFlexHoursVal: Cell = { value: 'n/a' },
        guaranteedFlexHoursVal: Cell = { value: 'n/a' },
        plannedFlexHoursVal: Cell = { value: 'n/a' }

    const filters = isStoreCheck(currentLocation)
        ? getLocationFilters(currentLocation)
        : undefined
    const dynamicSubPath = getDynamicSubPath(currentLocation)
    let { data, isLoading, isError, refetch, remove, error } =
        usePayrollLocationMonthly({
            env,
            accessToken: session?.accessToken ?? '',
            location: currentLocation,
            dynamicSubPath,
            filters,
        })
    useRefresh(refetch, remove)
    const count = useCountdown(5, logout, error as Error)

    if (isError) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    if (data) {
        data = addHyperLinkToFirstColumn(data, currentLocation, pathname)
        const { footer } = data
        if (footer) {
            const {
                projectedFlexHours,
                guaranteedFlexHours,
                plannedFlexHours,
            } = getLocationFlexHours(currentLocation, footer)
            projectedFlexHoursVal = projectedFlexHours
            guaranteedFlexHoursVal = guaranteedFlexHours
            plannedFlexHoursVal = plannedFlexHours
        }
    }

    const isStore = isStoreCheck(currentLocation)
    const yearMonthDate = getFormattedDateMonthandYear()

    return (
        <Container>
            <p className="hc-fs-md hc-ma-none">
                {t(getHeaderText(currentLocation))}
            </p>
            <p className="hc-fs-sm hc-ma-none2">
                {t('as of')} {getFormattedReportDate()}
            </p>
            <Divider />
            <GridData
                isLoading={isLoading}
                data={[
                    projectedFlexHoursVal,
                    guaranteedFlexHoursVal,
                    plannedFlexHoursVal,
                ]}
            />
            {!isStore && (
                <NonStoreLocation
                    data={data}
                    isLoading={isLoading}
                    isError={isError}
                    currentLocation={currentLocation}
                    bottomLeftCell={yearMonthDate}
                    error={error as Error}
                    count={count}
                />
            )}
            {isStore && (
                <StoreLocation
                    isLoading={isLoading}
                    isError={isError}
                    currentLocation={currentLocation}
                    bottomLeftCell={yearMonthDate}
                    error={error as Error}
                    count={count}
                />
            )}
        </Container>
    )
}
