import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { TargetCircleFetchProps } from 'types/api'
import { IResponse } from 'utils/api'
import { TIME } from 'utils/misc'
import { fetchTargetCircle } from 'queries/loyalty/target-circle'
import { useAnalytics } from '@praxis/component-analytics'

export function useTargetCircle({
    env,
    accessToken,
    dynamicSubPath,
    location,
    filters,
}: TargetCircleFetchProps): IResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    if (location === 'Company Target') {
        location = 'Company'
    }

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.TARGET_CIRCLE, location, dynamicSubPath],
        () =>
            fetchTargetCircle({
                env,
                accessToken,
                dynamicSubPath,
                filters,
                trackEvent,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )
    return { data, isLoading, isError, refetch, remove, error }
}
