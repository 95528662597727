import { useCallback, useEffect, useState } from 'react'
import { Layout } from 'components/layout'
import { clearLocationParam } from 'utils/helpers'
import {
    stripInitialTAndLeadingZeroes,
    convertToDisplayFriendlyDateString,
    decodeHtmlEntities,
} from 'utils/item-search'
import { useEssentials } from 'hooks/utils/useEssentials'
import { useItemDetails } from 'hooks/item-details/useItemDetails'
import {
    Card,
    Image,
    Input,
    Button,
    Modal,
    Grid,
} from '@enterprise-ui/canvas-ui-react'
import { Container } from 'components/container/Container'
import { CardSlider } from './card-slider'
import { messages } from './messages'
import { ErrorAlert } from 'components/error-fallback'
import useCountdown from 'hooks/utils/useCountdown'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { ItemSearchChart } from './item-search-chart'
import { BasicPlaceholder } from 'components/placeholder'

export const ItemDetailsPage = () => {
    const { params, setParams, currentLocation } = useEssentials()

    window.onpopstate = (_e) => {
        clearLocationParam({ params, setParams })
    }

    return (
        <Layout heading={'item details'} breadcrumbsEnabled={false}>
            <ItemDetails key={currentLocation} />
        </Layout>
    )
}

const ItemDetails = () => {
    const { t, currentLocation, env, session, logout } = useEssentials()
    const [itemId, setItemId] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [filters, setFilters] = useState({ location_id: '', item_id: '' })
    const [isOpen, setIsOpen] = useState(false)

    const simpleLocation = stripInitialTAndLeadingZeroes(currentLocation)

    const MIN_BARCODE_LENGTH = 12

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            setFilters({ location_id: simpleLocation, item_id: itemId })
            setIsSubmitted(true)
        },
        [simpleLocation, itemId],
    )

    useEffect(() => {
        const inputElement = document.getElementById(
            'item-id-search',
        ) as HTMLInputElement | null

        if (!inputElement) return

        const handleManualInput = (event: Event) => {
            const target = event.target as HTMLInputElement
            const newValue = target.value.replace(/[^0-9-]/g, '')
            setItemId(newValue)
        }

        inputElement.addEventListener('input', handleManualInput)

        // Use MutationObserver to detect scanner input
        const observer = new MutationObserver(() => {
            const newValue = inputElement.value.replace(/[^0-9-]/g, '')
            setItemId(newValue)

            if (newValue.length >= MIN_BARCODE_LENGTH) {
                const formElement = document.getElementById(
                    'item-search-form',
                ) as HTMLFormElement | null
                formElement?.requestSubmit()
            }
        })

        observer.observe(inputElement, {
            attributes: true,
            attributeFilter: ['value'],
        })

        return () => {
            observer.disconnect()
            inputElement.removeEventListener('input', handleManualInput)
        }
    }, [])

    const { itemSearch } = TRANSLATION_KEYS
    const {
        searchLabel,
        searchInputPlaceholder,
        searchButtonText,
        location,
        onHandRow,
        onWithdrawalRow,
        lastAuditedRow,
        lastReceivedRow,
        lastSoldRow,
        unitsSold,
        sales,
        store,
        district,
        company,
        averageSalesCardTitle,
        totalSalesCardTitle,
        itemNotFound,
    } = itemSearch

    const { data, isLoading, isError, error } = useItemDetails({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        filters,
    })

    const count = useCountdown(5, logout, error as Error)

    if (isError) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    if (!isLoading && isSubmitted && !data?.item_details) {
        return (
            <>
                <div className="hc-pt-expanded">
                    <form
                        id="item-search-form"
                        onSubmit={handleSubmit}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <div>
                            <Input.Label htmlFor="item-id-search">
                                {t(searchLabel)}
                            </Input.Label>
                            <Input.Text
                                type="text"
                                id="item-id-search"
                                value={itemId}
                                placeholder={t(searchInputPlaceholder)}
                            />
                        </div>
                        <Button style={{ marginTop: '21px' }} type="submit">
                            {t(searchButtonText)}
                        </Button>
                    </form>
                </div>
                <div className="hc-ma-normal" id="item-not-found">
                    {t(itemNotFound)}
                </div>
            </>
        )
    }

    const {
        item_details: {
            item_info: {
                digital_assets: { base_url = '', primary_image = '' } = {},
                product_description = '',
                sales_floor_qty = 0,
                backroom_floor_qty = 0,
                on_hand_inventory = 0,
                on_withdrawal_inventory = 0,
                placements = [{}],
                last_audited_date = null,
                last_received_date = null,
                last_sold_date = null,
            } = {},
            sales_history: {
                sales_turnover: {
                    store_sales_quantity = 0,
                    store_sale_amount = 0,
                    district_total_sale_amount = 0,
                    district_total_sale_quantity = 0,
                    company_total_sale_amount = 0,
                    company_total_sale_quantity = 0,
                } = {},
                sales_average: {
                    district_average_quantity = 0,
                    district_average_amount = 0,
                    company_average_quantity = 0,
                    company_average_amount = 0,
                } = {},
            } = {
                sales_turnover: {},
                sales_average: {},
            },
        } = {
            item_info: {},
            sales_history: {
                sales_turnover: {},
                sales_average: {},
            },
        },
    } = data || {
        item_details: {
            item_info: {},
            sales_history: {
                sales_turnover: {},
                sales_average: {},
            },
        },
    }

    const decodedDescription = (() => {
        try {
            if (!product_description || product_description.trim() === '') {
                return 'No description available'
            }

            return decodeHtmlEntities(product_description)
        } catch (error) {
            return 'No description available'
        }
    })()

    const formattedLastAuditedDate =
        convertToDisplayFriendlyDateString(last_audited_date)

    const formattedLastReceivedDate =
        convertToDisplayFriendlyDateString(last_received_date)

    const formattedLastSoldDate =
        convertToDisplayFriendlyDateString(last_sold_date)

    const onHandQty = Math.max(0, Number(on_hand_inventory) || 0)

    const onWayQty = Math.max(0, Number(on_withdrawal_inventory) || 0)

    const floorQty = Math.max(0, Number(sales_floor_qty) || 0)

    const backQty = Math.max(0, Number(backroom_floor_qty) || 0)

    return (
        <>
            <div>
                <form
                    id="item-search-form"
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <div>
                        <Input.Label htmlFor="item-id-search">
                            {t(searchLabel)}
                        </Input.Label>
                        <Input.Text
                            type="text"
                            id="item-id-search"
                            value={itemId}
                            placeholder={t(searchInputPlaceholder)}
                        />
                    </div>
                    <Button
                        style={{ marginTop: '21px' }}
                        id="item-search-button"
                        type="submit"
                    >
                        {t(searchButtonText)}
                    </Button>
                </form>
            </div>
            {isLoading && isSubmitted && (
                <div
                    className="placeholder-container hc-mt-normal"
                    data-testid="placeholder"
                >
                    <BasicPlaceholder type="table" rows={10} columns={1} />
                </div>
            )}
            {data && !isLoading && isSubmitted && (
                <Container>
                    <div className="hc-mt-normal hc-mh-normal">
                        <div
                            className="hc-pr-expanded"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Image
                                src={`${base_url}${primary_image}`}
                                alt={decodedDescription}
                                height="88px"
                                onClick={() => setIsOpen((p) => !p)}
                                id="small-item-image"
                            />
                            <h2 className="hc-ml-normal">
                                {decodedDescription}
                            </h2>
                        </div>
                        <p id="quantity-check" className="hc-mt-normal">
                            {messages.quantityCheck(floorQty, backQty)}
                        </p>
                    </div>

                    <Modal
                        isVisible={isOpen}
                        headingText={decodedDescription}
                        onRefuse={() => setIsOpen(false)}
                    >
                        <div className="hc-pa-normal">
                            <Grid.Container justify="center">
                                <Grid.Item>
                                    <Image
                                        src={`${base_url}${primary_image}`}
                                        alt={decodedDescription}
                                        height="148px"
                                        minWidth="148px"
                                    />
                                </Grid.Item>
                            </Grid.Container>
                        </div>
                    </Modal>

                    <CardSlider placements={placements} />
                    <Card className="hc-fs-md hc-mt-normal hc-mh-normal hc-pa-normal hc-pl-none">
                        <ItemSearchChart item_details={data.item_details} />
                    </Card>
                    <Card className="hc-fs-md hc-mv-normal hc-mh-normal hc-pa-normal">
                        <div className="inventory-card">
                            <div>
                                <span>{t(onHandRow)}</span>
                                <span
                                    id="on-hand-quantity"
                                    className="hc-ta-right"
                                >
                                    {onHandQty}
                                </span>
                            </div>
                            <div>
                                <span>{t(onWithdrawalRow)}</span>
                                <span
                                    id="on-withdrawal-quantity"
                                    className="hc-ta-right"
                                >
                                    {onWayQty}
                                </span>
                            </div>
                            <div>
                                <span>{t(lastAuditedRow)}</span>
                                <span
                                    id="last-audited-date"
                                    className="hc-ta-right"
                                >
                                    {formattedLastAuditedDate}
                                </span>
                            </div>
                            <div>
                                <span>{t(lastReceivedRow)}</span>
                                <span
                                    id="last-received-date"
                                    className="hc-ta-right"
                                >
                                    {formattedLastReceivedDate}
                                </span>
                            </div>
                            <div>
                                <span>{t(lastSoldRow)}</span>
                                <span
                                    id="last-sold-date"
                                    className="hc-ta-right"
                                >
                                    {formattedLastSoldDate}
                                </span>
                            </div>
                        </div>
                    </Card>
                    <Card className="hc-fs-md hc-mv-normal hc-mh-normal hc-ph-normal">
                        <h3 id="total-sales-title" className="hc-pv-normal">
                            {t(totalSalesCardTitle)}
                        </h3>
                        <div className="card-content hc-pb-normal">
                            <div>
                                <span>{t(location)}</span>
                                <span>{t(unitsSold)}</span>
                                <span>{t(sales)}</span>
                            </div>
                            <div>
                                <span>{t(store)}</span>
                                <span>{store_sales_quantity}</span>
                                <span>
                                    {`$`}
                                    {store_sale_amount}
                                </span>
                            </div>
                            <div>
                                <span>{t(district)}</span>
                                <span id="district-total-sale-qty">
                                    {district_total_sale_quantity}
                                </span>
                                <span id="district-total-sale-amt">
                                    {`$`}
                                    {district_total_sale_amount}
                                </span>
                            </div>
                            <div>
                                <span>{t(company)}</span>
                                <span id="company-total-sale-qty">
                                    {company_total_sale_quantity}
                                </span>
                                <span id="company-total-sale-amt">
                                    {`$`}
                                    {company_total_sale_amount}
                                </span>
                            </div>
                        </div>
                    </Card>

                    <Card className="hc-fs-md hc-mv-normal hc-mh-normal hc-ph-normal">
                        <h3 id="average-sales-title" className="hc-pv-normal">
                            {t(averageSalesCardTitle)}
                        </h3>
                        <div className="card-content hc-pb-normal">
                            <div>
                                <span>{t(location)}</span>
                                <span>{t(unitsSold)}</span>
                                <span>{t(sales)}</span>
                            </div>
                            <div>
                                <span>{t(district)}</span>
                                <span id="district-av-sale-qty">
                                    {district_average_quantity}
                                </span>
                                <span id="district-av-sale-amt">
                                    {`$`}
                                    {district_average_amount}
                                </span>
                            </div>
                            <div className="hc-pb-normal">
                                <span>{t(company)}</span>
                                <span id="av-qty">
                                    {company_average_quantity}
                                </span>
                                <span id="av-amount">
                                    {`$`}
                                    {company_average_amount}
                                </span>
                            </div>
                        </div>
                    </Card>
                </Container>
            )}
        </>
    )
}
