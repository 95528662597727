import { useEffect, useState } from 'react'
import {
    Card,
    Grid,
    GridContainer,
    Table,
} from '@enterprise-ui/canvas-ui-react'
import { format, parseISO } from 'date-fns'
import { useStoreTrailers } from 'hooks/trailer/useStoreTrailers'
import { useEssentials } from 'hooks/utils/useEssentials'
import { buildDataTableObject, Cell, TableData } from 'utils/data'
import { ILaborType, IStoreTrailerDetails } from './ITrailer'
import { ErrorBoundary } from 'react-error-boundary'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { ErrorAlert, ErrorFallback } from 'components/error-fallback'
import { Layout } from 'components/layout'
import { BackButton } from 'pages/location-search/location-search'
import { UrlParams } from 'enums/url-params'
import { BasicPlaceholder } from 'components/placeholder'
import { Constants } from './messages'
import { useNavigate } from 'react-router-dom'
import { TrailerEta } from './store-trailer-eta'

import './store-trailer-details.scss'
import { TrailerWorkArea } from './store-trailer-work-area'

export const StoreTrailerDetails = () => {
    const navigate = useNavigate()
    const { trailer: trailerKeys } = TRANSLATION_KEYS
    const { details } = trailerKeys
    const { table } = details

    const [sortedData, setSortedData] = useState<IStoreTrailerDetails>()
    const [customTableData, setCustomTableData] = useState<TableData>()
    const { t, env, session, currentLocation, params } = useEssentials()
    const moveId = params.get(UrlParams.MOVE_ID)
    useEffect(() => {
        if (!moveId) {
            navigate(`/trailer?locationId=${currentLocation}`, {
                replace: true,
            })
        }
    }, [navigate, moveId, currentLocation])

    const { trailer } = TRANSLATION_KEYS

    const { data, isLoading, isError, error } = useStoreTrailers({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
    })

    useEffect(() => {
        if (data) {
            setSortedData(data)
        }
    }, [data])

    if (isLoading) {
        return (
            <Layout
                heading={t(trailer.title)}
                breadcrumbsEnabled={false}
                isMenuOverride={true}
                menuOverride={<BackButton />}
            >
                <div
                    className="placeholder-container"
                    data-testid="placeholder"
                >
                    <BasicPlaceholder type="text" rows={1} />
                    <BasicPlaceholder type="table" rows={8} columns={3} />
                </div>
            </Layout>
        )
    }
    if (isError || !sortedData) {
        return <ErrorAlert error={error as Error} />
    }

    const {
        unacknowledged_at_store,
        unacknowledged_at_dc,
        unacknowledged_in_transit,
        unacknowledged_no_tracking,
        acknowledged,
    } = sortedData
    const trailers = [
        unacknowledged_at_dc,
        unacknowledged_at_store,
        unacknowledged_in_transit,
        unacknowledged_no_tracking,
        acknowledged,
    ]
        .filter((trailer) => trailer)
        .flat()
    const currentTrailer = trailers.find(
        (trailer) => trailer.move_id === moveId,
    )
    const { labor_types, custom_block_detail_counts } = currentTrailer!
    const formattedClosedTime = format(
        parseISO(currentTrailer?.shipment_closed_timestamp ?? ''),
        'M/d h:mm a',
    )
    const stockingTime =
        labor_types?.find((labor) => labor?.type === Constants.STOCKING)
            ?.formatted_hours ?? ''
    const secondarySortingTime =
        labor_types?.find(
            (labor) => labor?.type === Constants.SECONDARY_SORTING,
        )?.formatted_hours ?? ''

    let customBlockRows = custom_block_detail_counts.map((block: any): any => {
        const { id, name, case_pack_counts, repack_counts, labor_types } = block
        const secondarySorting = labor_types?.find(
            (labor: ILaborType) => labor.type === Constants.SECONDARY_SORTING,
        )?.formatted_hours
        const stockingTime = labor_types?.find(
            (labor: ILaborType) => labor.type === Constants.STOCKING,
        )?.formatted_hours
        const row = [
            { value: '' + id + '-' + name },
            {
                value: '' + (case_pack_counts ?? 0),
            },
            {
                value: '' + (repack_counts ?? 0),
            },
            {
                value: '' + (secondarySorting ?? ''),
            },
            {
                value: '' + (stockingTime ?? ''),
            },
        ]
        return row
    })

    const getDataRows = (customBlockRows: Cell[][]) => {
        let customBlockRowsWithZero = customBlockRows.filter((block) =>
            block[0].value.startsWith('00'),
        )
        let customBlockRowsWithoutZero = customBlockRows.filter(
            (block) => !block[0].value.startsWith('00'),
        )

        return {
            customBlockRowsWithZero,
            customBlockRowsWithoutZero,
        }
    }

    const { customBlockRowsWithZero, customBlockRowsWithoutZero } =
        getDataRows(customBlockRows)
    let customBlockTable = {
        headings: [
            table.customBlock,
            table.caseCartons,
            table.repackCartons,
            table.sortingTime,
            table.stockingTime,
        ].map(
            (value: string): Cell => ({
                value: t(value),
            }),
        ),
        rows: [...customBlockRowsWithoutZero, ...customBlockRowsWithZero],
    }
    const initRowSort = customBlockTable.rows.sort((a, b) => {
        if (a[0].value.includes('00') || b[0].value.includes('00')) {
            return 0
        }
        const aValue = Number(a[1].value)
        const bValue = Number(b[1].value)
        return bValue - aValue
    })
    const {
        customBlockRowsWithoutZero: sortedRowsWithoutZero,
        customBlockRowsWithZero: sortedRowsWithZero,
    } = getDataRows(initRowSort)
    customBlockTable.rows = [...sortedRowsWithoutZero, ...sortedRowsWithZero]
    const sortData = (sortDirection: 'asc' | 'desc', field: string) => {
        const { TABLE_KEYS } = Constants
        const {
            CUSTOM_BLOCK,
            CASE_CARTONS,
            REPACK_CARTONS,
            SORTING_TIME,
            STOCKING_TIME,
        } = TABLE_KEYS
        const fieldMapping: { [key: string]: number } = {
            [CUSTOM_BLOCK]: 0,
            [CASE_CARTONS]: 1,
            [REPACK_CARTONS]: 2,
            [SORTING_TIME]: 3,
            [STOCKING_TIME]: 4,
        }
        const fieldIndex = fieldMapping[field] ?? 0
        const newData = {
            ...customBlockTable,
            rows: customBlockTable?.rows ?? [],
        }
        let { customBlockRowsWithZero, customBlockRowsWithoutZero } =
            getDataRows(newData.rows)
        const newSortedRows = customBlockRowsWithoutZero.sort((a, b) => {
            const condition = fieldIndex === 1 || fieldIndex === 2
            const aValue = condition
                ? Number(a[fieldIndex].value)
                : a[fieldIndex].value
            const bValue = condition
                ? Number(b[fieldIndex].value)
                : b[fieldIndex].value
            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sortDirection === 'asc'
                    ? aValue - bValue
                    : bValue - aValue
            } else if (
                typeof aValue === 'string' &&
                typeof bValue === 'string'
            ) {
                return sortDirection === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue)
            } else {
                return 0
            }
        })
        newData.rows = [...newSortedRows, ...customBlockRowsWithZero]
        customBlockTable = newData
        setCustomTableData(customBlockTable)
    }

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(trailer.title)}
                />
            )}
        >
            <Layout
                heading={t(trailer.title)}
                breadcrumbsEnabled={false}
                isMenuOverride={true}
                menuOverride={<BackButton />}
            >
                <GridContainer className="hc-pa-min" spacing="expanded">
                    <Grid.Item xs={12} className="hc-pb-none">
                        <Card elevation={0}>
                            <TrailerEta moveId={moveId} />
                        </Card>
                    </Grid.Item>
                    <Grid.Item xs={12}>
                        <Card elevation={0}>
                            <p className="hc-ma-none hc-pa-sm">
                                {`${t(details.billOfLading)}:`} {moveId}
                            </p>
                            <div className="hc-pt-none">
                                <Table
                                    data={buildDataTableObject({
                                        currentData: {
                                            headings: [
                                                table.type,
                                                table.closed,
                                                table.trailerNumber,
                                                table.cartons,
                                                table.leadTime,
                                            ].map(
                                                (value: string): Cell => ({
                                                    value: t(value),
                                                }),
                                            ),
                                            rows: [
                                                [
                                                    currentTrailer?.type,
                                                    formattedClosedTime,
                                                    currentTrailer?.trailer_id,
                                                    currentTrailer?.cartons,
                                                    currentTrailer?.lead_time,
                                                ].map(
                                                    (value: any): Cell => ({
                                                        value: '' + value,
                                                    }),
                                                ),
                                            ],
                                        },
                                        widths: [80, 80, 80, 80, 80],
                                        isLoading,
                                    })}
                                    name={'store-trailer-details'}
                                    scrollableWidth={false}
                                />
                            </div>
                            <div className="hc-pt-md hc-pb-sm">
                                <Table
                                    data={buildDataTableObject({
                                        currentData: {
                                            headings: [
                                                table.laborHours,
                                                table.stockingTime,
                                                table.secondarySortingTime,
                                            ].map(
                                                (value: string): Cell => ({
                                                    value: t(value),
                                                }),
                                            ),
                                            rows: [
                                                [
                                                    t(table.total),
                                                    stockingTime,
                                                    secondarySortingTime,
                                                ].map(
                                                    (value: any): Cell => ({
                                                        value: '' + value,
                                                    }),
                                                ),
                                            ],
                                        },
                                        widths: [140, 90, 140],
                                        isLoading,
                                    })}
                                    name={'store-trailer-details'}
                                    scrollableWidth={false}
                                />
                            </div>
                        </Card>

                        <div className="hc-pt-md">
                            {currentTrailer && (
                                <TrailerWorkArea data={currentTrailer} />
                            )}
                        </div>

                        <div className="hc-pt-md store-trailer-details-custom-block">
                            <Table
                                data={buildDataTableObject({
                                    currentData:
                                        customTableData! || customBlockTable,
                                    widths: [120, 70, 70, 80, 80],
                                    isLoading,
                                    onSort: sortData,
                                    sortable: true,
                                })}
                                name={'store-trailer-details'}
                                scrollableWidth={false}
                            />
                        </div>
                    </Grid.Item>
                </GridContainer>
            </Layout>
        </ErrorBoundary>
    )
}
