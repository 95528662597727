import { useState, useEffect } from 'react'
import { useEssentials } from 'hooks/utils/useEssentials'
import { useTrailerRGD } from 'hooks/trailer/useTrailerRGD'
import { GeneralizedComponent } from 'components/generalized-component/generalized-component'
import { addHyperlinkToFirstColumn, TableData } from 'utils/data'
import { BasicPlaceholder } from 'components/placeholder'
import useCountdown from 'hooks/utils/useCountdown'
import { Container } from 'components/container/Container'
import { ErrorAlert } from 'components/error-fallback'
import { formatDateTime, createRow, formattedHeadings } from './helpers'
import { isGroup } from 'utils/location'

export const NonStore = () => {
    const { t, currentLocation, pathname, env, logout, session } =
        useEssentials()

    const isGroupLocation = isGroup(currentLocation)
    const [sortedData, setSortedData] = useState<TableData>()
    const { data, isLoading, isError, error } = useTrailerRGD({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
    })
    const count = useCountdown(5, logout, error as Error)

    const formattedTitle = formatDateTime(t)

    const sortData = (sortDirection: 'asc' | 'desc', field: string) => {
        const fieldMapping: { [key: string]: number } = {
            unacknowledged: 1,
            'red(>36hrs)': 2,
            recentlyacknowledged: 3,
        }

        const fieldIndex = fieldMapping[field] ?? 0
        const newData = { ...sortedData, rows: sortedData?.rows ?? [] }
        const newSortedFirstRow = newData.rows[0]
        const newSortedRows = newData.rows.slice(1).sort((a, b) => {
            const aValue =
                fieldIndex > 0
                    ? Number(a[fieldIndex].value)
                    : a[fieldIndex].value
            const bValue =
                fieldIndex > 0
                    ? Number(b[fieldIndex].value)
                    : b[fieldIndex].value
            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return sortDirection === 'asc'
                    ? aValue - bValue
                    : bValue - aValue
            } else if (
                typeof aValue === 'string' &&
                typeof bValue === 'string'
            ) {
                return sortDirection === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue)
            } else {
                return 0
            }
        })
        newData.rows = [newSortedFirstRow, ...newSortedRows]
        setSortedData(newData)
    }

    useEffect(() => {
        if (data) {
            const { current_location, child_locations, grandchild_locations } =
                data

            const tableHeadings = formattedHeadings
            const tableRows = [
                createRow(current_location),
                ...child_locations.map(createRow),
                ...(isGroupLocation ? grandchild_locations : []).map(createRow),
            ]
            const modifiedData: TableData = {
                headings: tableHeadings.map((heading) => ({
                    value: t(heading.value),
                })),
                rows: tableRows,
            }
            const firstRow = modifiedData.rows[0]
            const otherRows = modifiedData.rows.slice(1)
            let tempData = {
                headings: modifiedData.headings,
                rows: [...otherRows],
            } as TableData
            tempData = addHyperlinkToFirstColumn(
                tempData,
                currentLocation,
                pathname,
            )
            tempData = {
                headings: modifiedData.headings,
                rows: [firstRow, ...tempData.rows],
            }
            setSortedData(tempData)
        }
    }, [currentLocation, data, isGroupLocation, pathname, t])

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
                <BasicPlaceholder type="table" rows={8} columns={3} />
            </div>
        )
    }

    if (isError || !data || !sortedData) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    const payload = {
        '': [
            {
                title: '',
                subtitle: '',
                data: { '': sortedData },
            },
        ],
    }

    const groupPayload = {
        'show districts': [
            {
                title: '',
                subtitle: '',
                data: {
                    '': {
                        ...sortedData,
                        rows: sortedData.rows.filter((row) => {
                            const value = row[0].value
                            return !value.includes('T')
                        }),
                    },
                },
            },
        ],
        'show stores': [
            {
                title: '',
                subtitle: '',
                data: {
                    '': {
                        ...sortedData,
                        rows: sortedData.rows.filter((row) => {
                            const value = row[0].value
                            return !value.includes('D')
                        }),
                    },
                },
            },
        ],
    }

    return (
        <Container>
            <GeneralizedComponent
                payload={isGroupLocation ? groupPayload : payload}
                sortable={true}
                widths={[80, 120, 70, 110]}
                title={formattedTitle}
                onSort={sortData}
                key={currentLocation}
            />
        </Container>
    )
}
