import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { Layout } from 'components/layout'

export const Alerts = () => {
    const { alerts } = TRANSLATION_KEYS

    const { t } = useEssentials()

    return <Layout heading={t(alerts)} className="hc-pa-none"></Layout>
}
