import { useEffect } from 'react'
import {
    listenForPullDownRefresh,
    removePullDownListener,
} from 'utils/eventEmitter'

const useRefresh = (refetch: () => void, remove: () => void) => {
    useEffect(() => {
        const handlePullDownRefresh = () => {
            remove()
            refetch()
        }
        listenForPullDownRefresh(handlePullDownRefresh)

        return () => removePullDownListener(handlePullDownRefresh)
    }, [refetch, remove])
}

export default useRefresh
