import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { SafetyLagging } from './safety-lagging'
import { SafetyTotal } from './safety-total'
import { SafetyLeading } from './safety-leading'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const SafetyNonStore = () => {
    const { t, currentLocation } = useEssentials()

    const { safety } = TRANSLATION_KEYS
    const { tabs: tabKeys } = safety
    const { lagging, total, leading } = tabKeys
    const tabs = [lagging, total, leading]
    const [activeTab, setActiveTab] = useState<string>(tabs[0])

    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)

    return (
        <>
            <Tabs
                activeTab={activeTab}
                className="tabs hc-pv-dense"
                justified
                disableAdaptive={true}
                onTabSelect={handleTabSelect}
            >
                {tabs.map((tab: string) => (
                    <Tabs.Item className="hc-bg-tabsItem" key={tab} name={tab}>
                        {t(tab)}
                    </Tabs.Item>
                ))}
                <Tabs.Content className="tabsContent" name={tabs[0]}>
                    {activeTab === tabs[0] && (
                        <SafetyLagging key={currentLocation} />
                    )}
                </Tabs.Content>
                <Tabs.Content className="tabsContent" name={tabs[1]}>
                    {activeTab === tabs[1] && (
                        <SafetyTotal key={currentLocation} />
                    )}
                </Tabs.Content>
                <Tabs.Content className="tabsContent" name={tabs[2]}>
                    {activeTab === tabs[2] && (
                        <SafetyLeading key={currentLocation} />
                    )}
                </Tabs.Content>
            </Tabs>
        </>
    )
}
