import { buildDataTableObject } from 'utils/data'
import { Table, Heading } from '@enterprise-ui/canvas-ui-react'
import { useEssentials } from 'hooks/utils/useEssentials'
import { addHyperlinkToFirstColumn } from 'utils/data'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { TableData } from 'utils/data'
const { loyalty } = TRANSLATION_KEYS
const { overview } = loyalty

interface INonStoreLocation {
    priorTableData: TableData
    currentTableData: TableData
}

const createDataTableObject = (
    tableData: TableData,
    currentLocation: string,
    pathname: string,
) => {
    const dataWithLink = addHyperlinkToFirstColumn(
        tableData,
        currentLocation,
        pathname,
    )
    return buildDataTableObject({
        currentData: dataWithLink,
        widths: [100, 100, 100],
    })
}

export const NonStoreOverview = ({
    priorTableData,
    currentTableData,
}: INonStoreLocation) => {
    const { t, currentLocation, pathname } = useEssentials()

    const currObj = createDataTableObject(
        currentTableData,
        currentLocation,
        pathname,
    )
    const priorObj = createDataTableObject(
        priorTableData,
        currentLocation,
        pathname,
    )
    return (
        <>
            <Heading className="hc-mb-normal">
                {t(overview.currentWeek)}
            </Heading>
            <Table data={currObj} scrollableWidth={true} />
            <Heading className="hc-mb-normal hc-mt-normal">
                {t(overview.lastWeek)}
            </Heading>
            <Table data={priorObj} scrollableWidth={true} />
        </>
    )
}
