import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useTrailerSummary } from 'hooks/trailer/useTrailerSummary'
import useCountdown from 'hooks/utils/useCountdown'
import { BasicPlaceholder } from 'components/placeholder'
import { ErrorAlert } from 'components/error-fallback'
import { Grid, Card, Table } from '@enterprise-ui/canvas-ui-react'
import { useEffect, useMemo, useState } from 'react'
import { transformShipmentsForTable } from './helpers'

export const TrailerSummaryTab = () => {
    const { currentLocation, env, logout, session, t } = useEssentials()
    const [transformedData, setTransformedData] = useState<Record<string, any>>(
        {},
    )

    let { data, isLoading, isError, error } = useTrailerSummary({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
    })

    const count = useCountdown(5, logout, error as Error)

    const {
        trailer: { summary },
    } = TRANSLATION_KEYS

    const sections = useMemo(
        () => ({
            unacknowledged_at_dc: t(summary.unacknowledgedDcTitle),
            unacknowledged_at_store: t(summary.unacknowledgedStoreTitle),
            unacknowledged_in_transit: t(summary.unacknowledgedTransitTitle),
            acknowledged: t(summary.acknowledgedTitle),
            unacknowledged_no_tracking: t(
                summary.unacknowledgedNoTrackingTitle,
            ),
        }),
        [summary, t],
    )

    useEffect(() => {
        if (data) {
            const newTransformedData: Record<string, any> = {}
            Object.keys(sections).forEach((key) => {
                newTransformedData[key] = transformShipmentsForTable(
                    data[key],
                    currentLocation,
                ) || [{}]
            })
            setTransformedData(newTransformedData)
        }
    }, [data, sections, currentLocation])

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
                <BasicPlaceholder type="table" rows={8} columns={3} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    return (
        <Grid.Container spacing="expanded">
            {Object.entries(sections).map(([key, title]) =>
                key in data ? (
                    <Card className="hc-mb-normal hc-pa-dense">
                        <h3 className="hc-mb-normal">{title}</h3>
                        <Table
                            data={transformedData[key]}
                            alternateRowColor={true}
                        />
                    </Card>
                ) : null,
            )}
        </Grid.Container>
    )
}
