import { ITranslationKeys } from '../ITranslationKeys'

export const en: ITranslationKeys = {
    alerts: 'Alerts',
    alert: {
        heading: 'Uh-oh! It seems there’s an issue.',
        generic:
            'Please refresh, or contact support if the problem continues. Thanks for your patience!',
        expiredSession: (count: number = 0) =>
            `It appears your session has expired. Redirecting in ${count} seconds...`,
        retry: 'Retry',
    },
    assetsProtection: {
        title: 'assets protection',
        tabs: {
            payroll: 'payroll',
            theft: 'theft',
            secure: 'secure',
        },
        secure: {
            title: 'security',
        },
    },
    indexRedirectMessage: 'Redirecting to login',
    locationSearch: {
        header: 'select location',
        searchLabel: 'search region, group, district, or store',
        matchNotFound:
            'no location matched your search. showing nearby locations.',
        nearbyLocationsHeader: 'nearby locations',
    },
    loyalty: {
        title: 'loyalty',
        tabs: {
            overview: 'overview',
            targetCircle: 'target circle',
            targetCircleCard: 'target circle card',
        },
        timePeriods: {
            currentWeek: 'current week',
            lastWeek: 'last week',
            monthToDay: 'month to date',
            yearToDay: 'year to date',
        },
        overview: {
            currentWeek: 'current week',
            lastWeek: 'last week',
            percentTranslationsTargetCircle: '% of transactions target circle',
            percentTranslationsTargetCircleCard:
                '% of transactions target circle card',
            Sun: 'Sun',
            Mon: 'Mon',
            Tue: 'Tue',
            Wed: 'Wed',
            Thu: 'Thu',
            Fri: 'Fri',
            Sat: 'Sat',
            location: 'location',
            total: 'total',
        },
        circleCard: {
            applications: 'applications',
            applicationNumbers: (totalApps: string, forecastApps: string) =>
                `${totalApps} of ${forecastApps}`,
        },
    },
    payroll: {
        title: 'payroll',
        tabs: {
            location: 'location',
            workgroup: 'work group',
            overtime: 'overtime',
            monthly: 'monthly',
        },
        location: {
            title: 'payroll by location',
            payrollByLocation: 'payroll by location',
            payrollByWeek: 'payroll by week',
            projFlexHours: 'proj flex hrs',
            guranteedFlexHours: 'guranteed flex hrs',
            bpFlextoplan: 'b/p flex to plan',
        },
        workgroup: {
            title: 'planned hours by work group',
        },
        overtime: {
            title: 'weekly overtime % of total hours - trend',
        },
    },
    itemSearch: {
        searchLabel: 'item search:',
        searchButtonText: 'search',
        searchInputPlaceholder: 'search or scan',
        placementCapacityDescription: ' fit',
        onHandRow: 'quantity on hand',
        onWithdrawalRow: 'quantity on the way',
        lastAuditedRow: 'last audited date',
        lastReceivedRow: 'last received date',
        lastSoldRow: 'last sold date',
        totalSalesCardTitle: 'total sales results',
        averageSalesCardTitle: 'average sales results',
        location: 'location',
        company: 'company',
        district: 'district',
        store: 'this store',
        unitsSold: 'units sold',
        sales: 'sales $',
        itemNotFound: 'item not found',
        quantityCheck: (floorQty: number = 0, backQty: number = 0) =>
            `${floorQty} on floor, ${backQty} in back`,
        tabs: {
            actual: 'actual',
            forecast: 'forecast',
        },
    },
    safety: {
        title: 'safety',
        lagging: {
            title: 'lagging safety',
        },
        total: {
            title: 'total safety',
        },
        leading: {
            title: 'leading safety',
        },
        tabs: {
            lagging: 'lagging',
            total: 'total',
            leading: 'leading',
        },
        nonStoreLabel:
            'Data updates on Wednesdays and is good through Saturday',
        store: {
            'Lagging Indicators': 'lagging indicators',
            'Total Incidents': 'total incidents',
            'Leading Indicators': 'leading indicators',
        },
    },
    sales: {
        title: 'sales',
        tabs: {
            overview: 'overview',
            details: 'details',
            location: 'location',
        },
        overview: {
            hourlySales: 'hourly sales',
            originated: 'originated',
            lineBusting: 'line busting',
            fulfilled: 'fulfilled',
            shipFromStore: 'ship from store',
            driveUp: 'drive up',
            orderPickup: 'order pickup',
            shipt: 'shipt',
            otherSales: 'other sales',
            saveTheSale: 'save the sale',
            goal: 'goal',
            lastYear: 'last year',
            compPercent: 'comp %',
            originatedCompPercent: 'originated comp %',
            fulfilledCompPercent: 'fulfilled comp %',
            basketSize: 'basket size',
            transactions: 'transactions',
            total: 'total',
            ly: 'ly',
            bpToLy: 'b/p to ly',
            bpToLyPercent: 'b/p to ly %',
            goalPercentage: 'with a goal percentage of',
            of: 'of',
        },
        details: {
            workarea: {
                title: 'area of work',
                tabs: {
                    sales: 'sales',
                    salesMix: 'sales mix',
                },
            },
            merch: {
                title: 'sales by merch',
                tabs: {
                    sales: 'sales',
                    salesMix: 'sales mix',
                },
            },
        },
        location: {
            title: 'sales by location',
            tabs: {
                sales: 'sales',
                forecast: 'forecast',
                compPercent: 'comp %',
            },
        },
    },
    shortage: {
        title: 'shortage',
        totalShortage: 'total shortage',
        lastInvetoryDate: 'last inventory date',
        nextScheduledInventoryDate: 'next scheduled inventory date',
        'last inventory date': 'last inventory date',
        'next scheduled inventory date': 'next scheduled inventory date',
        thisYear: {
            title: 'this year',
        },
        merch: {
            title: 'shortage',
        },
        top10: {
            title: 'top 10 shortage',
        },
    },
    storeFacts: {
        title: 'store facts',
        quickFacts: 'quick facts',
        lastYearSales: 'last year sales',
        originatedSales: 'originated sales',
        fulfilledSales: 'fulfilled sales',
        totalSales: 'total sales',
        hours: 'hours',
        keyDates: 'key dates',
        opened: 'opened',
        lastRemodel: 'last remodeled',
        packStation: 'pack station',
        leadership: 'leadership',
        store: 'store',
        role: 'role',
        name: 'name',
        since: 'since',
        capabilities: 'capabilities',
    },
    notes: {
        notes: 'notes',
        sales: 'sales',
        of: 'of',
        payroll: 'payroll',
        hours: 'hours',
        flexPlan: 'flex plan',
        weather: 'weather',
        keyStatistics: 'key statistics',
        pickOnTime: 'pick on time',
        sfsInf: 'sfs inf',
        opuInf: 'opu inf',
        high: 'high',
        low: 'low',
        errorText: 'oops, please enter a valid date',
        items: {
            addButton: 'add',
            todo: {
                title: 'to-do',
                label: 'add next to-do',
                key: 'to_do',
            },
            attendance: {
                title: 'attendance',
                label: 'add next attendance',
                key: 'attendance',
            },
            notes: {
                title: 'notes',
                label: 'add a note',
                key: 'notes',
            },
        },
        legacy: {
            title: 'legacy notes',
            subtitle: 'read only',
        },
    },
    operationalMetrics: {
        title: 'operations',
        recall: {
            tabs: {
                operations: 'operations',
                recall: 'recall',
            },
            headings: {
                location: 'location',
                irNumber: 'IR number',
                status: 'status',
            },
        },
    },
    trailer: {
        title: 'trailer',
        tabs: {
            summary: 'summary',
            schedule: 'schedule',
        },
        summary: {
            unacknowledgedDcTitle: 'unacknowledged at dc',
            unacknowledgedStoreTitle: 'unacknowledged at store',
            unacknowledgedTransitTitle: 'unacknowledged in transit',
            unacknowledgedNoTrackingTitle:
                'unacknowledged (no tracking available)',
            acknowledgedTitle: 'acknowledged',
        },
        schedule: {
            title: 'trailer schedule',
            timePeriods: {
                currentWeek: 'current week',
                lastWeek: 'last week',
                nextWeek: 'next week',
                threeWeeksOut: 'three weeks out',
            },
            headers: {
                unloadDateTime: 'unload date/time',
                sequence: 'sequence',
                type: 'type',
                sweep: 'sweep',
            },
        },
        rgd: {
            location: 'location',
            unacknowledged: 'unacknowledged',
            red: 'red (> 36hrs)',
            recentlyAcknowledged: 'recently acknowledged',
            today: 'today',
        },
        details: {
            zeroHours: '0 hrs',
            billOfLading: 'bill of lading',
            lastApplication: 'last application',
            table: {
                type: 'type',
                closed: 'closed',
                trailerNumber: 'trailer #',
                cartons: 'cartons',
                leadTime: 'lead time',
                laborHours: 'labor hours',
                stockingTime: 'stocking time',
                secondarySortingTime: 'secondary sorting time',
                customBlock: 'custom block',
                caseCartons: 'case cartons',
                repackCartons: 'repack cartons',
                sortingTime: 'sorting time',
                total: 'total',
            },
            workArea: {
                workArea: 'work area',
                caseCartons: 'case cartons',
                caseUnit: 'case unit',
                repackCartons: 'repack cartons',
                repackUnits: 'repack units',
                total: 'total',
            },
        },
    },
    settings: {
        title: 'settings',
        info: 'info',
        you: 'you',
        location: 'location',
        version: 'version',
        help: 'help',
        feedback: 'feedback',
        language: 'language',
        languages: {
            en: 'en',
            es: 'es',
            EN: 'EN',
            ES: 'ES',
        },
        creatingPreferences: 'creating user preferences',
        updatingPreferences: 'updating user preferences',
        looksLikeSomethingWentWrong:
            'Looks like something went wrong updating user preferences',
        loading: 'loading',
    },
    unauthorized: {
        title: 'Unauthorized',
        message:
            'You do not have access to view details inside MPM. If you need access reach out to myPerformance.support@target.com.',
    },
    contact: {
        mainPhone: 'main phone',
        fax: 'fax',
    },
    location: {
        region: 'region',
        group: 'group',
        district: 'district',
        RDC: 'RDC',
        county: 'County',
    },
    timePeriods: {
        today: 'today',
        yesterday: 'yesterday',
        weekToDate: 'week to date',
        lastWeek: 'last week',
        monthToDate: 'month to date',
        yearToDate: 'year to date',
    },
    filters: {
        compPercent: 'comp %',
        viewInDollars: 'view in $',
        viewInPercent: 'view in %',
    },
    'weekly hours': 'weekly hours',
    'prior month': 'prior month',
    'Actual Hrs': 'Actual Hrs',
    'Plan Hrs': 'Plan Hrs',
    'Published Hrs': 'Published Hrs',
    'b/(p) to Plan': 'b/(p) to Plan',
    'b/(p) % to Plan': 'b/(p) % to Plan',
    'b/(p) % to Published': 'b/(p) % to Published',
    'Eastern Standard Time': 'Eastern Standard Time',
    'Central Standard Time': 'Central Standard Time',
    'Mountain Standard Time': 'Mountain Standard Time',
    'Pacific Standard Time': 'Pacific Standard Time',
    EST: 'EST',
    CST: 'CST',
    MST: 'MST',
    PST: 'PST',
    'Adult Beverage Pickup': 'Adult Beverage Pickup',
    'Wine & Beer Available': 'Wine & Beer Available',
    'Drive Up': 'Drive Up',
    'Starbucks Drive Up': 'Starbucks Drive Up',
    'Vandal Resistant Glass': 'Vandal Resistant Glass',
    'Fresh Order Pick Up': 'Fresh Order Pick Up',
    'Self Checkout': 'Self Checkout',
    'Accepts WIC': 'Accepts WIC',
    'Protects brands advocates in court safer shopping':
        'Protects brands advocates in court safer shopping',
    'Shipt Delivery': 'Shipt Delivery',
    'Consumer Cellular Cell Phone Activations':
        'Consumer Cellular Cell Phone Activations',
    'Fresh Grocery': 'Fresh Grocery',
    'AT&T Cell Phone Activations': 'AT&T Cell Phone Activations',
    'Off duty police or 3rd party security':
        'Off duty police or 3rd party security',
    'Prepaid Cell Phone Activations': 'Prepaid Cell Phone Activations',
    'Store Pickup': 'Store Pickup',
    'Mobile Operations': 'Mobile Operations',
    'Drive Up Returns': 'Drive Up Returns',
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
}
