import qs from 'qs'

import { MPMEnvType } from '../../utils/env'

export interface NearbyLocation {
    location_id: number
    type_code: string
    type_description: string
    sub_type_code: string
    status: string
    location_names: {
        name_type: string
        name: string
    }[]
    address: {
        address_line1: string
        address_line2: string | null
        city: string
        county: string
        region: string
        state: string
        postal_code: string
    }
    capabilities: {
        capability_code: string
        capability_name: string
        latitude: number | null
        longitude: number | null
        radius: number | null
        directions: string | null
        capability_attributes: {
            attribute_name: string
            attribute_value: string
        }[]
    }[]
    physical_specifications: {
        total_building_area: number
    }
    contact_information: {
        building_area: string
        telephone_type: string
        telephone_number: string
    }
    geographic_specifications: {
        latitude: number
        longitude: number
        time_zone_code: string
        iso_time_zone: string
    }
    milestones: {
        milestone_code: string
        milestone_date: string
    }[]
    rolling_operating_hours: {
        regular_event_hours: {
            days: {
                sequence_number: number
                date: string
                day_name: string
                is_open: boolean
                hours: {
                    begin_time: string
                    end_time: string
                    open_flag: boolean
                    close_flag: boolean
                }[]
                event_name: string | null
                event_message: string | null
            }[]
        }
    }
    distance: number
    distance_unit: string
}

interface NearbyLocationsResponse {
    place: string
    type: string
    within: number
    limit: number
    capabilities: unknown
    unit: string
    locale: string
    count: number
    locations: NearbyLocation[]
}

export const fetchNearbyLocations = async (
    env: MPMEnvType,
    accessToken?: string,
): Promise<NearbyLocationsResponse> => {
    const { apiKey, baseUrl } = env

    return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(async (position) => {
            const nearbyLocationQueryParams = qs.stringify({
                place: `${position.coords.latitude},${position.coords.longitude}`,
                type: 'store',
                within: 100,
            })
            const nearbyLocationsUrl = `${baseUrl}/location_proximities/v1/nearby_locations?${nearbyLocationQueryParams}`

            const nearbyLocationsOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'x-api-key': apiKey,
                },
            }

            const nearbyLocationsResponse = await fetch(
                nearbyLocationsUrl,
                nearbyLocationsOptions,
            )

            const parsedNearbyLocationsResponse =
                await nearbyLocationsResponse.json()

            resolve(parsedNearbyLocationsResponse)
        })
    })
}
