import { useLocation, useSearchParams } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import { useTranslation } from 'react-i18next'
import {
    Location,
    StoreLocation,
    LocationMapping,
    useUser,
} from 'components/user-context'
import { UrlParams } from 'enums/url-params'
import { useMPMEnv } from 'utils/env'

interface UseEssentialsType {
    t: ReturnType<typeof useTranslation>['t']
    i18n: ReturnType<typeof useTranslation>['i18n']
    params: ReturnType<typeof useSearchParams>[0]
    setParams: ReturnType<typeof useSearchParams>[1]
    currentLocation: string
    location: Location
    setLocation: ReturnType<typeof useUser>['setLocation']
    pathname: string
    env: ReturnType<typeof useMPMEnv>
    isAuthenticated: ReturnType<typeof useAuth>['isAuthenticated']
    login: ReturnType<typeof useAuth>['login']
    logout: ReturnType<typeof useAuth>['logout']
    session: ReturnType<typeof useAuth>['session']
    preferences: ReturnType<typeof useUser>['preferences']
    setPreferences: ReturnType<typeof useUser>['setPreferences']
    userAgent: UAParser.IResult
    greenfieldGroups: ReturnType<typeof useUser>['greenfieldGroups']
    setGreenfieldGroups: ReturnType<typeof useUser>['setGreenfieldGroups']
    storeLocationDetails: StoreLocation[]
    setStoreLocationDetails: ReturnType<
        typeof useUser
    >['setStoreLocationDetails']
    locationMapping: LocationMapping
    setLocationMapping: ReturnType<typeof useUser>['setLocationMapping']
}

export const useEssentials = (): UseEssentialsType => {
    const { t, i18n } = useTranslation()
    const [params, setParams] = useSearchParams()
    const {
        greenfieldGroups,
        setGreenfieldGroups,
        location,
        setLocation,
        preferences,
        setPreferences,
        userAgent,
        storeLocationDetails,
        setStoreLocationDetails,
        locationMapping,
        setLocationMapping,
    } = useUser()
    const env = useMPMEnv()
    const { isAuthenticated, login, logout, session } = useAuth()
    const { pathname } = useLocation()

    const currentLocation =
        params.get(UrlParams.LOCATION_ID) ?? location.shortName

    return {
        t,
        i18n,
        params,
        setParams,
        currentLocation,
        location,
        setLocation,
        pathname,
        env,
        isAuthenticated,
        login,
        logout,
        session,
        preferences,
        setPreferences,
        userAgent,
        greenfieldGroups,
        setGreenfieldGroups,
        storeLocationDetails,
        setStoreLocationDetails,
        locationMapping,
        setLocationMapping,
    }
}
