import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'

export const fetchTargetCircleCard = fetchGeneralData<any>(
    `${ApiSubUrls[Apis.REDCARD]}`,
)

export const fetchTargetCircleCardArchive = fetchGeneralData<any>(
    `${ApiSubUrls[Apis.AGGREGATIONS]}/redcards/counts`,
)
