import { Grid, Table } from '@enterprise-ui/canvas-ui-react'
import { TitledTableData, buildDataTableObject } from 'utils/data'
import {
    getDynamicSubPath,
    getLocationFilters,
    setFirstColumnInHeaders,
} from './helpers'
import { useSafetyStore } from 'hooks/safety/useSafetyStore'
import { BasicPlaceholder } from 'components/placeholder'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { translateItem, translateTitledTableData } from 'locales/utils'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const SafetyStore = () => {
    const { t, currentLocation, env, logout, session } = useEssentials()

    const dynamicSubPath = getDynamicSubPath(currentLocation)
    const filters = getLocationFilters(currentLocation)
    let { data, isLoading, isError, refetch, remove, error } = useSafetyStore({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        dynamicSubPath,
        filters,
    })
    useRefresh(refetch, remove)
    const count = useCountdown(5, logout, error as Error)

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="table" rows={5} columns={5} />
            </div>
        )
    }
    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    const { safety } = TRANSLATION_KEYS
    const {
        lagging: laggingKeys,
        total: totalKeys,
        leading: leadingKeys,
        store: storeKeys,
    } = safety
    const derivedData = structuredClone(data)
    let [lagging, total, leading] = derivedData

    total.table.headings = setFirstColumnInHeaders(total.table, 'timeframe')
    leading.table.headings = setFirstColumnInHeaders(leading.table, 'indicator')
    lagging = translateTitledTableData({
        t,
        data: lagging,
        translation: laggingKeys,
    })
    total = translateTitledTableData({ t, data: total, translation: totalKeys })
    leading = translateTitledTableData({
        t,
        data: leading,
        translation: leadingKeys,
    })

    return (
        <div>
            {[lagging, total, leading].map((titledTable: TitledTableData) => {
                let { title, table } = titledTable
                title = translateItem({
                    t,
                    item: title,
                    translation: storeKeys,
                })

                return (
                    <Grid.Container className="hc-pv-md" key={title}>
                        <Grid.Item xs={12} className="hc-pb-none">
                            <p className="hc-pa-min hc-lh-expanded hc-txt-lowercase">
                                {title}
                            </p>
                        </Grid.Item>
                        <Grid.Item xs={12} className="hc-pt-none">
                            <Table
                                data={buildDataTableObject({
                                    currentData: table,
                                    widths: [],
                                })}
                                name={title}
                            />
                        </Grid.Item>
                    </Grid.Container>
                )
            })}
        </div>
    )
}
