import { useQueries } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSalesLocation } from 'queries/sales/location'
import { SalesFetchProps } from 'types/api'
import { TIME } from 'utils/misc'

export function useSalesLocationHistorical({
    env,
    accessToken,
    location,
    filters,
    dynamicSubPath,
}: SalesFetchProps): any {
    const { tenMinutes } = TIME
    const timeDimensions = [
        'yesterday',
        'current_week',
        'last_week',
        'month_to_day',
        'year_to_day',
    ]

    const queries = timeDimensions.map((dimension) => {
        return {
            queryKey: [
                QueryKeys.SALES_LOCATION_HISTORICAL,
                location,
                dimension,
            ],
            queryFn: () =>
                fetchSalesLocation({
                    env,
                    accessToken,
                    filters: { ...filters, time_dimension: dimension },
                    dynamicSubPath,
                }),
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        }
    })

    return useQueries({
        queries,
    })
}
