import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'
import { TableData, TitledTableData } from 'utils/data'

export const fetchShortage = fetchGeneralData<TitledTableData[]>(
    `${ApiSubUrls[Apis.AGGREGATIONS]}/shortage/`,
)
export const fetchShortageTopTen = fetchGeneralData<TableData>(
    `${ApiSubUrls[Apis.AGGREGATIONS]}/shortage/`,
)
export const fetchMerchandise = fetchGeneralData<TableData>(
    `${ApiSubUrls[Apis.AGGREGATIONS]}/shortage/merchandise/`,
)
