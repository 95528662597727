import { useEffect, useState } from 'react'

const useCountdown = (
    initialCount: number,
    onComplete: () => void,
    error?: Error,
) => {
    const [count, setCount] = useState(initialCount)

    useEffect(() => {
        if (error?.message.includes('401')) {
            if (count <= 0) {
                onComplete()
                return () => clearInterval(timer)
            }

            const timer = setInterval(() => {
                setCount((prevCount) => prevCount - 1)
            }, 1000)

            return () => clearInterval(timer)
        }
    }, [count, error, onComplete])

    return count
}

export default useCountdown
