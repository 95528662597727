import { useEffect } from 'react'
import { LogLevel, logEvent } from '@praxis/component-logging'
import { Constants } from '../constants/constants'
import { Navigate } from 'react-router-dom'
import { useEssentials } from 'hooks/utils/useEssentials'
// import { TRANSLATION_KEYS } from 'locales/translation-keys'

export const HomePage = () => {
    const { isAuthenticated, login } = useEssentials()
    // const { indexRedirectMessage } = TRANSLATION_KEYS
    // const message = t(indexRedirectMessage)

    useEffect(() => {
        if (isAuthenticated()) {
            logEvent(
                {
                    message: `App - ${Constants.LOGIN_SUCCESSFUL}`,
                },
                { level: LogLevel.Info },
            )
        } else {
            logEvent(
                {
                    message: `App - ${Constants.LOGIN_FAIL}`,
                },
                { level: LogLevel.Info },
            )
            login()
        }
    }, [isAuthenticated, login])

    if (isAuthenticated()) {
        return <Navigate to="/sales" />
    }
    // TODO: make sure this consumes translations properly
    // eslint-disable-next-line react/jsx-no-literals
    return <h1>Redirecting to login</h1>
}
