import { useQueries } from '@tanstack/react-query'
import { Constants } from 'constants/constants'
import { QueryKeys } from 'constants/query-keys'
import { fetchLoyaltyOverview } from 'queries/loyalty/overview'
import { LoyaltyOverviewFetchProps } from 'types/api'
import { isStore, isCompany } from 'utils/location'
import { TIME } from 'utils/misc'
import {
    getGreenfieldDrillValues,
    getGreenfieldLocationFilter,
} from 'utils/greenfield'

export function useLoyaltyOverview({
    env,
    accessToken,
    location,
}: LoyaltyOverviewFetchProps): any[] {
    const { tenMinutes } = TIME
    const isCompanyLocation = isCompany(location)
    const isStoreLocation = isStore(location)
    const greenfieldCardNumber = isStoreLocation
        ? Constants.GREENFIELD_CARDS.LOYALTY.LOYALTY_OVERVIEW_STORE
        : Constants.GREENFIELD_CARDS.LOYALTY.LOYALTY_OVERVIEW_RGD
    const timeDimensions = ['current_week', 'prior_week']
    const drillFilters = getGreenfieldDrillValues(location)

    const queries = timeDimensions.map((dimension) => {
        const overviewFilters = {
            ...(!isCompanyLocation && getGreenfieldLocationFilter(location)),
            ...(!isCompanyLocation && drillFilters),
            time_dimension: dimension,
        }

        return {
            queryKey: [QueryKeys.LOYALTY_OVERVIEW, location, dimension],
            queryFn: () =>
                fetchLoyaltyOverview({
                    env,
                    accessToken,
                    dynamicSubPath: greenfieldCardNumber,
                    method: 'GET',
                    filters: overviewFilters,
                }),
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        }
    })

    return useQueries({
        queries,
    })
}
