export const messages = {
    appTitle: 'myPerformance Mobile',
    assetsProtection: 'assets protection',
    brandsLinkTitle: 'brands',
    greenfieldLinkTitle: 'greenfield',
    logOutCTA: 'log out',
    loyaltyLinkTitle: 'loyalty',
    navMenuAriaLabel: 'Open navigation menu',
    notesLinkTitle: 'notes',
    operationsLinkTitle: 'operations',
    payrollLinkTitle: 'payroll',
    safetyLinkTitle: 'safety',
    salesLinkTitle: 'sales',
    searchAriaLabel: 'Search for a location',
    settingsLinkTitle: 'settings',
    shortageLinkTitle: 'shortage',
    storeFactsLinkTitle: 'store facts',
    storeFulfillmentLinkTitle: 'store fulfillment',
    trailerLinkTitle: 'trailer',
    itemSearchTitle: 'item search',
}
