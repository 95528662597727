import { EnvProvider } from '@praxis/component-runtime-env'
import { AuthProvider, OnLoginCallback, useAuth } from '@praxis/component-auth'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { useEffect } from 'react'

import { LogLevel, logEvent } from '@praxis/component-logging'
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query'

import { Constants } from './constants/constants'
import { UserProvider, useUser } from './components/user-context'
import Router from './router'
import { useMPMEnv } from './utils/env'
import { QueryKeys } from './constants/query-keys'
import { fetchNearbyLocations } from './queries/getNearbyLocationsQuery'
import {
    NearbyLocationsProvider,
    useNearbyLocations,
} from './components/nearby-locations'

import FlagsProvider from './firebase/FirebaseRemoteConfig.provider'
import { FakeAuthenticationProvider } from 'utils/FakeAuthenticationProvider'

import './styles/index.scss'
import './app.css'
import { SplashScreen } from './components/splash-screen/splashScreen'
import { useUserDetails } from 'hooks/userDetails/useUserDetails'
import { LoginPlaceholder } from 'components/login-placeholder/loginPlaceholder'
import { useSetPreferences } from 'hooks/user-context/useSetPreferences'
import i18n from 'i18n'

const queryClient = new QueryClient()

const App = () => {
    return (
        <EnvProvider
            commonConfig={{ praxis: { docsText: 'Explore the documentation' } }}
            configPath={
                process.env.NODE_ENV === 'development'
                    ? '/config.json'
                    : '/app-environment'
            }
        >
            <FlagsProvider>
                <QueryClientProvider client={queryClient}>
                    <AppAuth />
                </QueryClientProvider>
            </FlagsProvider>
        </EnvProvider>
    )
}

/**
 * This will examine the current `REACT_APP_ENV` and either use the custom
 * <FakeAuthenticationProvider /> or the standard <AuthProvider /> from Praxis.
 *
 * To run using the fake auth, set REACT_APP_ENV to 'fakeauth'. Example:
 * `REACT_APP_ENV=fakeauth npm run start`
 * @example
 * <AuthSwitch>
 *   <p>I'm authorized (for real or pretend)!</p>
 * </AuthSwitch>
 * @param children Components to render within the chosen auth context.
 * @returns JXS.Element
 */
const AuthSwitch = ({ children }: { children: React.ReactNode }) => {
    const env = useMPMEnv()
    const onLogin: OnLoginCallback = (err, session) => {
        if (err) {
            logEvent(
                {
                    message: `App - ${session?.userInfo?.lanId} ${Constants.LOGIN_FAIL}`,
                },
                { level: LogLevel.Info },
            )
        } else {
            logEvent(
                {
                    message: `App - ${session?.userInfo?.lanId} ${Constants.LOGIN_SUCCESSFUL}`,
                },
                { level: LogLevel.Info },
            )
        }
    }
    return (
        <>
            {process.env.REACT_APP_ENV === Constants.FAKE_AUTH ? (
                <FakeAuthenticationProvider>
                    {children}
                </FakeAuthenticationProvider>
            ) : (
                <AuthProvider
                    authorizationUrl={env.auth.authorizationUrl}
                    clientId={env.auth.clientId}
                    loginRedirect={window.location.origin}
                    logoutRedirect={env.auth.logoutRedirect}
                    onLogin={onLogin}
                    loadingIndicator={LoginPlaceholder}
                >
                    {children}
                </AuthProvider>
            )}
        </>
    )
}

const AppAuth = () => {
    return (
        <AuthSwitch>
            <UserProvider>
                <NearbyLocationsProvider>
                    <HelveticaForTarget variants={['n4', 'n5']} />
                    <Router />
                </NearbyLocationsProvider>
            </UserProvider>
        </AuthSwitch>
    )
}

export interface IUserDetailsProps {
    children?: JSX.Element | JSX.Element[]
}
export const UserDetails = ({ children }: IUserDetailsProps) => {
    const env = useMPMEnv()
    const { session } = useAuth()
    const {
        setLocation,
        setGreenfieldGroups,
        setStoreLocationDetails,
        setLocationMapping,
    } = useUser()
    const { setNearbyLocations } = useNearbyLocations()
    const { isAuthenticated, login } = useAuth()

    const { lanId } = session?.userInfo ?? {
        lanId: '',
    }

    if (!isAuthenticated()) {
        login()
    }

    const preferences = useSetPreferences()
    const { data, isLoading } = useUserDetails({
        env,
        accessToken: session?.accessToken ?? '',
        lanId,
    })

    const { data: nearbyLocationsData } = useQuery(
        [QueryKeys.NEARBY_LOCATIONS],
        () => fetchNearbyLocations(env, session?.accessToken),
    )

    useEffect(() => {
        if (nearbyLocationsData?.locations) {
            setNearbyLocations(nearbyLocationsData.locations)
        }
    }, [nearbyLocationsData, setNearbyLocations])

    useEffect(() => {
        if (data?.user_details?.location_mapping?.location_id) {
            setLocation({
                locationId:
                    data.user_details.location_mapping.location_id.toString(),
                shortName: data.user_details.location_mapping.location,
                name: data.user_details.location_mapping.location_name,
            })
        }
        if (data?.greenfield_mpm_user_groups) {
            setGreenfieldGroups(data?.greenfield_mpm_user_groups)
        }
        if (data?.locations?.store_location_details) {
            setStoreLocationDetails(
                data.locations.store_location_details.map((location) => ({
                    ...location,
                    id: Number(location.id),
                })),
            )
        }
        if (data?.user_details?.location_mapping?.location_id) {
            setLocationMapping({
                location_id: Number(
                    data.user_details.location_mapping.location_id,
                ),
                location: data.user_details.location_mapping.location,
                location_name: data.user_details.location_mapping.location_name,
                entitled_for_non_store:
                    data.user_details.location_mapping.entitled_for_non_store,
            })
        }
    }, [
        data,
        setLocation,
        setGreenfieldGroups,
        setStoreLocationDetails,
        setLocationMapping,
    ])

    useEffect(() => {
        if (preferences) {
            const { user_contexts } = preferences
            if (user_contexts?.length) {
                const [user_context] = user_contexts
                const { app_context } = user_context
                const { language } = app_context
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                i18n.changeLanguage(language)
            }
        }
    }, [preferences])

    // returns SplashScreen before Authentication screen loads
    if (!session) {
        return <SplashScreen />
    }

    if (isLoading) {
        return <LoginPlaceholder />
    }

    return children
}

export default App
