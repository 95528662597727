import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'
import { Heading } from '@enterprise-ui/canvas-ui-react'
import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
const { loyalty } = TRANSLATION_KEYS
const { overview } = loyalty

interface IStoreOverview {
    storeCircleData: any
    storeCircleCardData: any
    storeCircleDataCurrent: any
    storeCircleCardDataCurrent: any
}

const Chart = ({ data, dataKey, label, fill, marginTop }: any) => (
    <ResponsiveContainer width="100%" height={200}>
        <BarChart
            data={data}
            margin={{
                left: -20,
                right: 20,
                top: marginTop,
            }}
        >
            <Tooltip
                contentStyle={{ backgroundColor: 'white', color: 'black' }}
                itemStyle={{ color: 'black' }}
                labelStyle={{ color: 'black' }}
                formatter={(value, name) => [
                    `${value}%`,
                    typeof name === 'string'
                        ? name.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
                        : name,
                ]}
            />
            <text
                x="50%"
                y={20}
                textAnchor="middle"
                fontSize="14px"
                fill="black"
            >
                {label}
            </text>
            <YAxis tickFormatter={(value) => `${value}%`} />
            <XAxis dataKey="name" />
            <Bar dataKey={dataKey} fill={fill} />
        </BarChart>
    </ResponsiveContainer>
)

export const StoreOverview = ({
    storeCircleData,
    storeCircleCardData,
    storeCircleDataCurrent,
    storeCircleCardDataCurrent,
}: IStoreOverview) => {
    const targetRed = '#cc0100'
    const targetGreen = '#008080'
    const { t } = useEssentials()

    return (
        <>
            <Heading>{t(overview.currentWeek)}</Heading>
            <Chart
                data={storeCircleDataCurrent}
                dataKey="circleTransactions"
                label={t(overview.percentTranslationsTargetCircle)}
                fill={targetGreen}
                marginTop={40}
            />
            <Chart
                data={storeCircleCardDataCurrent}
                dataKey="circleCardTransactions"
                label={t(overview.percentTranslationsTargetCircleCard)}
                fill={targetRed}
                marginTop={40}
            />
            <Heading>{t(overview.lastWeek)}</Heading>
            <Chart
                data={storeCircleData}
                dataKey="circleTransactions"
                label={t(overview.percentTranslationsTargetCircle)}
                fill={targetGreen}
                marginTop={40}
            />
            <Chart
                data={storeCircleCardData}
                dataKey="circleCardTransactions"
                label={t(overview.percentTranslationsTargetCircleCard)}
                fill={targetRed}
                marginTop={40}
            />
        </>
    )
}

export default StoreOverview
