import { useCallback, useRef } from 'react'
import { emitPullDownRefreshEvent } from 'utils/eventEmitter'

const usePullDown = (threshold = 100) => {
    const touchStartY = useRef(0)
    const touchEndY = useRef(0)

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        touchStartY.current = e.touches[0].clientY
    }, [])

    const handleTouchMove = useCallback((e: React.TouchEvent) => {
        touchEndY.current = e.touches[0].clientY
    }, [])

    const handleTouchEnd = useCallback(() => {
        if (touchEndY.current > touchStartY.current + threshold) {
            emitPullDownRefreshEvent()
        }
        touchStartY.current = 0
        touchEndY.current = 0
    }, [threshold])

    return { handleTouchStart, handleTouchMove, handleTouchEnd }
}

export default usePullDown
