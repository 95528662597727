import { format } from 'date-fns/format'
import {
    Cell,
    LoyaltyCellData,
    LoyaltyRowData,
    LoyaltyRowsData,
    TableData,
} from 'utils/data'
import { isRegion, isDistrict, isGroup, isRGD, isStore } from 'utils/location'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import type { TFunction } from 'i18next/typescript/t'
const { loyalty } = TRANSLATION_KEYS
const { overview } = loyalty

export const getLoyaltyFiltersForTargetCircleCard = (
    location: string,
): { [key: string]: string } => {
    const locationTypeChar = location.charAt(0)
    const locationId = location.slice(1)

    if (isStore(location)) {
        return {
            store_id: locationId,
            date: format(new Date(), 'yyyy-MM-dd'),
        }
    } else if (isRGD(location)) {
        let locationType = ''
        switch (locationTypeChar) {
            case 'R':
                locationType = 'REGION'
                break
            case 'G':
                locationType = 'GROUP'
                break
            case 'D':
                locationType = 'DISTRICT'
                break
        }

        return {
            location_id: locationId,
            location_type: locationType,
            date: format(new Date(), 'yyyy-MM-dd'),
        }
    } else {
        return {
            date: format(new Date(), 'yyyy-MM-dd'),
        }
    }
}

export const buildTableDataForTargetCircleCardStore = (
    data: Record<string, Record<string, number>>,
): TableData => {
    return {
        headings: [
            { value: 'location' },
            { value: 'total' },
            { value: 'forecast' },
            { value: '% to forecast' },
        ],
        rows: Object.entries(data).map(([category, values]) => {
            const rowData = [
                { value: category.charAt(0).toUpperCase() + category.slice(1) },
                ...Object.entries(values).map(([k, v]) => {
                    let numericVal = Number(v).toFixed(0)
                    let color = undefined
                    if (k === 'variance_percentage') {
                        numericVal = Number(v).toFixed(2) + '%'
                        color = Number(numericVal) > 100 ? '#32864E' : '#AE570F'
                    }
                    const value = '' + numericVal.toLocaleString()
                    return {
                        value,
                        color,
                    }
                }),
            ]
            return rowData
        }),
    }
}

export function buildTableDataForTargetCircleCardNonStore(data: {
    last_updated: string
    start_date: string
    end_date: string
    metrics: Record<
        string,
        {
            goal: number
            total_applications: number
            variance_percentage: number
        }
    >
    location_results: {
        last_updated: string
        child_location_id: number
        child_location_type: string
        metrics: Record<
            string,
            {
                goal: number
                total_applications: number
                variance_percentage: number
            }
        >
    }[]
}): TableData {
    const categories = ['all', 'store', 'digital']
    const metricLabels = ['total', 'forecast', '% to forecast']
    return {
        headings: [{ value: 'location' }].concat(
            categories.flatMap(() =>
                metricLabels.map((label) => ({ value: label })),
            ),
        ),
        rows:
            data?.location_results?.map((location) => {
                const { child_location_type, child_location_id, metrics } =
                    location
                const { all, store, digital } = metrics
                const locChar = child_location_type.charAt(0)
                const locId =
                    locChar === 'S'
                        ? child_location_id.toString().padStart(4, '0')
                        : child_location_id
                const locStr = (locChar === 'S' ? 'T' : locChar) + locId
                return [
                    { value: locStr },
                    {
                        value: all.total_applications?.toString() ?? '0',
                    },
                    { value: all.goal?.toString() ?? '0' },
                    {
                        value:
                            (all.variance_percentage?.toFixed(2) ?? '0') + '%',
                        color:
                            Number(all.variance_percentage?.toFixed(2)) > 100
                                ? '#32864E'
                                : '#AE570F',
                    },
                    {
                        value: store.total_applications?.toString() ?? '0',
                    },
                    { value: store.goal?.toString() ?? '0' },
                    {
                        value:
                            (store.variance_percentage?.toFixed(2) ?? 0) + '%',
                        color:
                            Number(store.variance_percentage?.toFixed(2)) > 100
                                ? '#32864E'
                                : '#AE570F',
                    },
                    {
                        value: digital.total_applications?.toString() ?? '0',
                    },
                    { value: digital.goal?.toString() ?? '0' },
                    {
                        value:
                            (digital.variance_percentage?.toFixed(2) ?? 0) +
                            '%',
                        color:
                            Number(digital.variance_percentage?.toFixed(2)) >
                            100
                                ? '#32864E'
                                : '#AE570F',
                    },
                ]
            }) ?? [],
    }
}

export const pickColumns = (
    data: TableData,
    columnIndexes: number[],
): TableData => {
    const newData = { ...data }
    newData.headings = columnIndexes
        .map((index) => data.headings?.[index])
        .filter((heading): heading is Cell => heading !== undefined)
    newData.rows = newData.rows.map((row) =>
        columnIndexes.map((index) => row[index]),
    )
    newData.footer = columnIndexes
        .map((index) => data.footer?.[index])
        .filter((heading): heading is Cell => heading !== undefined)

    return newData
}

export const addHyperLinkToFirstColumn = (
    data: TableData,
    currentLocation: string,
    pathname: string,
) => {
    if (data) {
        const { rows } = data
        if (rows) {
            data.rows = [...rows]?.map((row) => {
                const locationCell = row[0]
                if (!isStore(currentLocation)) {
                    locationCell.url =
                        pathname + '?locationId=' + (locationCell.value || '')
                }
                return row
            })
        }
    }
    return data
}

export const renameTargetCircleCardStoreColumns = (
    data: TableData,
    location: string,
) => {
    data.rows = data.rows.map((row) => {
        const keyMap: { [key: string]: string } = {
            All: location,
            Store: 'in-store',
            Digital: 'online',
        }
        if (keyMap[row[0].value]) {
            row[0].value = keyMap[row[0].value]
        }

        return row
    })

    return data
}

export const getDynamicSubPath = (location: string) => {
    if (isRegion(location)) {
        return `/REGION/${location}`
    } else if (isGroup(location)) {
        return `/GROUP/${location}`
    } else if (isDistrict(location)) {
        return `/DISTRICT/${location}`
    }
}

export const formatChartData = (tableData: any) => {
    if (!tableData?.rows) {
        return []
    }

    return tableData.rows.map((row: any[]) => ({
        name: row[0]?.value || 'Unknown',
        signups: Number(row[1]?.raw) || 0,
        forecast: Number(row[2]?.raw) || 0,
    }))
}

export const setFirstColumnInHeaders = (data: TableData, label: string) => {
    return data?.headings?.map((heading: Cell, index: number) => {
        if (index === 0) {
            heading.value = label
        }

        return heading
    })
}
export const updateFirstRowForStore = (data: TableData, location: string) => {
    if (isStore(location)) {
        data.rows[0][0].value = location
    }
    return data
}

export const fillMissingDays = (
    rows: LoyaltyRowData[],
    totalDays: number = 7,
) => {
    const missingDays = totalDays - rows.length
    if (missingDays > 0) {
        rows.push(...Array(missingDays).fill([{ value: 0 }]))
    }
}

export const createTableData = (
    rows: LoyaltyRowsData,
    footerData: LoyaltyCellData[],
    headings: string[],
): TableData => ({
    headings: headings.map((heading) => ({ value: heading }) as Cell),
    rows: rows?.map((row: LoyaltyRowData) => [
        { value: row[1]?.value },
        { value: row[2]?.value },
        { value: row[3]?.value },
    ]),
    footer: [footerData?.[0], footerData?.[2], footerData?.[3]],
})

const daysOfWeek = [
    overview.Sun,
    overview.Mon,
    overview.Tue,
    overview.Wed,
    overview.Thu,
    overview.Fri,
    overview.Sat,
]

export const createStoreChartData = (rows: LoyaltyRowsData, t: TFunction) => ({
    storeChartCircleData: rows?.map((item: LoyaltyRowData, index: number) => ({
        circleTransactions: item[4]?.value?.replace('%', ''),
        name: t(daysOfWeek[index]),
    })),
    storeChartCircleCardData: rows?.map(
        (item: LoyaltyRowData, index: number) => ({
            circleCardTransactions: item[5]?.value?.replace('%', ''),
            name: t(daysOfWeek[index]),
        }),
    ),
})
