import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEffect, useCallback, useState } from 'react'
import { useTargetCircle } from 'hooks/loyalty'
import {
    formatChartData,
    addHyperLinkToFirstColumn,
    setFirstColumnInHeaders,
    updateFirstRowForStore,
} from '../helpers'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import useRefresh from 'hooks/utils/useRefresh'
import { ErrorAlert } from 'components/error-fallback'
import { BasicPlaceholder } from 'components/placeholder'
import useCountdown from 'hooks/utils/useCountdown'
import { isDistrict, isGroup, isRegion, isRGD, isStore } from 'utils/location'
import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Bar,
    Tooltip,
} from 'recharts'
import { GeneralizedComponent } from 'components/generalized-component/generalized-component'

export const TargetCircleTab = () => {
    const { currentLocation, env, t, logout, session, pathname } =
        useEssentials()

    const { loyalty } = TRANSLATION_KEYS
    const [translatedTimePeriod, setTranslatedTimePeriod] = useState(
        t(loyalty.timePeriods.currentWeek),
    )
    const [timePeriod, setTimePeriod] = useState<string>('current_week')

    const [timePeriodIndex, setTimePeriodIndex] = useState<number>(0)
    const handleIndexUpdate = useCallback((index: number) => {
        const timePeriods = [
            'current_week',
            'last_week',
            'month_to_date',
            'year_to_date',
        ]
        setTimePeriod(timePeriods[index])
    }, [])

    const dynamicSubPath = '2565068'

    let rgdFilters = null
    if (isRGD(currentLocation)) {
        rgdFilters = {
            drill_value: currentLocation,
            drill_dimension: '',
        }
        if (isRegion(currentLocation)) {
            rgdFilters = { ...rgdFilters, drill_dimension: 'REGION' }
        } else if (isGroup(currentLocation)) {
            rgdFilters = { ...rgdFilters, drill_dimension: 'GROUP' }
        } else if (isDistrict(currentLocation)) {
            rgdFilters = { ...rgdFilters, drill_dimension: 'DISTRICT' }
        }
    }
    let { data, isLoading, isError, refetch, remove, error } = useTargetCircle({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        filters: {
            time_dimension: timePeriod,
            ...(isStore(currentLocation) && {
                location_id: currentLocation,
            }),
            ...(rgdFilters && { ...rgdFilters }),
        },
        dynamicSubPath,
    })

    const count = useCountdown(5, logout, error as Error)

    useEffect(() => {
        if (timePeriodIndex !== undefined) {
            const getTimePeriodValue = (index: number) => {
                const keys = Object.keys(loyalty.timePeriods)
                return loyalty.timePeriods[
                    keys[index] as keyof typeof loyalty.timePeriods
                ]
            }
            setTranslatedTimePeriod(getTimePeriodValue(timePeriodIndex))
        }

        void refetch()
    }, [timePeriodIndex, loyalty, refetch])
    useRefresh(refetch, remove)

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
                <BasicPlaceholder type="table" rows={8} columns={3} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    if (data) {
        if (isStore(currentLocation)) {
            data = updateFirstRowForStore(data, currentLocation)
            data.footer = []
        }
        data.headings = setFirstColumnInHeaders(data, 'Location')
        data = addHyperLinkToFirstColumn(data, currentLocation, pathname)
        data.headings = data.headings?.map((heading) => {
            return { ...heading, value: t(heading.value) }
        })
    }
    const payload = {
        'view in %': [
            {
                title: '',
                subtitle: '',
                data: data ? { [currentLocation]: data } : {},
            },
            {
                title: '',
                subtitle: '',
                data: data ? { [currentLocation]: data } : {},
            },
            {
                title: '',
                subtitle: '',
                data: data ? { [currentLocation]: data } : {},
            },
            {
                title: '',
                subtitle: '',
                data: data ? { [currentLocation]: data } : {},
            },
        ],
    }

    const chartData = formatChartData(data)
    interface IChartData {
        signups: number
        forecast: number
    }
    const maxSignups = Math.max(
        ...chartData.map((item: IChartData) => item.signups),
    )
    const maxForecast = Math.max(
        ...chartData.map((item: IChartData) => item.forecast),
    )
    const yAxisDataKey = maxForecast > maxSignups ? 'forecast' : 'signups'

    return (
        <Grid.Container>
            <Grid.Item xs={12}>
                <GeneralizedComponent
                    payload={payload}
                    isLoading={isLoading}
                    scrollableWidth={false}
                    widths={[90, 90, 90, 100]}
                    expandededWidth={false}
                    onIndexUpdate={handleIndexUpdate}
                    parentHandleClick={(timePeriodIndex: number) => {
                        setTimePeriodIndex(timePeriodIndex)
                    }}
                    isError={isError || !data?.rows}
                    title={t(translatedTimePeriod)}
                    titleClassName={'hc-fs-lg'}
                >
                    <Card>
                        <ResponsiveContainer
                            width="100%"
                            height={240}
                            className={'hc-mv-lg'}
                        >
                            <BarChart
                                data={chartData}
                                {...{
                                    overflow: 'visible',
                                    margin: {
                                        left: 10,
                                        right: 20,
                                        top: 20,
                                        bottom: isStore(currentLocation)
                                            ? 10
                                            : 40,
                                    },
                                }}
                                barSize={30}
                            >
                                <CartesianGrid />
                                <XAxis
                                    dataKey="name"
                                    angle={isStore(currentLocation) ? 0 : -90}
                                    tick={{
                                        textAnchor: isStore(currentLocation)
                                            ? 'middle'
                                            : 'end',
                                    }}
                                    dy={5}
                                    dx={-5}
                                />

                                <YAxis
                                    dataKey={yAxisDataKey}
                                    label={{
                                        value: 'new signups',
                                        position: 'center',
                                        angle: -90,
                                        dx: -25,
                                    }}
                                />
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                    }}
                                    itemStyle={{ color: 'black' }}
                                    labelStyle={{ color: 'black' }}
                                />
                                <Bar
                                    dataKey="signups"
                                    fill="#008389"
                                    barSize={15}
                                />
                                <Bar
                                    dataKey="forecast"
                                    fill="#808080"
                                    barSize={15}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </GeneralizedComponent>
            </Grid.Item>
            <Grid.Item xs={12}>
                {isLoading && (
                    <div
                        className="placeholder-container"
                        data-testid="placeholder-grid"
                    >
                        <BasicPlaceholder type="table" rows={5} />
                    </div>
                )}
            </Grid.Item>
        </Grid.Container>
    )
}
