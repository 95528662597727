import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSafetyStore } from 'queries/safety/safety'
import { ShortageFetchProps } from 'types/api'
import { ITitleListResponse } from 'utils/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function useSafetyStore({
    env,
    accessToken,
    location,
    dynamicSubPath,
    filters,
}: ShortageFetchProps): ITitleListResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.SAFETY_STORE, location],
        () =>
            fetchSafetyStore({
                env,
                accessToken,
                location,
                dynamicSubPath,
                filters,
                trackEvent,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
