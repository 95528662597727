import { Layout } from 'components/layout'
import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'

export const Unauthorized = () => {
    const { t } = useEssentials()
    const { unauthorized } = TRANSLATION_KEYS
    return (
        <Layout heading={`${t(unauthorized.title)}`}>
            <p>{t(unauthorized.message)}</p>
        </Layout>
    )
}
