import { isStore as isStoreCheck } from 'utils/location'
import { Container } from 'components/container/Container'
import { useLoyaltyOverview } from 'hooks/loyalty/useLoyaltyOverview'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { useEssentials } from 'hooks/utils/useEssentials'
import { NonStoreOverview } from './NonStoreOverview'
import { BasicPlaceholder } from 'components/placeholder'
import { StoreOverview } from './StoreOverview'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import {
    fillMissingDays,
    createTableData,
    createStoreChartData,
} from '../helpers'
import type { LoyaltyCellData, TableData } from 'utils/data'

const { loyalty } = TRANSLATION_KEYS
const { overview } = loyalty

export const Overview = () => {
    const { t, currentLocation, env, logout, session } = useEssentials()
    const isStore = isStoreCheck(currentLocation)
    const count = useCountdown(5, logout)

    const [
        {
            data: currentWeekData,
            isLoading: isLoadingCurrent,
            isError: isErrorCurrent,
            error: errorCurrent,
            refetch,
            remove,
        },
        {
            data: priorWeekData,
            isLoading: isLoadingPrior,
            isError: isErrorPrior,
            error: errorPrior,
        },
    ] = useLoyaltyOverview({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
    })

    useRefresh(refetch, remove)

    const headings = [
        t(overview.location),
        'target circle',
        'target circle card',
    ]

    const priorRows = priorWeekData?.rows
    const currentRows = currentWeekData?.rows

    if (isStore && currentRows) {
        fillMissingDays(currentRows)
    }

    const currentFooterData = currentWeekData?.footer?.map(
        (footer: LoyaltyCellData) => [{ value: footer.value }][0],
    )

    const priorFooterData = currentWeekData?.footer?.map(
        (footer: LoyaltyCellData) => [{ value: footer.value }][0],
    )

    const nonStoreTableCurrent: TableData = !isStore
        ? createTableData(currentRows, currentFooterData, headings)
        : { headings: [], rows: [], footer: [] }

    const nonStoreTablePrior: TableData = !isStore
        ? createTableData(priorRows, priorFooterData, headings)
        : { headings: [], rows: [], footer: [] }

    const { storeChartCircleData, storeChartCircleCardData } =
        createStoreChartData(priorRows, t)

    const {
        storeChartCircleData: storeChartCircleDataCurrent,
        storeChartCircleCardData: storeChartCircleCardDataCurrent,
    } = createStoreChartData(currentRows, t)

    if (isErrorPrior || isErrorCurrent) {
        const errorToShow = isErrorPrior ? errorPrior : errorCurrent
        return <ErrorAlert error={errorToShow as Error} count={count} />
    }

    if (isLoadingPrior || isLoadingCurrent) {
        return !isStore ? (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" />
                <BasicPlaceholder type="table" rows={5} columns={3} />
                <BasicPlaceholder type="text" />
                <BasicPlaceholder type="table" rows={5} columns={3} />
            </div>
        ) : (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" />
                <BasicPlaceholder type="rect" height="200px" />
                <BasicPlaceholder type="rect" height="200px" />
            </div>
        )
    }

    return (
        <Container>
            {!isStore && (
                <NonStoreOverview
                    priorTableData={nonStoreTablePrior}
                    currentTableData={nonStoreTableCurrent}
                />
            )}
            {isStore && (
                <StoreOverview
                    storeCircleData={storeChartCircleData}
                    storeCircleCardData={storeChartCircleCardData}
                    storeCircleDataCurrent={storeChartCircleDataCurrent}
                    storeCircleCardDataCurrent={storeChartCircleCardDataCurrent}
                />
            )}
        </Container>
    )
}
