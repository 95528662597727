export const Constants = {
    RED_STRING: 'red',
    RED_HEX: '#AE570F',
    YELLOW_STRING: 'yellow',
    YELLOW_HEX: '#CCAC00',
    KEY_STATUS: 'status',
    KEY_BK_COLOR: 'bk_color',
    DATE_FORMAT: "'today,' MMM dd yyyy, h:mm a",
    CSS_TEXT_LOWERCASE: 'hc-txt-lowercase',
}
