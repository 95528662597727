import { useState } from 'react'
import {
    Button,
    ButtonGroup,
    Card,
    Divider,
    Grid,
    Heading,
} from '@enterprise-ui/canvas-ui-react'
import {
    ChatIcon,
    HelpIcon,
    InfoFilledIcon,
    ProfileIcon,
    StoreIcon,
    TextStringIcon,
} from '@enterprise-ui/icons'

import {
    FloatingActionButton,
    FloatingActionButtonContainer,
} from 'components/floating-action-button'
import { SplitRow } from 'components/grid'
import { Layout } from 'components/layout'
import { useUpdatePreferences } from '../../hooks/user-context/useUpdatePreferences'
import { useCreatePreferences } from '../../hooks/user-context/useCreatePreferences'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorAlert, ErrorFallback } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const SettingsPage = () => {
    const { settings } = TRANSLATION_KEYS
    const { t, i18n, location, env, session, preferences, setPreferences } =
        useEssentials()

    const [language, setLanguage] = useState<string>(
        preferences?.user_contexts?.[0]?.app_context?.language || 'en',
    )

    const { firstName, lanId } = session?.userInfo ?? {
        firstName: '',
        lanId: '',
    }

    const {
        createPreferences,
        isError: isCreateError,
        isLoading: isCreateLoading,
    } = useCreatePreferences({
        lanId,
        env,
        accessToken: session?.accessToken ?? '',
    })

    const {
        updatePreferences,
        isError: isUpdateError,
        isLoading: isUpdateLoading,
    } = useUpdatePreferences({
        lanId,
        env,
        accessToken: session?.accessToken ?? '',
        preferences,
    })

    const handleSuccess = (response: any) => {
        const newPreferences = {
            ...preferences,
            user_contexts: [response],
        }
        setPreferences(newPreferences)
    }

    const handleLanguageChange = (lang: string) => {
        if (language === lang) {
            return
        }
        setLanguage(lang)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        i18n.changeLanguage(lang)
        if (preferences.user_contexts.length === 0) {
            createPreferences(
                {
                    ...preferences,
                    language: lang,
                },
                {
                    onSuccess: handleSuccess,
                },
            )
        } else {
            updatePreferences(
                {
                    ...preferences,
                    language: lang,
                },
                {
                    onSuccess: handleSuccess,
                },
            )
        }
    }

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(settings.title)}
                />
            )}
        >
            <Layout
                heading={t(settings.title)}
                className="settings-content"
                isBottomNavDisplayed={false}
            >
                <>
                    {(isCreateError || isUpdateError) && (
                        <ErrorAlert className="hc-mv-md" />
                    )}
                </>
                <Card className="hc-pa-md">
                    <Heading size={4}>{t(settings.info)}</Heading>
                    <Divider className="hc-pb-sm" />
                    <Grid.Container justify="flex-start" align="center">
                        <SplitRow
                            icon={ProfileIcon}
                            start={t(settings.you)}
                            end={`${firstName} (${lanId})`}
                        />
                        <SplitRow
                            icon={StoreIcon}
                            start={t(settings.location)}
                            end={`${location.shortName} ${location.name}`}
                        />
                        <SplitRow
                            icon={InfoFilledIcon}
                            start={t(settings.version)}
                            end={`${process.env.REACT_APP_VERSION}`}
                        />
                        <SplitRow
                            icon={TextStringIcon}
                            start={t(settings.language)}
                            end={
                                <ButtonGroup>
                                    <Button
                                        className={`ghost ${language === 'en' ? 'lang-active' : ''}`}
                                        onClick={() => {
                                            handleLanguageChange('en')
                                        }}
                                        isLoading={
                                            isCreateLoading || isUpdateLoading
                                        }
                                    >
                                        {t(settings.languages.EN)}
                                    </Button>
                                    <Button
                                        className={`ghost ${language === 'es' ? 'lang-active' : ''}`}
                                        onClick={() => {
                                            handleLanguageChange('es')
                                        }}
                                        isLoading={
                                            isCreateLoading || isUpdateLoading
                                        }
                                    >
                                        {t(settings.languages.ES)}
                                    </Button>
                                </ButtonGroup>
                            }
                        />
                    </Grid.Container>
                </Card>
                <FloatingActionButtonContainer>
                    <FloatingActionButton icon={HelpIcon}>
                        {t(settings.help)}
                    </FloatingActionButton>
                    <FloatingActionButton icon={ChatIcon}>
                        {t(settings.feedback)}
                    </FloatingActionButton>
                </FloatingActionButtonContainer>
            </Layout>
        </ErrorBoundary>
    )
}
