type RefItem = {
    ref_id: string
    field_name: string
    obj_type: string
    column_display_name: string
    type: string
    data_type: string
    format_type: string
}
type DataItem = { [key: string]: string }
type MappedItem = { [fieldName: string]: string }

export function mapFieldNames(ref: RefItem[], data: DataItem[]): MappedItem[] {
    if (!ref) return []
    const refMap: Record<string, string> = ref.reduce(
        (acc: Record<string, string>, { ref_id, field_name }) => {
            acc[ref_id] = field_name
            return acc
        },
        {},
    )

    return data.map((item) => {
        const mappedItem: MappedItem = {}
        for (const [key, value] of Object.entries(item)) {
            if (refMap[key]) {
                mappedItem[refMap[key]] = value
            }
        }
        return mappedItem
    })
}

export const formatStoreTime = (time: string) => {
    return new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }).format(new Date(`1970-01-01T${time}`))
}
