import { LegacyNotesItems } from './utils/notes'

export const getLegacyNotes = (legacyNotes: LegacyNotesItems) => {
    const legacyKeys: {
        [key: string]: string
    } = {
        wins: 'wins',
        opportunities: 'opportunities',
        tm_attendance: 'attendance',
        tm_recognition: 'recognition',
        to_do: 'to do',
    }
    const legacyNotesContent = legacyNotes
        .map((note, index) => {
            if (index > 0 || !note) return ''
            const {
                general_notes = '',
                tm_attendance = '',
                tm_recognition = '',
                wins = '',
                opportunities = '',
                to_do = '',
            } = note ?? {}
            let content = ''
            const acknowledgements = {
                wins: wins ?? '',
                opportunities: opportunities ?? '',
                tm_attendance: tm_attendance ?? '',
                tm_recognition: tm_recognition ?? '',
                to_do: to_do ?? '',
            }
            Object.entries(acknowledgements).forEach(([key, value]) => {
                const item = value
                if (item.length > 0) {
                    const items = item.split('\n')
                    content += `\n${legacyKeys[key]}:\n\n`
                    items.forEach((itemStr: string) => {
                        if (itemStr.length > 0) {
                            content += '- ' + itemStr + '\n'
                        }
                    })
                }
            })
            if (general_notes.length > 0) {
                content += '\nnotes:\n\n' + general_notes + '\n\n'
            }
            return content
        })
        .join('')

    return legacyNotesContent
}
