import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSalesDetails } from 'queries/sales/details'
import { SalesDetailsFetchProps } from 'types/api'
import { TIME } from 'utils/misc'
import { getTodaysDateFormatted } from 'hooks/helpers'
import type { IResponse } from 'utils/api'

export function useSalesDetails({
    env,
    accessToken,
    dynamicSubPath,
    location,
    timePeriod,
}: SalesDetailsFetchProps): IResponse {
    const { tenMinutes } = TIME
    if (location === 'Company Target') {
        location = 'Company'
    }

    const dynamicSubPathArr = dynamicSubPath?.split('|') ?? []
    let dynamicUrl = ''
    if (dynamicSubPathArr) {
        const [group = '', division = '', department = ''] = dynamicSubPathArr
        dynamicUrl = [
            group && `/groups/${group}`,
            division && `divisions/${division}`,
            department && `departments/${department}`,
        ]
            .filter(Boolean)
            .join('/')
    }

    let filters: any = {}
    if (timePeriod === 'today') {
        filters = {
            intraday: `${true}`,
            sales_date: `${getTodaysDateFormatted()}`,
            time_dimension: timePeriod,
            location_id: location,
        }
    } else {
        filters = {
            intraday: `${false}`,
            time_dimension: timePeriod,
            location_id: location,
        }
    }

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.MERCHANDISE, location, timePeriod, dynamicUrl],
        () =>
            fetchSalesDetails({
                env,
                accessToken,
                dynamicSubPath: dynamicUrl,
                filters,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
