import { useQuery } from '@tanstack/react-query'
import { useAnalytics } from '@praxis/component-analytics'
import { TIME } from 'utils/misc'
import { QueryKeys } from 'constants/query-keys'
import { fetchTrailerSummary } from 'queries/trailer/trailer-summary'
import { stripInitialTAndLeadingZeroes } from 'utils/item-search'

export function useTrailerSummary({ env, accessToken, location }: any): any {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const simpleLocation = stripInitialTAndLeadingZeroes(location)

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.TRAILER_SUMMARY, location],
        () =>
            fetchTrailerSummary({
                env,
                accessToken,
                location,
                filters: {
                    store_id: simpleLocation,
                },
                trackEvent,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
