import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchTrailerRGD } from 'queries/trailer/trailer'
import { trailerRGDFetchProps } from 'types/api'
import { IResponseTrailerRGD } from 'utils/api'
import { TIME } from 'utils/misc'

export function useTrailerRGD({
    env,
    accessToken,
    location,
}: trailerRGDFetchProps): IResponseTrailerRGD {
    const { tenMinutes } = TIME

    if (location === 'Company Target' || location === 'Company') {
        location = 'TARGET'
    }

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.TRAILER_RGD, location],
        () =>
            fetchTrailerRGD({
                env,
                accessToken,
                filters: {
                    location_id: location,
                },
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
