import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { BaseFetchProps } from 'types/api'
import { TIME } from 'utils/misc'
import { fetchRecall } from 'queries/operations/recall'
import { useEssentials } from 'hooks/utils/useEssentials'

export function useRecall({ env, accessToken, filters }: BaseFetchProps) {
    const { currentLocation } = useEssentials()
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.OPERATIONS_RECALL, currentLocation],
        () =>
            fetchRecall({
                env,
                accessToken,
                filters,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
