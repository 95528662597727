import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollWorkgroupData } from 'queries/payroll/workgroup/workgroup'
import { PayrollFetchProps } from 'types/api'
import { TIME } from 'utils/misc'
import { getLocationFilter } from '../../helpers'

export function usePayrollWorkgroup({
    env,
    accessToken,
    location,
}: PayrollFetchProps) {
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.PAYROLL_WORKGROUP, location],
        () =>
            fetchPayrollWorkgroupData({
                env,
                accessToken,
                location,
                filters: {
                    ...getLocationFilter(location),
                },
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
