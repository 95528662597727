import { Layout } from 'components/layout/layout'
import { t } from 'i18next'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { TargetCircleTab } from './target-circle'
import { TargetCircleCard } from './target-circle-card'
import { Overview } from './overview'

export const LoyaltyPage = () => {
    const { loyalty } = TRANSLATION_KEYS
    const { tabs: tabsKeys } = loyalty
    const tabs = [
        t(tabsKeys.overview),
        t(tabsKeys.targetCircle),
        t(tabsKeys.targetCircleCard),
    ]

    const [activeTab, setActiveTab] = useState<string>(tabs[0])
    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)
    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(loyalty.title)}
                />
            )}
        >
            <Layout heading={t(loyalty.title)} className="hc-pa-none">
                <Tabs
                    activeTab={activeTab}
                    className="tabs hc-pv-dense"
                    justified
                    disableAdaptive={true}
                    onTabSelect={handleTabSelect}
                >
                    {tabs.map((tab: string) => (
                        <Tabs.Item
                            className="hc-bg-tabsItem hc-tw-balance hc-pr-sm hc-pl-sm hc-lh-dense"
                            key={tab}
                            name={tab}
                        >
                            {t(tab)}
                        </Tabs.Item>
                    ))}
                    <Tabs.Content className="tabsContent" name={tabs[0]}>
                        {activeTab === tabs[0] && <Overview />}
                    </Tabs.Content>
                    <Tabs.Content className="tabsContent" name={tabs[1]}>
                        {activeTab === tabs[1] && <TargetCircleTab />}
                    </Tabs.Content>
                    <Tabs.Content className="tabsContent" name={tabs[2]}>
                        {activeTab === tabs[2] && <TargetCircleCard />}
                    </Tabs.Content>
                </Tabs>
            </Layout>
        </ErrorBoundary>
    )
}
