import { useEffect } from 'react'
import { TitledFilteredTable } from 'components/titled-filtered-table/title-filtered-table'
import { useMerchandise } from 'hooks/shortage/useMerchandise'
import { useState } from 'react'
import { getLocationFilters } from 'utils/api'
import { TableData } from 'utils/data'
import { getTableFormat } from 'utils/table'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const MerchandiseGroups = () => {
    const { t, currentLocation, env, session } = useEssentials()

    const [drills, setDrills] = useState<string[]>([])

    const filters = getLocationFilters(currentLocation)
    const { data, isLoading } = useMerchandise({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        dynamicSubPath: drills.join('|'),
        filters,
    })
    const widths = [150, ...getTableFormat(data?.headings?.length ?? 20, 90)]

    const [currentData, setCurrentData] = useState<TableData>()
    const [selectedCell, setSelectedCell] = useState<string>('')

    useEffect(() => {
        if (data) {
            setSelectedCell('')
            setCurrentData(data)
        }
    }, [data])

    if (!currentData) return null

    const handleClick = (selected: string) => {
        setSelectedCell(selected)
        const selectedIndex = currentData.rows.findIndex(
            (row) => row[0].value === selected,
        )
        const firstNonInteractiveIndex = currentData.rows.findIndex(
            (row) => row[0].is_link === false,
        )
        if (selectedIndex < firstNonInteractiveIndex) {
            setDrills([...drills].slice(0, selectedIndex))
        } else {
            setDrills([...drills, selected])
        }
    }

    const { shortage } = TRANSLATION_KEYS

    return (
        <TitledFilteredTable
            title={t(shortage.merch.title)}
            data={currentData}
            widths={widths}
            handleClick={handleClick}
            pinFirstColumn={true}
            selectedCell={selectedCell}
            isLoading={isLoading}
        />
    )
}
