import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSalesLocation } from 'queries/sales/location'
import { SalesFetchProps } from 'types/api'
import { TIME } from 'utils/misc'

export function useSalesLocation({
    env,
    accessToken,
    location,
    filters,
    dynamicSubPath,
}: SalesFetchProps): any {
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.SALES_LOCATION, location],
        () =>
            fetchSalesLocation({
                env,
                accessToken,
                location,
                filters,
                dynamicSubPath,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
