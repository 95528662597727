import { ITranslationKeys } from '../ITranslationKeys'

export const es: ITranslationKeys = {
    alerts: 'Alertas',
    alert: {
        heading: '¡Ups! Parece que hay un problema.',
        generic:
            'Por favor, actualiza la página o contacta con soporte si el problema persiste. ¡Gracias por tu paciencia!',
        expiredSession: (count: number = 0) =>
            `Parece que tu sesión ha expirado. Redirigiendo en ${count} segundos...`,
        retry: 'Reintentar',
    },
    assetsProtection: {
        title: 'assets protection',
        tabs: {
            payroll: 'nómina',
            theft: 'robo',
            secure: 'seguro',
        },
        secure: {
            title: 'seguro',
        },
    },
    loyalty: {
        title: 'loyalty',
        tabs: {
            overview: 'overview',
            targetCircle: 'target circle',
            targetCircleCard: 'target circle card',
        },
        timePeriods: {
            currentWeek: 'semana a la fecha',
            lastWeek: 'última semana',
            monthToDay: 'del mes a la fecha',
            yearToDay: 'del año a la fecha',
        },
        overview: {
            currentWeek: 'current week',
            lastWeek: 'last week',
            percentTranslationsTargetCircle: '% of transactions target circle',
            percentTranslationsTargetCircleCard:
                '% of transactions target circle card',
            Sun: 'Sun',
            Mon: 'Mon',
            Tue: 'Tue',
            Wed: 'Wed',
            Thu: 'Thu',
            Fri: 'Fri',
            Sat: 'Sat',
            location: 'ubicación',
            total: 'total',
        },
        circleCard: {
            applications: 'applications',
            applicationNumbers: (totalApps: string, forecastApps: string) =>
                `${totalApps} of ${forecastApps}`,
        },
    },
    indexRedirectMessage: 'Redirecting to login',
    locationSearch: {
        header: 'select location',
        searchLabel: 'search region, group, district, or store',
        matchNotFound:
            'no location matched your search. showing nearby locations.',
        nearbyLocationsHeader: 'nearby locations',
    },
    payroll: {
        title: 'nómina',
        tabs: {
            location: 'ubicación',
            workgroup: 'grupo de trabajo',
            overtime: 'horas extra',
            monthly: 'mensualmente',
        },
        location: {
            title: 'nómina por ubicación',
            payrollByLocation: 'nómina por ubicación',
            payrollByWeek: 'nómina por semana',
            projFlexHours: 'horas flexibles proyectadas',
            guranteedFlexHours: 'horas flexibles garantizadas',
            bpFlextoplan: 'm/(p) flex para planificar',
        },
        workgroup: {
            title: 'horas planificadas por grupo de trabajo',
        },
        overtime: {
            title: '% de horas extra semanales de las horas totales - tendencia',
        },
    },
    itemSearch: {
        searchLabel: 'item search:',
        searchButtonText: 'search',
        searchInputPlaceholder: 'search or scan',
        placementCapacityDescription: ' fit',
        onHandRow: 'quantity on hand',
        onWithdrawalRow: 'quantity on the way',
        lastAuditedRow: 'last audited date',
        lastReceivedRow: 'last received date',
        lastSoldRow: 'last sold date',
        totalSalesCardTitle: 'total sales results',
        averageSalesCardTitle: 'average sales results',
        location: 'location',
        company: 'company',
        district: 'district',
        store: 'this store',
        unitsSold: 'units sold',
        sales: 'sales $',
        itemNotFound: 'item not found',
        quantityCheck: (floorQty: number = 0, backQty: number = 0) =>
            `${floorQty} on floor, ${backQty} in back`,
        tabs: {
            actual: 'actual',
            forecast: 'forecast',
        },
    },
    safety: {
        title: 'seguridad',
        lagging: {
            title: 'lagging safety',
        },
        total: {
            title: 'total safety',
        },
        leading: {
            title: 'leading safety',
        },
        tabs: {
            lagging: 'rezagados',
            total: 'total',
            leading: 'leading',
        },
        nonStoreLabel:
            'Los datos se actualizan el miércoles y son válidos hasta el sábado',
        store: {
            'Lagging Indicators': 'indicadores rezagados',
            'Total Incidents': 'total incidents',
            'Leading Indicators': 'leading indicators',
        },
    },
    sales: {
        title: 'ventas',
        tabs: {
            overview: 'general',
            details: 'details',
            location: 'ubicación',
        },
        overview: {
            hourlySales: 'ventas por hora',
            originated: 'originadas',
            lineBusting: 'de cobro móvil',
            fulfilled: 'de Fulfillment',
            shipFromStore: 'ship from store',
            driveUp: 'drive up',
            orderPickup: 'order pickup',
            shipt: 'shipt',
            otherSales: 'other sales',
            saveTheSale: 'save the sale',
            goal: 'meta',
            lastYear: 'last year',
            compPercent: '% comp',
            originatedCompPercent: '% comparativo de originadas',
            fulfilledCompPercent: '% comparativo de Fulfillment',
            basketSize: 'tamaño de la canasta',
            transactions: 'transacciones',
            total: 'total',
            ly: 'año pasado',
            bpToLy: 'b/p to ly',
            bpToLyPercent: 'b/p to ly %',
            goalPercentage: 'with a goal percentage of',
            of: 'de',
        },
        details: {
            workarea: {
                title: 'área de trabajo',
                tabs: {
                    sales: 'sales',
                    salesMix: 'sales mix',
                },
            },
            merch: {
                title: 'ventas por mercancía',
                tabs: {
                    sales: 'sales',
                    salesMix: 'sales mix',
                },
            },
        },
        location: {
            title: 'ventas por ubicación',
            tabs: {
                sales: 'ventas',
                forecast: 'pronóstico',
                compPercent: '% comp',
            },
        },
    },
    shortage: {
        title: 'descuadre de inventario',
        totalShortage: 'descuadre total',
        lastInvetoryDate: 'última fecha de inventario',
        nextScheduledInventoryDate: 'fecha de siguiente inventario programado',
        'last inventory date': 'última fecha de inventario',
        'next scheduled inventory date':
            'fecha de siguiente inventario programado',
        thisYear: {
            title: 'este año',
        },
        merch: {
            title: 'descuadre de inventario',
        },
        top10: {
            title: '10 descuadres principales',
        },
    },
    storeFacts: {
        title: 'store facts',
        quickFacts: 'datos rápidos',
        lastYearSales: 'ventas del año pasado',
        originatedSales: 'ventas originadas',
        fulfilledSales: 'ventas de Fulfillment',
        totalSales: 'ventas totales',
        hours: 'horas',
        keyDates: 'key dates',
        opened: 'abierto',
        lastRemodel: 'última remodelación',
        packStation: 'estaciones de empaque',
        leadership: 'liderazgo',
        store: 'tienda',
        role: 'role',
        name: 'name',
        since: 'since',
        capabilities: 'capacidades',
    },
    notes: {
        notes: 'notas',
        sales: 'ventas',
        payroll: 'nómina',
        of: 'de',
        hours: 'horas',
        flexPlan: 'flex plan',
        weather: 'weather',
        keyStatistics: 'key statistics',
        pickOnTime: 'pick on time',
        sfsInf: 'sfs inf',
        opuInf: 'opu inf',
        high: 'high',
        low: 'low',
        errorText: 'oops, please enter a valid date',
        items: {
            addButton: 'add',
            todo: {
                title: 'to-do',
                label: 'add next to-do',
                key: 'to_do',
            },
            attendance: {
                title: 'attendance',
                label: 'add next attendance',
                key: 'attendance',
            },
            notes: {
                title: 'notes',
                label: 'add a note',
                key: 'notes',
            },
        },
        legacy: {
            title: 'legacy notes',
            subtitle: 'read only',
        },
    },
    operationalMetrics: {
        title: 'operations',
        recall: {
            tabs: {
                operations: 'operations',
                recall: 'recall',
            },
            headings: {
                location: 'location',
                irNumber: 'IR number',
                status: 'status',
            },
        },
    },
    trailer: {
        title: 'trailer',
        tabs: {
            summary: 'summary',
            schedule: 'schedule',
        },
        summary: {
            unacknowledgedDcTitle: 'unacknowledged at dc',
            unacknowledgedStoreTitle: 'unacknowledged at store',
            unacknowledgedTransitTitle: 'unacknowledged in transit',
            unacknowledgedNoTrackingTitle:
                'unacknowledged (no tracking available)',
            acknowledgedTitle: 'acknowledged',
        },
        schedule: {
            title: 'trailer schedule',
            timePeriods: {
                currentWeek: 'current week',
                lastWeek: 'last week',
                nextWeek: 'next week',
                threeWeeksOut: 'three weeks out',
            },
            headers: {
                unloadDateTime: 'unload date/time',
                sequence: 'sequence',
                type: 'type',
                sweep: 'sweep',
            },
        },
        rgd: {
            location: 'ubicación',
            unacknowledged: 'unacknowledged',
            red: 'red (> 36 hrs)',
            recentlyAcknowledged: 'recently acknowledged',
            today: 'hoy',
        },
        details: {
            zeroHours: '0 hrs',
            billOfLading: 'bill of lading',
            lastApplication: 'last application',
            table: {
                type: 'type',
                closed: 'closed',
                trailerNumber: 'trailer #',
                cartons: 'cartons',
                leadTime: 'lead time',
                laborHours: 'labor hours',
                stockingTime: 'stocking time',
                secondarySortingTime: 'secondary sorting time',
                customBlock: 'custom block',
                caseCartons: 'case cartons',
                repackCartons: 'repack cartons',
                sortingTime: 'sorting time',
                total: 'total',
            },
            workArea: {
                workArea: 'work area',
                caseCartons: 'case cartons',
                caseUnit: 'case unit',
                repackCartons: 'repack cartons',
                repackUnits: 'repack units',
                total: 'total',
            },
        },
    },
    settings: {
        title: 'configuración',
        info: 'información',
        you: 'usted',
        location: 'Ubicación',
        version: 'versión',
        help: 'ayuda',
        feedback: 'opiniones',
        language: 'idioma',
        languages: {
            en: 'en',
            es: 'es',
            EN: 'EN',
            ES: 'ES',
        },
        creatingPreferences: 'creando las preferencias del usuario',
        updatingPreferences: 'actualizando las preferencias del usuario',
        looksLikeSomethingWentWrong:
            'Parece que algo falló al actualizar las preferencias del usuario',
        loading: 'cargando',
    },
    unauthorized: {
        title: 'Unauthorized',
        message:
            'You do not have access to view details inside MPM. If you need access reach out to myPerformance.support@target.com.',
    },
    contact: {
        mainPhone: 'teléfono principal',
        fax: 'fax',
    },
    location: {
        region: 'región',
        group: 'grupo',
        district: 'distrito',
        RDC: 'RDC',
        county: 'County',
    },
    timePeriods: {
        today: 'hoy',
        yesterday: 'ayer',
        weekToDate: 'semana a la fecha',
        lastWeek: 'última semana',
        monthToDate: 'del mes a la fecha',
        yearToDate: 'del año a la fecha',
    },
    filters: {
        compPercent: '% comp',
        viewInPercent: 'ver en %',
        viewInDollars: 'ver en $',
    },
    'weekly hours': 'horas semanales',
    'prior month': 'mes anterior',
    'Actual Hrs': 'hrs reales',
    'Plan Hrs': 'hrs planificadas',
    'Published Hrs': 'hrs publicadas',
    'b/(p) to Plan': 'b/(p) respecto a planeadas',
    'b/(p) % to Plan': '% b/(p) respecto a planeadas',
    'b/(p) % to Published': '% b/(p) respecto a publicadas',
    'Eastern Standard Time': 'hora del este',
    'Central Standard Time': 'hora del centro',
    'Mountain Standard Time': 'hora de la montaña',
    'Pacific Standard Time': 'hora del Pacífico',
    EST: 'hora del este',
    CST: 'hora del centro',
    MST: 'hora de la montaña',
    PST: 'hora del Pacífico',
    'Adult Beverage Pickup': 'Servicio Pickup de bebidas para adultos',
    'Wine & Beer Available': 'Vino y cerveza disponibles',
    'Drive Up': 'Servicio Drive Up',
    'Starbucks Drive Up': 'Servicio Drive Up de Starbucks',
    'Vandal Resistant Glass': 'Vidrio resistente al vandalismo',
    'Fresh Order Pick Up': 'Servicio Order Pick Up de productos frescos',
    'Self Checkout': 'Cajas de autopago',
    'Accepts WIC': 'Acepta WIC',
    // eslint-disable-next-line @stylistic/js/max-len
    'Protects brands advocates in court safer shopping':
        'Protege las marcas, aboga en tribunales, crea un ambiente de compras más seguro',
    'Shipt Delivery': 'Entrega de Shipt',
    'Consumer Cellular Cell Phone Activations':
        'Activaciones de teléfonos celulares de Consumer Cellular',
    'Fresh Grocery': 'Comestibles frescos',
    'AT&T Cell Phone Activations':
        'Activaciones de teléfonos celulares de AT&T',
    'Off duty police or 3rd party security':
        'Oficiales de policía fuera de servicio o guardias de seguridad de servicios externos',
    'Prepaid Cell Phone Activations':
        'Activaciones de teléfonos celulares prepagados',
    'Store Pickup': 'Entrega de pedidos en la tienda',
    'Mobile Operations': 'Operaciones móviles',
    'Drive Up Returns': 'Devoluciones de Drive Up',
    sunday: 'domingo',
    monday: 'lunes',
    tuesday: 'martes',
    wednesday: 'miércoles',
    thursday: 'jueves',
    friday: 'viernes',
    saturday: 'sábado',
}
