import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'
import { getYDomainForForecast } from './helpers'

type ItemSearchChartProps = {
    item_details: any
}

export const ItemSearchChart = ({ item_details }: ItemSearchChartProps) => {
    const { t } = useEssentials()
    const { itemSearch } = TRANSLATION_KEYS
    const { tabs } = itemSearch
    const { actual, forecast } = tabs
    const [activeTab, setActiveTab] = useState<string>(actual)

    if (item_details === undefined) {
        return <></>
    }
    const { sales_history, store_sales_forecast } = item_details
    const { sales_history_average } = sales_history
    const selectedDetails =
        activeTab === actual ? sales_history_average : store_sales_forecast
    const { location_details, time_line } = selectedDetails
    const allQuantities: number[] = []
    const chartData = time_line.map((time: string, index: number) => {
        const dataPoint: Record<string, any> = { name: time }

        location_details.forEach((location: any) => {
            const quantity =
                location.time_lines_quantity?.[index]?.quantity || 0
            dataPoint[location.location] = quantity
            allQuantities.push(Number(quantity))
        })

        return dataPoint
    })

    const yAxisMax = Math.max(...allQuantities)

    const [minYForecast, maxYForecast] = getYDomainForForecast(chartData)

    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)

    const forecastStore = store_sales_forecast.location_details[0]
    const forecastStoreQuantity = forecastStore.time_lines_quantity.reduce(
        (acc: number, item: any) => {
            return acc + Number(item.quantity)
        },
        0,
    )

    const isTabsRequired = sales_history_average && forecastStoreQuantity > 0

    return (
        <>
            {isTabsRequired && (
                <Tabs
                    className={'kpi'}
                    activeTab={activeTab}
                    onTabSelect={handleTabSelect}
                    disableAdaptive={true}
                >
                    <Tabs.Item name={actual}>{t(actual)}</Tabs.Item>
                    <Tabs.Item name={forecast}>{t(forecast)}</Tabs.Item>
                </Tabs>
            )}
            <ResponsiveContainer width={'100%'} height={300}>
                <LineChart
                    data={chartData}
                    {...{
                        overflow: 'visible',
                        margin: {
                            left: -20,
                            right: 40,
                            top: 40,
                        },
                    }}
                >
                    <XAxis
                        dataKey="name"
                        tick={{ fontSize: '14px' }}
                        interval={0}
                        angle={-90}
                        height={95}
                        dy={45}
                    />
                    <YAxis
                        type="number"
                        allowDecimals={false}
                        tick={{ fontSize: '14px' }}
                        domain={
                            activeTab === actual
                                ? [0, yAxisMax]
                                : [minYForecast, maxYForecast]
                        }
                    />
                    <Tooltip />
                    <Legend
                        wrapperStyle={{
                            fontSize: '14px',
                            textAlign: 'center',
                            left: 0,
                            margin: '0 auto',
                            minWidth: '100%',
                            maxWidth: '100%',
                        }}
                    />
                    <CartesianGrid stroke="#dedede" strokeDasharray="5 5" />
                    {location_details.map((locationDetail: any) => (
                        <Line
                            key={locationDetail.location}
                            type="monotone"
                            dataKey={locationDetail.location}
                            stroke={locationDetail.color}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}
