import { format } from 'date-fns'
import { Cell, TableData, getMultipleSlices } from 'utils/data'
import { isDistrict, isGroup, isRegion, isStore } from 'utils/location'

export const getTabs = (location: string): string[] => {
    const commonTabs = ['work group', 'overtime']
    return isStore(location)
        ? ['monthly', ...commonTabs]
        : ['location', ...commonTabs]
}
export const getHeaderText = (location: string) => {
    if (isStore(location)) {
        return 'payroll by week'
    }
    return 'payroll by location'
}
export const getFormattedReportDate = () => {
    const reportDate = new Date()
    return format(reportDate, 'MMM d')
}
export const getFormattedDateMonthandYear = () => {
    const reportDate = new Date()
    return format(reportDate, 'MMM yyyy')
}
export const getDynamicSubPath = (location: string) => {
    if (isRegion(location)) {
        return `/REGION/${location}`
    } else if (isGroup(location)) {
        return `/GROUP/${location}`
    } else if (isDistrict(location)) {
        return `/DISTRICT/${location}`
    }

    return ''
}
export const getLocationTimePeriodType = (location: string) => {
    if (isStore(location)) {
        return 'weekly'
    }
    return 'monthly'
}
export const getLocationPlannedIndexes = (location: string) => {
    return {
        startIndex: 6,
        endIndex: 9,
    }
}
export const getLocationTableIndexes = (location: string) => {
    if (isStore(location)) {
        return {
            startIndex: 4,
            endIndex: 7,
        }
    }
    return {
        startIndex: 3,
        endIndex: 6,
    }
}
export const getLocationFlexHours = (location: string, footer: Cell[]) => {
    const flexIndexes = getLocationPlannedIndexes(location)
    const flexHours = getMultipleSlices(footer, [
        flexIndexes.startIndex,
        flexIndexes.endIndex,
    ])

    let [projectedFlexHours, guaranteedFlexHours, plannedFlexHours] = flexHours
    return {
        projectedFlexHours,
        guaranteedFlexHours,
        plannedFlexHours,
    }
}
export const pickData = (
    currentData: TableData,
    currentLocation: string,
    tableStartIndex: number,
    tableEndIndex: number,
    firstHeader: string,
) => {
    const filteredCopy = { ...currentData }
    const { headings, rows, footer } = currentData
    const sliceParams = isStore(currentLocation)
        ? [[tableStartIndex, tableEndIndex]]
        : [[tableStartIndex, tableEndIndex], 8]
    if (headings) {
        filteredCopy.headings = getMultipleSlices(
            headings,
            ...(sliceParams as any),
        )
        filteredCopy.headings.unshift({ value: firstHeader })
    }
    if (rows) {
        filteredCopy.rows = [...rows].map((row) => {
            let pickColumns = null
            if (row[0].childOf) {
                pickColumns = getMultipleSlices(row, [3, 6])
            } else {
                pickColumns = getMultipleSlices(row, ...(sliceParams as any))
            }
            return [row[0], ...pickColumns]
        })
    }
    if (footer) {
        filteredCopy.footer = getMultipleSlices(footer, ...(sliceParams as any))
    }
    return filteredCopy
}
export const addHyperLinkToFirstColumn = (
    data: TableData,
    currentLocation: string,
    pathname: string,
) => {
    if (data) {
        const { rows } = data
        if (rows) {
            data.rows = [...rows]?.map((row) => {
                const locationCell = row[0]
                if (!isStore(currentLocation)) {
                    locationCell.url =
                        pathname + '?locationId=' + (locationCell.value || '')
                }
                return row
            })
        }
    }
    return data
}
