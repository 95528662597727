import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchStoreLeadership } from 'queries/store-facts/store-facts'
import { StoreFactsFetchProps } from 'types/api'
import { IResponseStoreFacts } from 'utils/api'
import { isStore } from 'utils/location'
import { TIME } from 'utils/misc'

export function useStoreLeadership({
    env,
    accessToken,
    location,
}: StoreFactsFetchProps): IResponseStoreFacts {
    const { tenMinutes } = TIME
    const enabled = isStore(location)
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.STORE_LEADERSHIP, location],
        () =>
            fetchStoreLeadership({
                env,
                accessToken,
                body: {
                    filters: [
                        {
                            type: 'in',
                            pattern: location,
                            dimension: 'store_t_number',
                        },
                    ],
                },
                filters: {
                    card_id: '1320970',
                },
                method: 'POST',
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            enabled,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
