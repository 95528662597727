export const TRANSLATION_KEYS = {
    alerts: 'alerts',
    alert: {
        heading: 'alert.heading',
        generic: 'alert.generic',
        expiredSession: 'alert.expiredSession',
        retry: 'alert.retry',
    },
    assetsProtection: {
        title: 'assetsProtection.title',
        tabs: {
            payroll: 'assetsProtection.tabs.payroll',
            theft: 'assetsProtection.tabs.theft',
            secure: 'assetsProtection.tabs.secure',
        },
        secure: {
            title: 'assetsProtection.secure.title',
        },
    },
    indexRedirectMessage: 'indexRedirectMessage',
    locationSearch: {
        header: 'locationSearch.header',
        searchLabel: 'locationSearch.searchLabel',
        matchNotFound: 'locationSearch.matchNotFound',
        nearbyLocationsHeader: 'locationSearch.nearbyLocationsHeader',
    },
    loyalty: {
        title: 'loyalty.title',
        tabs: {
            overview: 'loyalty.tabs.overview',
            targetCircle: 'loyalty.tabs.targetCircle',
            targetCircleCard: 'loyalty.tabs.targetCircleCard',
        },
        timePeriods: {
            currentWeek: 'loyalty.timePeriods.currentWeek',
            lastWeek: 'loyalty.timePeriods.lastWeek',
            monthToDay: 'loyalty.timePeriods.monthToDay',
            yearToDay: 'loyalty.timePeriods.yearToDay',
        },
        overview: {
            currentWeek: 'loyalty.overview.currentWeek',
            lastWeek: 'loyalty.overview.lastWeek',
            percentTranslationsTargetCircle:
                'loyalty.overview.percentTranslationsTargetCircle',
            percentTranslationsTargetCircleCard:
                'loyalty.overview.percentTranslationsTargetCircleCard',
            Sun: 'loyalty.overview.Sun',
            Mon: 'loyalty.overview.Mon',
            Tue: 'loyalty.overview.Tue',
            Wed: 'loyalty.overview.Wed',
            Thu: 'loyalty.overview.Thu',
            Fri: 'loyalty.overview.Fri',
            Sat: 'loyalty.overview.Sat',
            location: 'loyalty.overview.location',
            total: 'loyalty.overview.total',
        },
        circleCard: {
            applications: 'loyalty.circleCard.applications',
            applicationNumbers: 'loyalty.circleCard.applicationNumbers',
        },
    },
    payroll: {
        title: 'payroll.title',
        tabs: {
            location: 'payroll.tabs.location',
            workgroup: 'payroll.tabs.workgroup',
            overtime: 'payroll.tabs.overtime',
            monthly: 'payroll.tabs.monthly',
        },
        location: {
            title: 'payroll.location.title',
            payrollByLocation: 'payroll.location.payrollByLocation',
            payrollByWeek: 'payroll.location.payrollByWeek',
            projFlexHours: 'payroll.location.projFlexHours',
            guranteedFlexHours: 'payroll.location.guranteedFlexHours',
            bpFlextoplan: 'payroll.location.bpFlextoplan',
        },
        workgroup: {
            title: 'payroll.workgroup.title',
        },
        overtime: {
            title: 'payroll.overtime.title',
        },
    },
    itemSearch: {
        searchLabel: 'itemSearch.searchLabel',
        searchButtonText: 'itemSearch.searchButtonText',
        searchInputPlaceholder: 'itemSearch.searchInputPlaceholder',
        placementCapacityDescription: 'itemSearch.placementCapacityDescription',
        onHandRow: 'itemSearch.onHandRow',
        onWithdrawalRow: 'itemSearch.onWithdrawalRow',
        lastAuditedRow: 'itemSearch.lastAuditedRow',
        lastReceivedRow: 'itemSearch.lastReceivedRow',
        lastSoldRow: 'itemSearch.lastSoldRow',
        totalSalesCardTitle: 'itemSearch.totalSalesCardTitle',
        averageSalesCardTitle: 'itemSearch.averageSalesCardTitle',
        location: 'itemSearch.location',
        company: 'itemSearch.company',
        district: 'itemSearch.district',
        store: 'itemSearch.store',
        unitsSold: 'itemSearch.unitsSold',
        sales: 'itemSearch.sales',
        itemNotFound: 'itemSearch.itemNotFound',
        quantityCheck: 'itemSearch.quantityCheck',
        tabs: {
            actual: 'itemSearch.tabs.actual',
            forecast: 'itemSearch.tabs.forecast',
        },
    },
    safety: {
        title: 'safety.title',
        lagging: {
            title: 'safety.lagging.title',
        },
        total: {
            title: 'safety.total.title',
        },
        leading: {
            title: 'safety.leading.title',
        },
        tabs: {
            lagging: 'safety.tabs.lagging',
            total: 'safety.tabs.total',
            leading: 'safety.tabs.leading',
        },
        nonStoreLabel: 'safety.nonStoreLabel',
        store: {
            'Lagging Indicators': 'safety.store.Lagging Indicators',
            'Total Incidents': 'safety.store.Total Incidents',
            'Leading Indicators': 'safety.store.Leading Indicators',
        },
    },
    sales: {
        title: 'sales.title',
        tabs: {
            overview: 'sales.tabs.overview',
            details: 'sales.tabs.details',
            location: 'sales.tabs.location',
        },
        overview: {
            hourlySales: 'sales.overview.hourlySales',
            originated: 'sales.overview.originated',
            lineBusting: 'sales.overview.lineBusting',
            fulfilled: 'sales.overview.fulfilled',
            shipFromStore: 'sales.overview.shipFromStore',
            driveUp: 'sales.overview.driveUp',
            orderPickup: 'sales.overview.orderPickup',
            shipt: 'sales.overview.shipt',
            otherSales: 'sales.overview.otherSales',
            saveTheSale: 'sales.overview.saveTheSale',
            goal: 'sales.overview.goal',
            lastYear: 'sales.overview.lastYear',
            compPercent: 'sales.overview.compPercent',
            originatedCompPercent: 'sales.overview.originatedCompPercent',
            fulfilledCompPercent: 'sales.overview.fulfilledCompPercent',
            basketSize: 'sales.overview.basketSize',
            transactions: 'sales.overview.transactions',
            total: 'sales.overview.total',
            ly: 'sales.overview.ly',
            bpToLy: 'sales.overview.bpToLy',
            bpToLyPercent: 'sales.overview.bpToLyPercent',
            goalPercentage: 'sales.overview.goalPercentage',
            of: 'sales.overview.of',
        },
        details: {
            workarea: {
                title: 'sales.details.workarea.title',
                tabs: {
                    sales: 'sales.details.workarea.tabs.sales',
                    salesMix: 'sales.details.workarea.tabs.salesMix',
                },
            },
            merch: {
                title: 'sales.details.merch.title',
                tabs: {
                    sales: 'sales.details.merch.tabs.sales',
                    salesMix: 'sales.details.merch.tabs.salesMix',
                },
            },
        },
        location: {
            title: 'sales.location.title',
            tabs: {
                sales: 'sales.location.tabs.sales',
                forecast: 'sales.location.tabs.forecast',
                compPercent: 'sales.location.tabs.compPercent',
            },
        },
    },
    shortage: {
        title: 'shortage.title',
        totalShortage: 'shortage.totalShortage',
        lastInvetoryDate: 'shortage.lastInvetoryDate',
        nextScheduledInventoryDate: 'shortage.nextScheduledInventoryDate',
        'last inventory date': 'shortage.last inventory date',
        'next scheduled inventory date':
            'shortage.next scheduled inventory date',
        thisYear: {
            title: 'shortage.thisYear.title',
        },
        merch: {
            title: 'shortage.merch.title',
        },
        top10: {
            title: 'shortage.top10.title',
        },
    },
    storeFacts: {
        title: 'storeFacts.title',
        quickFacts: 'storeFacts.quickFacts',
        lastYearSales: 'storeFacts.lastYearSales',
        originatedSales: 'storeFacts.originatedSales',
        fulfilledSales: 'storeFacts.fulfilledSales',
        totalSales: 'storeFacts.totalSales',
        hours: 'storeFacts.hours',
        keyDates: 'storeFacts.keyDates',
        opened: 'storeFacts.opened',
        lastRemodel: 'storeFacts.lastRemodel',
        packStation: 'storeFacts.packStation',
        leadership: 'storeFacts.leadership',
        store: 'storeFacts.store',
        role: 'storeFacts.role',
        name: 'storeFacts.name',
        since: 'storeFacts.since',
        capabilities: 'storeFacts.capabilities',
    },
    notes: {
        notes: 'notes.notes',
        sales: 'notes.sales',
        of: 'notes.of',
        payroll: 'notes.payroll',
        hours: 'notes.hours',
        flexPlan: 'notes.flexPlan',
        weather: 'notes.weather',
        keyStatistics: 'notes.keyStatistics',
        pickOnTime: 'notes.pickOnTime',
        sfsInf: 'notes.sfsInf',
        opuInf: 'notes.opuInf',
        high: 'notes.high',
        low: 'notes.low',
        errorText: 'notes.errorText',
        items: {
            addButton: 'notes.items.addButton',
            todo: {
                title: 'notes.items.todo.title',
                label: 'notes.items.todo.label',
                key: 'notes.items.todo.key',
            },
            attendance: {
                title: 'notes.items.attendance.title',
                label: 'notes.items.attendance.label',
                key: 'notes.items.attendance.key',
            },
            notes: {
                title: 'notes.items.notes.title',
                label: 'notes.items.notes.label',
                key: 'notes.items.notes.key',
            },
        },
        legacy: {
            title: 'notes.legacy.title',
            subtitle: 'notes.legacy.subtitle',
        },
    },
    operationalMetrics: {
        title: 'operationalMetrics.title',
        recall: {
            tabs: {
                operations: 'operationalMetrics.recall.tabs.operations',
                recall: 'operationalMetrics.recall.tabs.recall',
            },
            headings: {
                location: 'operationalMetrics.recall.headings.location',
                irNumber: 'operationalMetrics.recall.headings.irNumber',
                status: 'operationalMetrics.recall.headings.status',
            },
        },
    },
    trailer: {
        title: 'trailer.title',
        tabs: {
            summary: 'trailer.tabs.summary',
            schedule: 'trailer.tabs.schedule',
        },
        summary: {
            unacknowledgedDcTitle: 'trailer.summary.unacknowledgedDcTitle',
            unacknowledgedStoreTitle:
                'trailer.summary.unacknowledgedStoreTitle',
            unacknowledgedTransitTitle:
                'trailer.summary.unacknowledgedTransitTitle',
            unacknowledgedNoTrackingTitle:
                'trailer.summary.unacknowledgedNoTrackingTitle',
            acknowledgedTitle: 'trailer.summary.acknowledgedTitle',
        },
        schedule: {
            title: 'trailer.schedule.title',
            timePeriods: {
                currentWeek: 'trailer.schedule.timePeriods.currentWeek',
                lastWeek: 'trailer.schedule.timePeriods.lastWeek',
                nextWeek: 'trailer.schedule.timePeriods.nextWeek',
                threeWeeksOut: 'trailer.schedule.timePeriods.threeWeeksOut',
            },
            headers: {
                unloadDateTime: 'trailer.schedule.headers.unloadDateTime',
                sequence: 'trailer.schedule.headers.sequence',
                type: 'trailer.schedule.headers.type',
                sweep: 'trailer.schedule.headers.sweep',
            },
        },
        rgd: {
            location: 'trailer.rgd.location',
            unacknowledged: 'trailer.rgd.unacknowledged',
            red: 'trailer.rgd.red',
            recentlyAcknowledged: 'trailer.rgd.recentlyAcknowledged',
            today: 'trailer.rgd.today',
        },
        details: {
            zeroHours: 'trailer.details.zeroHours',
            billOfLading: 'trailer.details.billOfLading',
            lastApplication: 'trailer.details.lastApplication',
            table: {
                type: 'trailer.details.table.type',
                closed: 'trailer.details.table.closed',
                trailerNumber: 'trailer.details.table.trailerNumber',
                cartons: 'trailer.details.table.cartons',
                leadTime: 'trailer.details.table.leadTime',
                laborHours: 'trailer.details.table.laborHours',
                stockingTime: 'trailer.details.table.stockingTime',
                secondarySortingTime:
                    'trailer.details.table.secondarySortingTime',
                customBlock: 'trailer.details.table.customBlock',
                caseCartons: 'trailer.details.table.caseCartons',
                repackCartons: 'trailer.details.table.repackCartons',
                sortingTime: 'trailer.details.table.sortingTime',
                total: 'trailer.details.table.total',
            },
            workArea: {
                workArea: 'trailer.details.workArea.workArea',
                caseCartons: 'trailer.details.workArea.caseCartons',
                caseUnit: 'trailer.details.workArea.caseUnit',
                repackCartons: 'trailer.details.workArea.repackCartons',
                repackUnits: 'trailer.details.workArea.repackUnits',
                total: 'trailer.details.workArea.total',
            },
        },
    },
    settings: {
        title: 'settings.title',
        info: 'settings.info',
        you: 'settings.you',
        location: 'settings.location',
        version: 'settings.version',
        help: 'settings.help',
        feedback: 'settings.feedback',
        language: 'settings.language',
        languages: {
            en: 'settings.languages.en',
            es: 'settings.languages.es',
            EN: 'settings.languages.EN',
            ES: 'settings.languages.ES',
        },
        creatingPreferences: 'settings.creatingPreferences',
        updatingPreferences: 'settings.updatingPreferences',
        looksLikeSomethingWentWrong: 'settings.looksLikeSomethingWentWrong',
        loading: 'settings.loading',
    },
    unauthorized: {
        title: 'unauthorized.title',
        message: 'unauthorized.message',
    },
    contact: {
        mainPhone: 'contact.mainPhone',
        fax: 'contact.fax',
    },
    location: {
        region: 'location.region',
        group: 'location.group',
        district: 'location.district',
        RDC: 'location.RDC',
        county: 'location.county',
    },
    timePeriods: {
        today: 'timePeriods.today',
        yesterday: 'timePeriods.yesterday',
        weekToDate: 'timePeriods.weekToDate',
        lastWeek: 'timePeriods.lastWeek',
        monthToDate: 'timePeriods.monthToDate',
        yearToDate: 'timePeriods.yearToDate',
    },
    filters: {
        compPercent: 'filters.compPercent',
        viewInPercent: 'filters.viewInPercent',
        viewInDollars: 'filters.viewInDollars',
    },
    'weekly hours': 'weekly hours',
    'prior month': 'prior month',
    'Actual Hrs': 'Actual Hrs',
    'Plan Hrs': 'Plan Hrs',
    'Published Hrs': 'Published Hrs',
    'b/(p) to Plan': 'b/(p) to Plan',
    'b/(p) % to Plan': 'b/(p) % to Plan',
    'b/(p) % to Published': 'b/(p) % to Published',
    'Eastern Standard Time': 'Eastern Standard Time',
    'Central Standard Time': 'Central Standard Time',
    'Mountain Standard Time': 'Mountain Standard Time',
    'Pacific Standard Time': 'Pacific Standard Time',
    EST: 'EST',
    CST: 'CST',
    MST: 'MST',
    PST: 'PST',
    'Adult Beverage Pickup': 'Adult Beverage Pickup',
    'Wine & Beer Available': 'Wine & Beer Available',
    'Drive Up': 'Drive Up',
    'Starbucks Drive Up': 'Starbucks Drive Up',
    'Vandal Resistant Glass': 'Vandal Resistant Glass',
    'Fresh Order Pick Up': 'Fresh Order Pick Up',
    'Self Checkout': 'Self Checkout',
    'Accepts WIC': 'Accepts WIC',
    'Protects brands advocates in court safer shopping':
        'Protects brands advocates in court safer shopping',
    'Shipt Delivery': 'Shipt Delivery',
    'Consumer Cellular Cell Phone Activations':
        'Consumer Cellular Cell Phone Activations',
    'Fresh Grocery': 'Fresh Grocery',
    'AT&T Cell Phone Activations': 'AT&T Cell Phone Activations',
    'Off duty police or 3rd party security':
        'Off duty police or 3rd party security',
    'Prepaid Cell Phone Activations': 'Prepaid Cell Phone Activations',
    'Store Pickup': 'Store Pickup',
    'Mobile Operations': 'Mobile Operations',
    'Drive Up Returns': 'Drive Up Returns',
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
}
