import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { TrailerScheduleFetchProps } from 'types/api'
import { useAnalytics } from '@praxis/component-analytics'
import { TIME } from 'utils/misc'
import { fetchTrailerSchedule } from 'queries/trailer/trailer-schedule'

export function useTrailerSchedule({
    env,
    accessToken,
    location,
    filters,
}: TrailerScheduleFetchProps): any {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.TRAILER_SCHEDULE, location],
        () =>
            fetchTrailerSchedule({
                env,
                accessToken,
                trackEvent,
                filters,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
