export const Constants = {
    SECONDARY_SORTING: 'SECONDARY_SORTING',
    STOCKING: 'STOCKING',
    TRAILER: 'TRAILER',
    ZERO_HOURS: '0 hrs',
    TABLE_KEYS: {
        CUSTOM_BLOCK: 'customblock',
        CASE_CARTONS: 'casecartons',
        REPACK_CARTONS: 'repackcartons',
        SORTING_TIME: 'sortingtime',
        STOCKING_TIME: 'stockingtime',
    },
}
