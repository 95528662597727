import EnterpriseIcon, { ProcessingIcon } from '@enterprise-ui/icons'
import { InteractiveButton } from 'components/button'
import { IWorkAreaToggleProps } from 'pages/trailer/store-trailer-details/ITrailer'
import { Link } from 'react-router-dom'
import { P, match } from 'ts-pattern'
import { Link as LinkType, formatNegative, getNegativeStyle } from 'utils/data'

interface IBaseCell {
    value: string
    color?: string
    className?: string
    id?: string
}

interface ILinkCell extends IBaseCell {
    url: string
}

interface IButtonCell extends IBaseCell {
    link: LinkType
    is_link?: boolean
}

type Cell = IBaseCell | ILinkCell | IButtonCell

export type HandleDataClick = {
    (data: IWorkAreaToggleProps): void
}

type RenderCellProps = {
    cell: Cell
    handleDataClick?: HandleDataClick
    handleClick?: (value: string) => void
    selectedCell: string
    isFooter?: boolean
    isLoading?: boolean
}

export const renderCell = ({
    cell,
    handleClick,
    handleDataClick,
    selectedCell = '',
    isFooter = false,
    isLoading = false,
}: RenderCellProps) => {
    const color = cell.color || ''
    const className = cell.className || ''
    const formattedValue = formatNegative(cell.value)
    const formattedStyle = getNegativeStyle(cell.value)
    const cellToMatch = { ...cell, isFooter }
    const result = match(cellToMatch)
        .with({ url: P.select() }, (url) => (
            <InteractiveButton style={formattedStyle}>
                <Link to={url}>{formattedValue}</Link>
            </InteractiveButton>
        ))
        .with(P.union({ link: P.nonNullable }, { is_link: true }), () => {
            const onClickFn = () => {
                if (handleDataClick && cell?.id) {
                    handleDataClick({
                        value: formattedValue ?? '',
                        id: cell.id,
                    })
                } else if (handleClick) {
                    handleClick(formattedValue || '')
                }
            }
            return (
                <InteractiveButton style={formattedStyle} onClick={onClickFn}>
                    {formattedValue}{' '}
                    {selectedCell === formattedValue && isLoading && (
                        <EnterpriseIcon
                            icon={ProcessingIcon}
                            className="toggle-loading-animation"
                            data-testid="processing-icon"
                        />
                    )}
                </InteractiveButton>
            )
        })
        .with({ isFooter: true }, () => (
            <strong className={formattedStyle}>{formattedValue}</strong>
        ))
        .otherwise(() => (
            <span
                style={{ color }}
                className={`${formattedStyle}${className.length > 0 ? ' ' + className : ''}`}
            >
                {formattedValue}
            </span>
        ))

    return result
}
