import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { Layout } from 'components/layout'
import { clearLocationParam } from 'utils/helpers'
import { PayrollStore } from './payroll/payroll'
import { isStore } from 'utils/location'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'
import { SecureStore } from './secure'
import { Theft } from './theft'

export const AssetsProtectionPage = () => {
    const { params, setParams, currentLocation } = useEssentials()

    window.onpopstate = (e) => {
        clearLocationParam({ params, setParams })
    }

    return <AssetsProtection key={currentLocation} />
}

const AssetsProtection = () => {
    const { t, currentLocation } = useEssentials()
    const { assetsProtection } = TRANSLATION_KEYS
    const { title, tabs: tabsKeys } = assetsProtection
    const tabs = [t(tabsKeys.payroll), t(tabsKeys.theft), t(tabsKeys.secure)]
    const [activeTab, setActiveTab] = useState<string>(tabs[0])

    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)

    return (
        <Layout heading={t(title)} className="hc-pa-none">
            <Tabs
                activeTab={activeTab}
                className="tabs hc-pv-dense"
                justified
                disableAdaptive={true}
                onTabSelect={handleTabSelect}
            >
                {tabs.map((tab: string) => (
                    <Tabs.Item className="hc-bg-tabsItem" key={tab} name={tab}>
                        {tab}
                    </Tabs.Item>
                ))}
                <Tabs.Content className="tabsContent" name={tabs[0]}>
                    {isStore(currentLocation) ? (
                        <PayrollStore />
                    ) : (
                        <h1>{tabs[0]}</h1>
                    )}
                </Tabs.Content>
                <Tabs.Content className="tabsContent" name={tabs[1]}>
                    <Theft />
                </Tabs.Content>
                <Tabs.Content className="tabsContent" name={tabs[2]}>
                    {isStore(currentLocation) ? (
                        <SecureStore />
                    ) : (
                        <h1>{tabs[2]}</h1>
                    )}
                </Tabs.Content>
            </Tabs>
        </Layout>
    )
}
