import { useCallback } from 'react'
import { Button, Card, Form, Input } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { TrashIcon } from '@enterprise-ui/icons'
import { useState } from 'react'
import { NoteItem } from './note-types'
import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { Constants } from 'constants/constants'

export type NotesItemsProps = {
    title: string
    type: string
    addLabel: string
    notes: NoteItem[]
    setNotes: (note: NoteItem[]) => void
    handleAdd: (body: NoteItem) => void
    handleUpdate: (body: any) => void
    formattedDate: string
    isInProgress: boolean
    isCheckboxEnabled: boolean
}

export const NotesItems = ({
    title,
    type,
    addLabel,
    notes,
    setNotes,
    handleAdd,
    handleUpdate,
    formattedDate,
    isInProgress,
    isCheckboxEnabled,
}: NotesItemsProps) => {
    const { notes: notesKeys } = TRANSLATION_KEYS
    const { items: itemsKeys } = notesKeys
    const { t, currentLocation, session, greenfieldGroups } = useEssentials()
    const hasNotesWritePermission =
        greenfieldGroups?.some((group) =>
            [
                Constants.GREENFIELD_GROUPS.NOTES_WRITE,
                Constants.GREENFIELD_GROUPS.ADMIN_PROD,
                Constants.GREENFIELD_GROUPS.ALPHA_TESTERS,
            ].includes(group),
        ) ?? false

    const currentItems = notes.filter((item) => item.type === type)
    const [inputValue, setInputValue] = useState('')
    const [items] = useState<NoteItem[]>(currentItems)

    const handleClick = () => {
        const newItem: NoteItem = {
            type: title,
            completed: false,
            deleted: false,
            created_timestamp: new Date().toISOString(),
            last_updated_timestamp: new Date().toISOString(),
            description: inputValue,
        }
        setNotes([...notes, newItem])
        handleAdd({
            store_id: currentLocation.slice(1),
            notes_date: formattedDate,
            type,
            description: inputValue,
            last_updated_user: session?.userInfo?.lanId,
        } as any)
        setInputValue('')
    }

    const updateItem = useCallback(
        (id: number, transform: (item: NoteItem) => NoteItem) => {
            const currentItem = items.find((item) => item.id === id)!
            const updatedItem = transform(currentItem)
            handleUpdate({
                id: updatedItem.id,
                completed: updatedItem.completed,
                deleted: updatedItem.deleted,
                last_updated_user: session?.userInfo?.lanId,
                description: updatedItem.description,
            })
        },
        [items, handleUpdate, session],
    )

    const handleDelete = useCallback(
        (id: number) => {
            updateItem(id, (item) => ({
                ...item,
                id,
                deleted: true,
                last_updated_timestamp: new Date().toISOString(),
            }))
        },
        [updateItem],
    )

    const toggleComplete = useCallback(
        (id: number) => {
            updateItem(id, (item) => ({
                ...item,
                id,
                completed: !item.completed,
                last_updated_timestamp: new Date().toISOString(),
            }))
        },
        [updateItem],
    )

    const activeItems = items.filter((item) => !item.deleted)

    return (
        <>
            <h3 className="hc-mt-lg">{title}</h3>
            {activeItems.map((item, index) => (
                <div className="item" key={index}>
                    {isCheckboxEnabled && hasNotesWritePermission ? (
                        <Input.Checkbox
                            key={`${item.description}-${item.id}`}
                            id={`${title}-${item.id}`}
                            label={item.description}
                            onClick={() => toggleComplete(item.id!)}
                            checked={item.completed}
                            disabled={isInProgress}
                            aria-label={item.description}
                        />
                    ) : (
                        <>{item.description}</>
                    )}
                    {!isInProgress && hasNotesWritePermission && (
                        <EnterpriseIcon
                            icon={TrashIcon}
                            onClick={() => handleDelete(item.id!)}
                            data-testid={`delete-${item.id}`}
                        />
                    )}
                </div>
            ))}

            {hasNotesWritePermission && (
                <Card>
                    <div className={'item-add'}>
                        <Form.Field
                            aria-label={addLabel}
                            label={addLabel}
                            value={inputValue}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => setInputValue(e.target.value)}
                            disabled={isInProgress}
                        />
                        <Button
                            type="primary"
                            onClick={handleClick}
                            disabled={isInProgress}
                        >
                            {t(itemsKeys.addButton)}
                        </Button>
                    </div>
                </Card>
            )}
        </>
    )
}
