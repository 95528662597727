import { useQuery } from '@tanstack/react-query'
import { useAnalytics } from '@praxis/component-analytics'
import { fetchItemDetails } from 'queries/item-details/item-details'
import { TIME } from 'utils/misc'
import { QueryKeys } from 'constants/query-keys'

export function useItemDetails({
    env,
    accessToken,
    location,
    filters,
}: any): any {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const isEnabled =
        filters?.location_id.length > 0 && filters?.item_id.length > 0

    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.ITEM_DETAILS, filters?.item_id, location],
        () =>
            fetchItemDetails({
                env,
                accessToken,
                location,
                filters,
                trackEvent,
            }),
        {
            enabled: isEnabled,
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
