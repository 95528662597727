import { Cell } from 'utils/data'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import type { TFunction } from 'i18next/typescript/t'
import { format, parseISO } from 'date-fns'
import { buildDataTableObject } from 'utils/data'
import { TableData } from 'utils/data'
import { GeneralizedComponentPayloadType } from 'components/generalized-component/generalized-component'
import { t } from 'i18next'

export const getHeaders = (type: 'fdc' | 'rdc') => {
    const { trailer } = TRANSLATION_KEYS
    const trailerHeaders = trailer.schedule.headers
    return [
        { value: t(trailerHeaders.unloadDateTime) },
        { value: t(trailerHeaders.sequence) },
        { value: t(trailerHeaders.type) },
        { value: t(trailerHeaders.sweep) },
        { value: `${type.toUpperCase()} #` },
    ]
}
export const buildTrailerScheduleData = (
    data: any,
): GeneralizedComponentPayloadType => {
    const formatOrdinal = (n: number): string => {
        const s = ['th', 'st', 'nd', 'rd'],
            v = n % 100
        return n + (s[(v - 20) % 10] || s[v] || s[0])
    }
    const columnIndexes = [
        'unload_time',
        'delivery_sequence',
        'unload_type',
        'sweep_eligible',
        'distribution_center_name',
    ]

    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        return format(date, 'EEE M/d h:mma')
    }

    const processData = (entries: any[], type: string): TableData => ({
        headings: getHeaders(type as 'fdc' | 'rdc'),
        rows: entries.flatMap((entry: any) =>
            entry.trailers
                .filter(
                    (trailer: any) =>
                        trailer.distribution_center_name !== 'C&S',
                )
                .map((trailer: any) =>
                    columnIndexes.map((key) => ({
                        value:
                            key === 'sweep_eligible'
                                ? trailer[key]
                                    ? 'yes'
                                    : 'no'
                                : key === 'unload_time'
                                  ? formatDate(trailer[key])
                                  : key === 'delivery_sequence'
                                    ? formatOrdinal(trailer[key])
                                    : trailer[key] !== null &&
                                        trailer[key] !== undefined
                                      ? String(trailer[key])
                                      : '',
                    })),
                ),
        ),
    })

    const timeFrames = [
        'current_week',
        'last_week',
        'next_week',
        'three_weeks_out',
    ]

    const payload: GeneralizedComponentPayloadType = {
        'show RDC': timeFrames.map((timeFrame) => ({
            title: '',
            subtitle: '',
            data: {
                RDC: processData(data[timeFrame].rdc, 'rdc'),
            },
        })),
        'show FDC': timeFrames.map((timeFrame) => ({
            title: '',
            subtitle: '',
            data: {
                FDC: processData(data[timeFrame].fdc, 'fdc'),
            },
        })),
    }

    return payload
}

const {
    trailer: { rgd },
} = TRANSLATION_KEYS

const now = new Date()

export function formatDateTime(t: TFunction): string {
    const formattedDate = format(now, 'MMM d, yyyy h:mm a')
    return `${t(rgd.today)}, ${formattedDate}`
}

export const headings = [
    rgd.location,
    rgd.unacknowledged,
    rgd.red,
    rgd.recentlyAcknowledged,
]

export const formattedHeadings: Cell[] = headings.map((heading) => ({
    value: heading,
}))

export const createRow = (location: any): Cell[] => [
    { value: `${location.location_id}` },
    { value: `${location.unacknowledged_trailers}` },
    { value: `${location.unacknowledged_trailers_red_status}` },
    { value: `${location.acknowledged_trailers}` },
]

type ExtractedShipment = {
    type: string
    trailer_id: string
    move_id: string
    cartons: number
    acknowledged?: string
    closed?: string
    lead_time?: string
}

export const getTrailerSummaryTimestamp = (timestamp: string) => {
    return format(parseISO(timestamp), 'M/d h:mm a')
}

export const extractTrailerSummaryData = (shipments: any[]) => {
    if (!Array.isArray(shipments)) return []

    return shipments.map((shipment) => {
        if (!shipment) return [{}]

        const {
            type,
            acknowledged_timestamp,
            shipment_closed_timestamp,
            trailer_id,
            cartons,
            lead_time,
            move_id,
        } = shipment

        return acknowledged_timestamp
            ? {
                  type,
                  acknowledged: getTrailerSummaryTimestamp(
                      acknowledged_timestamp,
                  ).toLowerCase(),
                  trailer_id,
                  move_id,
                  cartons,
              }
            : {
                  type,
                  closed: format(
                      parseISO(shipment_closed_timestamp),
                      'M/d h:mm a',
                  ).toLowerCase(),
                  trailer_id,
                  move_id,
                  cartons,
                  lead_time,
              }
    })
}

export const transformShipmentsForTable = (
    shipments: any[],
    currentLocation: string,
) => {
    const extractedData = extractTrailerSummaryData(shipments)

    if (!extractedData.length) return null

    const hasAcknowledged = extractedData.some((item) => 'acknowledged' in item)

    const columnMappings: { [key: string]: keyof ExtractedShipment } = {
        type: 'type',
        acknowledged: 'acknowledged',
        closed: 'closed',
        'trailer #': 'trailer_id',
        cartons: 'cartons',
        'lead time': 'lead_time',
    }

    const headings = hasAcknowledged
        ? ['type', 'acknowledged', 'trailer #', 'cartons']
        : ['type', 'closed', 'trailer #', 'cartons', 'lead time']

    const formattedData = {
        headings: headings.map((value) => ({ value })),
        rows: extractedData.map((item) => {
            const typedItem = item as ExtractedShipment
            return headings.map((heading) => {
                const key = columnMappings[heading] as keyof ExtractedShipment
                if (key === 'trailer_id') {
                    return {
                        value: String(typedItem[key] ?? ''),
                        is_link: true,
                        url: `/trailer/detail?locationId=${currentLocation}&moveId=${typedItem['move_id']}`,
                    }
                }
                return { value: String(typedItem[key] ?? '') }
            })
        }),
    }

    return buildDataTableObject({
        currentData: formattedData,
        widths: Array(headings.length).fill(150),
        handleClick: () => {},
        pinFirstColumn: false,
        sortable: false,
        isLoading: false,
        onSort: () => {},
    })
}
