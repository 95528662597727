import { ON_TIME_THRESHOLD } from 'queries/notes/helpers'
import { StoreMetrics } from '../types/storeMetrics'

export const parseStoreMetrics = (data: any): StoreMetrics => {
    const storeMetrics = data?.legacy_notes?.[0]?.store_metrics_overview || {}

    return {
        ...parseSalesMetrics(storeMetrics.sales),
        ...parsePayrollMetrics(storeMetrics.payroll),
        ...parseFlexMetrics(storeMetrics.flex),
        ...parseWeatherMetrics(storeMetrics.weather),
    }
}

export const parseSalesMetrics = (salesData: any = {}) => {
    const salesValue = roundToInt(salesData.sales, 0)
    const forecastValue = roundToInt(salesData.forecast, 1)
    const salesProgress = calculatePercentage(salesValue, forecastValue)

    const compPerc = parseFloat(salesData.compPerc) || 0
    const formattedCompPerc = formatPercentage(compPerc)
    const compPercStyle = compPerc <= 0 ? 'hc-clr-error' : 'hc-clr-success'

    return {
        salesValue,
        forecastValue,
        salesProgress,
        formattedCompPerc,
        compPercStyle,
    }
}

export const parsePayrollMetrics = (payrollData: any = {}) => {
    const payrollUsed = roundToInt(payrollData.total_hours, 0)
    const payrollPlanned = roundToInt(payrollData.planned_hours, 1)
    const payrollProgress = calculatePercentage(payrollUsed, payrollPlanned)

    const flexPlan = payrollData.better_to_poor_plan || 0
    const formattedFlexPlan = formatNumber(flexPlan)
    const flexPlanStyle = flexPlan <= 0 ? 'hc-clr-error' : 'hc-clr-success'

    return {
        payrollUsed,
        payrollPlanned,
        payrollProgress,
        formattedFlexPlan,
        flexPlanStyle,
    }
}

export const parseFlexMetrics = (flexData: any = {}) => {
    const metrics: any = {}

    // SFS Inf
    metrics.sfsInf = flexData.sfs_inf?.inf_percent || 0
    if (flexData.sfs_inf?.color_indicator === '#00a200') {
        metrics.sfsInfColor = 'hc-clr-success'
    } else {
        metrics.sfsInfColor = 'hc-clr-error'
    }
    metrics.sfsStatus = getGoalStatus(flexData.sfs_inf?.color_indicator)

    // OPU Inf
    metrics.opuInf = flexData.opu_inf?.inf_percent || 0
    if (flexData.opu_inf?.color_indicator === '#00a200') {
        metrics.opuInfColor = 'hc-clr-success'
    } else {
        metrics.opuInfColor = 'hc-clr-error'
    }
    metrics.opuStatus = getGoalStatus(flexData.opu_inf?.color_indicator)

    // Pick On Time
    metrics.pickOnTime = flexData.picked_on_time_percent || 0
    if (flexData.picked_on_time_percent < ON_TIME_THRESHOLD) {
        metrics.pickOnTimeColor = 'hc-clr-error'
        metrics.pickOnTimeStatus = 'below goal'
    } else {
        metrics.pickOnTimeColor = 'hc-clr-success'
        metrics.pickOnTimeStatus = 'at goal'
    }

    return metrics
}

export const parseWeatherMetrics = (weatherData: any = {}) => {
    return {
        maxTemp: roundToInt(weatherData.forecast_max_temp, 0),
        minTemp: roundToInt(weatherData.forecast_min_temp, 0),
        weatherDescription: weatherData.forecast_description || '-',
        weatherIconUrl: weatherData.url_forecast_icon || '-',
    }
}

export const roundToInt = (value: any, defaultValue: number): number =>
    Math.round(parseFloat(value) || defaultValue)

export const calculatePercentage = (value: number, total: number): number =>
    total !== 0 ? (value / total) * 100 : 0

export const formatPercentage = (value: number): string =>
    value < 0 ? `(${Math.abs(value)}%)` : `${value}%`

export const formatNumber = (value: number): string =>
    value < 0 ? `(${Math.abs(value)})` : `${value}`

export const getGoalStatus = (colorIndicator: string = '#000'): string =>
    colorIndicator === '#00a200' ? 'at goal' : 'below goal'
