import { useState } from 'react'
import { BasicPlaceholder } from 'components/placeholder'
import { useShortage } from 'hooks/shortage/useShortage'
import { getTableFormat } from 'utils/table'
import { TitledFilteredTable } from 'components/titled-filtered-table/title-filtered-table'
import { addHyperLinkToFirstColumn } from 'pages/payroll/helpers'
import {
    TableData,
    addLinkToFirstColumn,
    insertRows,
    removeLinkFromFirstColumn,
} from 'utils/data'
import { useShortageTopTen } from 'hooks/shortage/useShortageTopTen'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { ShortageCard } from './components/card'
import { getLocationFilters } from 'utils/api'
import useRefresh from 'hooks/utils/useRefresh'
import useCountdown from 'hooks/utils/useCountdown'
import { ErrorAlert } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { translateItem } from 'locales/utils'
import { useEssentials } from 'hooks/utils/useEssentials'

export const ShortageNonStore = () => {
    const { t, currentLocation, pathname, env, logout, session } =
        useEssentials()

    const [parent, setParent] = useState<string>('')

    const filters = getLocationFilters(currentLocation)
    const { data, isLoading, isError, refetch, remove, error } = useShortage({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        filters,
    })
    useRefresh(refetch, remove)
    const count = useCountdown(5, logout, error as Error)

    const {
        data: subData,
        refetch: refetchTopTen,
        remove: removeTopTen,
    } = useShortageTopTen({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        dynamicSubPath: parent,
    })
    useRefresh(refetchTopTen, removeTopTen)

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="table" rows={5} columns={5} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    let derivedData = [...data]

    let [thisYear, topTen] = derivedData

    const { shortage } = TRANSLATION_KEYS
    const { thisYear: thisYearKeys, top10: topTenKeys } = shortage
    let { title: tyTitle, table: tyTable } = thisYear
    let { title: ttTitle, table: ttTable } = topTen

    tyTitle = translateItem({ t, item: tyTitle, translation: thisYearKeys })
    ttTitle = translateItem({ t, item: ttTitle, translation: topTenKeys })

    const handleClick = (parentValue: string) => {
        if (ttTable) {
            const match = ttTable?.rows.filter(
                (row) => row[0].childOf === parentValue,
            )
            if (parent === parentValue || match.length > 0) {
                const filtered = ttTable?.rows.filter(
                    (row) => row[0].childOf !== parentValue,
                )
                ttTable.rows = filtered
                setParent('')
            } else {
                setParent(parentValue)
            }
        }
    }

    const addChildRows = (data: TableData, subData: TableData) => {
        subData.rows = removeLinkFromFirstColumn(subData.rows)
        subData.rows = subData.rows.map((row) => {
            row[0].childOf = parent

            return row
        })
        const insertIndex =
            ttTable.rows.findIndex((row) => row[0].value === parent) + 1
        const newData = { ...ttTable }
        let newDataRows = [...ttTable.rows]
        insertRows(newDataRows, insertIndex, subData.rows)
        newData.rows = newDataRows
        data = newData

        return data
    }

    tyTable = addHyperLinkToFirstColumn(tyTable, currentLocation, pathname)
    ttTable.rows = addLinkToFirstColumn(ttTable.rows)
    if (subData) {
        ttTable = addChildRows(ttTable, subData)
    }

    const thisYearWidths = [
        80,
        ...getTableFormat(tyTable.headings?.length ?? 20, 90),
    ]
    const topTenWidths = [
        150,
        ...getTableFormat(tyTable.headings?.length ?? 20, 90),
    ]

    return (
        <>
            <Grid.Container className="hc-pv-md">
                <TitledFilteredTable
                    title={t(shortage.thisYear.title)}
                    data={tyTable}
                    widths={thisYearWidths}
                    pinFirstColumn={true}
                    key={tyTitle}
                    isLoading={isLoading}
                />
            </Grid.Container>
            <Grid.Container className="hc-pv-md hc-mt-md">
                <Grid.Item xs={12}>
                    <ShortageCard
                        dollars={tyTable.footer?.[3].value}
                        percent={tyTable.footer?.[7].value}
                    />
                </Grid.Item>
            </Grid.Container>
            <Grid.Container className="hc-pv-md hc-mt-md">
                <TitledFilteredTable
                    title={t(shortage.top10.title)}
                    data={ttTable}
                    widths={topTenWidths}
                    handleClick={handleClick}
                    pinFirstColumn={true}
                    key={ttTitle}
                    isLoading={isLoading}
                />
            </Grid.Container>
        </>
    )
}
