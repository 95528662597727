import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollOvertimeData } from 'queries/payroll/overtime/overtime'
import { PayrollFetchProps } from 'types/api'
import { TIME } from 'utils/misc'

export function usePayrollOvertime({
    env,
    accessToken,
    location,
    filters,
}: PayrollFetchProps) {
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.PAYROLL_OVERTIME, location],
        () =>
            fetchPayrollOvertimeData({
                env,
                accessToken,
                filters,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
