import { useEffect, useMemo, useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { Layout } from 'components/layout'
import { UrlParams } from 'enums/url-params'
import { clearLocationParam, isEntitled } from 'utils/helpers'
import { SalesOverview } from './overview/overview'
import { SalesDetails } from './details/details'

import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { useEssentials } from 'hooks/utils/useEssentials'
import { Constants } from 'constants/constants'

import { SalesLocation } from './location/location'
import { isStore } from 'utils/location'

import { TRANSLATION_KEYS } from 'locales/translation-keys'

export const SalesPage = () => {
    const { params, setParams } = useEssentials()
    window.onpopstate = (e) => {
        clearLocationParam({ params, setParams })
    }

    return <Sales />
}

export const Sales = () => {
    const { sales } = TRANSLATION_KEYS
    const { tabs: tabsKeys } = sales
    const { t, params, greenfieldGroups, location } = useEssentials()
    const currentLocation =
        params.get(UrlParams.LOCATION_ID) ?? location.shortName
    const { SALES_LOCATION, ADMIN_PROD } = Constants.GREENFIELD_GROUPS
    const isAuth = isEntitled(greenfieldGroups ?? [], [
        SALES_LOCATION,
        ADMIN_PROD,
    ])

    const commonTabs = useMemo(
        () => [t(tabsKeys.overview), t(tabsKeys.details)],
        [t, tabsKeys],
    )
    const tabs = useMemo(() => {
        return !isStore(currentLocation) && isAuth
            ? [...commonTabs, tabsKeys.location]
            : commonTabs
    }, [commonTabs, currentLocation, isAuth, tabsKeys.location])

    const [activeTab, setActiveTab] = useState<string>(tabs[0])

    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)

    useEffect(() => {
        if (isStore(currentLocation)) {
            setActiveTab(tabs[0])
        }
    }, [currentLocation, tabs])

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(sales.title)}
                />
            )}
        >
            <Layout heading={t(sales.title)} className="hc-pa-none">
                <Tabs
                    activeTab={activeTab}
                    className="tabs hc-pv-dense"
                    justified
                    disableAdaptive={true}
                    onTabSelect={handleTabSelect}
                >
                    {tabs.map((tab: string) => (
                        <Tabs.Item
                            className="hc-bg-tabsItem"
                            key={tab}
                            name={tab}
                        >
                            {t(tab)}
                        </Tabs.Item>
                    ))}
                    <Tabs.Content className="tabsContent" name={tabs[0]}>
                        {activeTab === tabs[0] && <SalesOverview />}
                    </Tabs.Content>
                    <Tabs.Content className="tabsContent" name={tabs[1]}>
                        {activeTab === tabs[1] && <SalesDetails />}
                    </Tabs.Content>
                    <Tabs.Content className="tabsContent" name={tabs[2]}>
                        {activeTab === tabs[2] && <SalesLocation />}
                    </Tabs.Content>
                </Tabs>
            </Layout>
        </ErrorBoundary>
    )
}
