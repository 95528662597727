import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollLocationMonthlyData } from 'queries/payroll/location/location'
import { PayrollFetchProps } from 'types/api'
import { IResponse, apiNotEnabled } from 'utils/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function usePayrollLocationMonthly({
    env,
    accessToken,
    location,
    dynamicSubPath,
    filters,
}: PayrollFetchProps): IResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const enabled = true
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.PAYROLL_LOCATION, location],
        () =>
            fetchPayrollLocationMonthlyData({
                env,
                accessToken,
                location,
                dynamicSubPath,
                filters,
                trackEvent,
            }),
        {
            enabled,
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    if (!enabled) {
        return apiNotEnabled()
    }

    return { data, isLoading, isError, refetch, remove, error }
}
