import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { SalesFetchProps } from 'types/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'
import { fetchStoreTrailers } from 'queries/trailer-temp/fetchStoreTrailers'
import { ITrailerResponse } from 'utils/api'
import { stripInitialTAndLeadingZeroes } from 'utils/item-search'

export function useStoreTrailers({
    env,
    accessToken,
    location,
}: SalesFetchProps): ITrailerResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const simpleLocation = stripInitialTAndLeadingZeroes(location ?? '')
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.TRAILER_SUMMARY, location],
        () =>
            fetchStoreTrailers({
                env,
                accessToken,
                location,
                filters: {
                    store_id: simpleLocation,
                },
                trackEvent,
            }),
        {
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
            retry: false,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
