import { useCallback, useEffect, useState } from 'react'
import { ErrorAlert } from 'components/error-fallback'
import { BasicPlaceholder } from 'components/placeholder'
import { useSalesAreaOfStore } from 'hooks/sales/useSalesAreaOfStore'
import {
    GeneralizedComponent,
    GeneralizedComponentPayloadType,
} from 'components/generalized-component/generalized-component'
import { Container } from 'components/container/Container'
import { Cell, TableData } from 'utils/data'
import { getLocationFilters } from 'utils/api'
import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'

export const SalesAreaOfStore = () => {
    const { t, currentLocation, env, session } = useEssentials()
    const { filters: filtersKeys, sales: salesKeys } = TRANSLATION_KEYS
    const { details } = salesKeys
    const { workarea } = details
    const { tabs } = workarea

    const [drills, setDrills] = useState<string[]>([])
    const filters = getLocationFilters(currentLocation)
    let [dataState, setDataState] = useState<TableData>()
    const [timePeriod, setTimePeriod] = useState<string>('today')

    const handleDrillUpdate = useCallback((newDrills: string[]) => {
        setDrills(newDrills)
    }, [])

    const handleIndexUpdate = useCallback((index: number) => {
        const timePeriods = [
            'today',
            'yesterday',
            'week_to_day',
            'last_week',
            'month_to_day',
            'year_to_day',
        ]
        setTimePeriod(timePeriods[index])
    }, [])

    const { data, isLoading, isError } = useSalesAreaOfStore({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
        dynamicSubPath: drills.join('|'),
        filters,
        timePeriod: timePeriod,
    })

    const pickColumns = (
        data: TableData,
        columnIndexes: number[],
    ): TableData => {
        const newData = { ...data }
        newData.headings = columnIndexes
            .map((index) => data.headings?.[index])
            .filter((heading): heading is Cell => heading !== undefined)
        newData.rows = newData.rows.map((row) =>
            columnIndexes.map((index) => row[index]),
        )
        newData.footer = columnIndexes
            .map((index) => data.footer?.[index])
            .filter((heading): heading is Cell => heading !== undefined)

        return newData
    }

    useEffect(() => {
        if (data) {
            setDataState(data)
        }
    }, [data])
    if (isError) {
        return <ErrorAlert />
    }

    if (!dataState) {
        return (
            <div
                className="placeholder-container"
                data-testid="placeholder-grid"
            >
                <BasicPlaceholder type="table" rows={5} />
            </div>
        )
    }

    dataState = dataState!
    const sales = t(tabs.sales)
    const salesMix = t(tabs.salesMix)
    const viewInDollars = t(filtersKeys.viewInDollars)
    const viewInPercent = t(filtersKeys.viewInPercent)
    const generalizedComponentData: GeneralizedComponentPayloadType = {
        [t(viewInDollars)]: [
            {
                title: 'today',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 1, 2, 3]),
                },
            },
            {
                title: 'yesterday',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 1, 2, 3]),
                    [salesMix]: pickColumns(dataState, [0, 1, 7]),
                },
            },
            {
                title: 'week to date',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 1, 2, 3]),
                    [salesMix]: pickColumns(dataState, [0, 1, 7]),
                },
            },
            {
                title: 'last week',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 1, 2, 3]),
                    [salesMix]: pickColumns(dataState, [0, 1, 7]),
                },
            },
            {
                title: 'month to date',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 1, 2, 3]),
                    [salesMix]: pickColumns(dataState, [0, 1, 7]),
                },
            },
            {
                title: 'year to date',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 1, 2, 3]),
                    [salesMix]: pickColumns(dataState, [0, 1, 7]),
                },
            },
        ],
        [t(viewInPercent)]: [
            {
                title: 'today',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 4, 5, 6]),
                },
            },
            {
                title: 'yesterday',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 4, 5, 6]),
                    [salesMix]: pickColumns(dataState, [0, 4, 7]),
                },
            },
            {
                title: 'week to date',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 4, 5, 6]),
                    [salesMix]: pickColumns(dataState, [0, 4, 7]),
                },
            },
            {
                title: 'last week',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 4, 5, 6]),
                    [salesMix]: pickColumns(dataState, [0, 4, 7]),
                },
            },
            {
                title: 'month to date',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 4, 5, 6]),
                    [salesMix]: pickColumns(dataState, [0, 4, 7]),
                },
            },
            {
                title: 'year to date',
                subtitle: '',
                data: {
                    [sales]: pickColumns(dataState, [0, 4, 5, 6]),
                    [salesMix]: pickColumns(dataState, [0, 4, 7]),
                },
            },
        ],
    }

    return (
        <Container className="sales-by-merchandise hc-pv-md" role="main">
            <GeneralizedComponent
                payload={generalizedComponentData}
                title={t(workarea.title)}
                onDrillUpdate={handleDrillUpdate}
                onIndexUpdate={handleIndexUpdate}
                isLoading={isLoading}
                loadingComponent={
                    <div
                        className="placeholder-container"
                        data-testid="placeholder-grid"
                    >
                        <BasicPlaceholder type="table" rows={5} />
                    </div>
                }
            ></GeneralizedComponent>
        </Container>
    )
}
