import { useEffect } from 'react'
import {
    Outlet,
    RouteObject,
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import '@enterprise-ui/canvas-ui-css'
import { AnalyticsProvider } from '@praxis/component-analytics'

import Authenticate from 'pages/authenticate'
import { SalesByLocationRoute, SalesRoute } from 'pages/sales'
import { HomePage } from 'pages'
import { SettingsRoute } from 'pages/settings'
import { LocationSearchRoute } from 'pages/location-search'
import { checkRoutePermissions } from 'utils/authentication'
import { Unauthorized } from 'pages/unauthorized'
import { useUser } from 'components/user-context'
import { PayrollRoute } from 'pages/payroll'
import { ShortageRoute } from 'pages/shortage'
import { SafetyRoute } from 'pages/safety/routes'
import { SalesHourlyRoute } from 'pages/sales/routes'
import { StoreFactsRoute } from 'pages/store-facts'
import { NotesRoute } from 'pages/notes'
import { AssetsProtectionRoute } from 'pages/assets-protection/routes'
import { ItemSearchRoute } from 'pages/item-search/routes'
import { LoginPlaceholder } from 'components/login-placeholder/loginPlaceholder'
import { useEssentials } from 'hooks/utils/useEssentials'
import { UserDetails } from 'App'
import { AlertsRoute } from 'pages/alerts/routes'
import { LoyaltyRoute } from 'pages/loyalty'
import { TrailerRoute } from 'pages/trailer'
import { TrailerDetailRoute } from 'pages/trailer/routes'
import { OperationsRoute } from 'pages/operations'

const HomeRoute: RouteObject = {
    path: '/',
    index: true,
    element: <HomePage />,
}

/**
 * Ensures that the current user has the correct permissions (AD Groups) to
 * access a given route. If not, user will be shown the <Unauthorized /> page.
 *
 * The route to AD Group access mappings are configured in the public/config.json file.
 */
function AuthorizedRoutes() {
    let {
        env,
        pathname: path,
        greenfieldGroups: userPermissions,
    } = useEssentials()
    const { permissionsByKPI } = env

    if (path.lastIndexOf('/') > 0) {
        path = path.slice(0, path.lastIndexOf('/'))
    }

    const isAuthorized = checkRoutePermissions({
        path,
        userPermissions,
        permissionsByKPI,
    })

    // userPermissions is null when not yet loaded
    if (isAuthorized === false && userPermissions !== null) {
        return <Unauthorized />
    }
    if (isAuthorized) {
        return <Outlet />
    }
    return <LoginPlaceholder />
}

const AuthenticateRoute: RouteObject = {
    path: '/authenticate',
    element: <Authenticate />,
}

/**
 * The root route. Provides analytics and then loads the current route.
 * Must be called from within a router context.
 */
const Root = () => {
    const { env } = useEssentials()

    const eventManagerConfiguration = {
        appName: env.firefly.appName,
        apiKey: env.apiKey,
        url: env.firefly.url,
    }

    return (
        <AnalyticsProvider
            eventManagerConfiguration={eventManagerConfiguration}
            trackLocations
        >
            <UserDetails>
                <AuthorizedRoutes />
            </UserDetails>
        </AnalyticsProvider>
    )
}

/**
 * Whenever new route objects are created, they must be added as children here
 * or children of one of the routes given here.
 */
export const RootRoute: RouteObject = {
    path: '/',
    element: <Root />,
    children: [
        HomeRoute,
        SalesRoute,
        LocationSearchRoute,
        AuthenticateRoute,
        PayrollRoute,
        SalesRoute,
        SalesByLocationRoute,
        SalesHourlyRoute,
        SettingsRoute,
        ShortageRoute,
        SafetyRoute,
        StoreFactsRoute,
        NotesRoute,
        AssetsProtectionRoute,
        ItemSearchRoute,
        AlertsRoute,
        LoyaltyRoute,
        NotesRoute,
        TrailerRoute,
        TrailerDetailRoute,
        OperationsRoute,
    ],
}

const router = createBrowserRouter([RootRoute])

/**
 * Ensures login happens, if necessary. Then sets up the routing.
 */
const Router = () => {
    const { isAuthenticated, login } = useAuth()

    if (!isAuthenticated()) {
        login()
    }

    const { setLocation } = useUser()

    useEffect(() => {
        setLocation({
            locationId: 'Target',
            shortName: 'Company',
            name: 'Target',
        })
    }, [setLocation])

    return <RouterProvider router={router} />
}

export default Router
