import { Layout } from 'components/layout'
import { isStore } from 'utils/location'
import { ShortageStore } from './shortage-store'
import { ShortageNonStore } from './shortage-nonstore'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'

export const ShortagePage = () => {
    const { currentLocation } = useEssentials()

    return <Shortage key={currentLocation} />
}

const Shortage = () => {
    const { t, currentLocation } = useEssentials()
    const isStoreFlag = isStore(currentLocation)

    const { shortage } = TRANSLATION_KEYS
    const { title } = shortage

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(title)}
                />
            )}
        >
            <Layout heading={t(title)}>
                <div className="pwa shortage">
                    {isStoreFlag ? (
                        <ShortageStore key={currentLocation} />
                    ) : (
                        <ShortageNonStore key={currentLocation} />
                    )}
                </div>
            </Layout>
        </ErrorBoundary>
    )
}
