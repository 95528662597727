import { useCallback, useRef } from 'react'

interface UseSwipeToIndexProps {
    timePeriods: string[]
    setTimePeriodIndex: React.Dispatch<React.SetStateAction<number>>
}

export const useSwipeToIndex = ({
    timePeriods,
    setTimePeriodIndex,
}: UseSwipeToIndexProps) => {
    const delta = 50
    let touchStartX = useRef(0)
    let touchEndX = useRef(0)

    const isActionable = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement
        if (target.closest('.C-TableAdvanced')) {
            return false
        }

        return true
    }, [])

    const handleTouchStart = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            if (isActionable(e)) {
                touchStartX.current = e.touches[0].clientX
                touchEndX.current = 0
            }
        },
        [isActionable],
    )

    const handleTouchMove = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            touchEndX.current = e.touches[0].clientX
        },
        [],
    )

    const handleTouchEnd = useCallback(
        (e: React.TouchEvent<HTMLDivElement>) => {
            if (touchEndX.current === 0) return

            if (isActionable(e)) {
                const isSwipedLeft =
                    touchEndX.current < touchStartX.current - delta
                const isSwipedRight =
                    touchEndX.current > touchStartX.current + delta
                if (isSwipedLeft) {
                    setTimePeriodIndex(
                        (prevIndex) => (prevIndex + 1) % timePeriods.length,
                    )
                } else if (isSwipedRight) {
                    setTimePeriodIndex(
                        (prevIndex) =>
                            (prevIndex - 1 + timePeriods.length) %
                            timePeriods.length,
                    )
                }
            }
        },
        [timePeriods.length, setTimePeriodIndex, isActionable],
    )

    return {
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
    }
}
