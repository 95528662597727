export const getYDomainForForecast = (
    data: Array<Record<string, string>>,
): [number, number] => {
    const values = data.map((item) => +item['this store'])

    const minValue = Math.min(...values)
    const maxValue = Math.max(...values)
    const margin = (maxValue - minValue) * 0.1 || 1

    return [Math.floor(minValue - margin), Math.ceil(maxValue + margin)]
}
