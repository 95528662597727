import { createContext, useContext, useState } from 'react'
import { IPreferences } from 'types/user-context'
import { IResult, UAParser } from 'ua-parser-js'

export interface Location {
    locationId: string
    shortName: string
    name: string
}

export interface StoreLocation {
    name: string
    short_name: string
    id: number
}

export interface LocationMapping {
    location_id: number
    location: string
    location_name: string
    entitled_for_non_store: boolean
}

export interface IUserContext {
    location: Location
    setLocation: (location: Location) => void
    greenfieldGroups: string[] | null
    setGreenfieldGroups: (greenfieldGroups: string[]) => void
    preferences: IPreferences
    setPreferences: (preferences: IPreferences) => void
    userAgent: IResult
    setUserAgent: (userAgent: IResult) => void
    storeLocationDetails: StoreLocation[]
    setStoreLocationDetails: (storeLocationDetails: StoreLocation[]) => void
    locationMapping: LocationMapping
    setLocationMapping: (locationMapping: LocationMapping) => void
}

export const UserContext = createContext<IUserContext | undefined>(undefined)

interface IUserProviderProps {
    location?: string
    children: JSX.Element | JSX.Element[] | string
}

export const UserProvider = ({ children }: IUserProviderProps) => {
    const [location, setLocation] = useState<Location>({
        locationId: '',
        shortName: '',
        name: '',
    })

    const [greenfieldGroups, setGreenfieldGroups] = useState<string[]>([])
    const [preferences, setPreferences] = useState<IPreferences>({
        app_id: '',
        count: 0,
        page: 0,
        per_page: 0,
        user_id: '',
        user_contexts: [],
    })

    const [userAgent, setUserAgent] = useState<IResult>(
        new UAParser().getResult(),
    )

    const [storeLocationDetails, setStoreLocationDetails] = useState<
        StoreLocation[]
    >([])

    const [locationMapping, setLocationMapping] = useState<LocationMapping>({
        location_id: 0,
        location: '',
        location_name: '',
        entitled_for_non_store: false,
    })

    const userProviderContextValue = {
        location,
        setLocation,
        greenfieldGroups,
        setGreenfieldGroups,
        preferences,
        setPreferences,
        userAgent,
        setUserAgent,
        storeLocationDetails,
        setStoreLocationDetails,
        locationMapping,
        setLocationMapping,
    }

    return (
        <UserContext.Provider value={userProviderContextValue}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => {
    const userContext = useContext(UserContext)

    if (userContext === undefined) {
        throw new Error('useUser must be used within a UserProvider')
    }

    return userContext
}
