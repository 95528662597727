import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { Layout } from 'components/layout'
import { useEssentials } from 'hooks/utils/useEssentials'
import { useState } from 'react'
import { clearLocationParam } from 'utils/helpers'
import { isDistrict } from 'utils/location'
import { Recall } from './recall/recall'
import { Constants } from 'constants/constants'
import { TRANSLATION_KEYS } from 'locales/translation-keys'

export const OperationsPage = () => {
    const { t, params, setParams } = useEssentials()
    const { operationalMetrics } = TRANSLATION_KEYS
    const locationParams = params.get('locationId')

    window.onpopstate = (e) => {
        clearLocationParam({ params, setParams })
    }

    return (
        <Layout
            heading={t(operationalMetrics.title)}
            className="hc-pa-none"
            breadcrumbsEnabled={false}
        >
            <Operations key={locationParams} />
        </Layout>
    )
}

export const Operations = () => {
    const { t, currentLocation, greenfieldGroups } = useEssentials()
    const { operationalMetrics } = TRANSLATION_KEYS
    const { recall } = operationalMetrics
    const { tabs: tabsKeys } = recall
    const hasRecallAccess =
        greenfieldGroups?.some((group) =>
            [Constants.GREENFIELD_GROUPS.RECALL].includes(group),
        ) ?? false
    const commonTabs = [t(tabsKeys.operations)]
    const tabs =
        isDistrict(currentLocation) && hasRecallAccess
            ? [...commonTabs, t(tabsKeys.recall)]
            : [...commonTabs]

    const [activeTab, setActiveTab] = useState<string>(tabs[0])

    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)
    return (
        <Tabs
            activeTab={activeTab}
            className="tabs hc-pv-dense"
            justified
            disableAdaptive={true}
            onTabSelect={handleTabSelect}
        >
            {tabs.map((tab: string) => (
                <Tabs.Item className="hc-bg-tabsItem" key={tab} name={tab}>
                    {t(tab)}
                </Tabs.Item>
            ))}
            <Tabs.Content className="tabsContent" name={tabs[0]}>
                {activeTab === tabs[0] && <h1>{'operations tab'}</h1>}
            </Tabs.Content>
            <Tabs.Content className="tabsContent" name={tabs[1]}>
                {activeTab === tabs[1] && <Recall />}
            </Tabs.Content>
        </Tabs>
    )
}
