import { useEssentials } from 'hooks/utils/useEssentials'
import { TargetCircleCardNonStore } from './target-circle-card-nonstore'
import { isStore } from 'utils/location'
import { TargetCircleCardStore } from './target-circle-card-store'

export const TargetCircleCard = () => {
    const { currentLocation } = useEssentials()

    if (isStore(currentLocation)) {
        return <TargetCircleCardStore />
    }

    return <TargetCircleCardNonStore />
}
