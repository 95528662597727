import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { Layout } from 'components/layout'
import { Location } from './location'
import { Workgroup } from './workgroup'
import { Overtime } from './overtime/overtime'
import { clearLocationParam } from 'utils/helpers'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/error-fallback'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { isStore } from 'utils/location'
import { useEssentials } from 'hooks/utils/useEssentials'

export const PayrollPage = () => {
    const { params, setParams, currentLocation } = useEssentials()

    window.onpopstate = (e) => {
        clearLocationParam({ params, setParams })
    }

    return <Payroll key={currentLocation} />
}

const Payroll = () => {
    const { t, currentLocation } = useEssentials()
    const { payroll } = TRANSLATION_KEYS
    const { tabs: tabsKeys } = payroll
    const commonTabs = [tabsKeys.workgroup, tabsKeys.overtime]
    const tabs = isStore(currentLocation)
        ? [tabsKeys.monthly, ...commonTabs]
        : [tabsKeys.location, ...commonTabs]
    const [activeTab, setActiveTab] = useState<string>(tabs[0])

    const handleTabSelect = (_event: Event, tab: { name: string }) =>
        setActiveTab(tab.name)

    return (
        <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                    error={error}
                    resetErrorBoundary={resetErrorBoundary}
                    heading={t(payroll.title)}
                />
            )}
        >
            <Layout heading={t(payroll.title)} className="hc-pa-none">
                <Tabs
                    activeTab={activeTab}
                    className="tabs hc-pv-dense"
                    justified
                    disableAdaptive={true}
                    onTabSelect={handleTabSelect}
                >
                    {tabs.map((tab: string) => (
                        <Tabs.Item
                            className="hc-bg-tabsItem"
                            key={tab}
                            name={tab}
                        >
                            {t(tab)}
                        </Tabs.Item>
                    ))}
                    <Tabs.Content className="tabsContent" name={tabs[0]}>
                        {activeTab === tabs[0] && <Location />}
                    </Tabs.Content>
                    <Tabs.Content className="tabsContent" name={tabs[1]}>
                        {activeTab === tabs[1] && <Workgroup />}
                    </Tabs.Content>
                    <Tabs.Content className="tabsContent" name={tabs[2]}>
                        {activeTab === tabs[2] && <Overtime />}
                    </Tabs.Content>
                </Tabs>
            </Layout>
        </ErrorBoundary>
    )
}
