import {
    ExpandableSection,
    Grid,
    Timeline,
} from '@enterprise-ui/canvas-ui-react'
import { useStoreTrailerEta } from 'hooks/trailer/useStoreTrailerEta'
import { useEssentials } from 'hooks/utils/useEssentials'
import { format, parseISO } from 'date-fns'
import { ErrorAlert } from 'components/error-fallback'
import { BasicPlaceholder } from 'components/placeholder'
import useCountdown from 'hooks/utils/useCountdown'
import { useEffect } from 'react'

export const TrailerEta = (moveId: any) => {
    const { env, session, logout } = useEssentials()

    const { data, isLoading, isError, error, refetch, remove } =
        useStoreTrailerEta({
            env,
            accessToken: session?.accessToken ?? '',
            filters: {
                move_id: moveId.moveId,
            },
        })

    useEffect(() => {
        remove()
        void refetch()
    }, [moveId, remove, refetch])

    const count = useCountdown(5, logout, error as Error)

    const getHeaderInfo = () => {
        const hasDeliveryInfo = data?.delivery_window_range_from
        const status = data?.status_message

        if (hasDeliveryInfo) {
            return (
                `${status} ${formatDate(data.delivery_window_range_from)} - ` +
                `${formatDate(data.delivery_window_range_to)}`
            )
        }
        return `${status} ${formatDate(data.status_timestamp)}`
    }

    const getStatus = (status: string) => {
        return status ? 'success' : 'default'
    }

    const formatDate = (date: string) => {
        return date ? format(parseISO(date), 'MMM d, h:mm a') : ''
    }

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    return (
        <ExpandableSection startExpanded={false} preserveDom padding="dense">
            <strong>{getHeaderInfo()}</strong>
            <ExpandableSection.Content>
                <Timeline>
                    {data?.events.map((item: any) => (
                        <Timeline.Item
                            key={item.timestamp}
                            title={item.description}
                            type={getStatus(item.event_timestamp)}
                        >
                            {item.event_description}
                            {item.event_timestamp &&
                                ` |  ${formatDate(item.event_timestamp).toLowerCase()}`}
                        </Timeline.Item>
                    ))}
                </Timeline>
                <Grid.Item xs={12} className="hc-mt-sm">
                    <strong>{'From'}</strong>
                </Grid.Item>
                <Grid.Item xs={12}>{data?.origin || ''}</Grid.Item>
            </ExpandableSection.Content>
        </ExpandableSection>
    )
}
