import { Alert } from '@enterprise-ui/canvas-ui-react'
import React, { ReactNode } from 'react'
import { messages } from './messages'

interface ErrorAlertProps {
    error?: Error | null
    count?: number
    children?: ReactNode
    className?: string
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({
    error,
    count,
    children,
    className,
}) => {
    const isSessionExpired =
        error instanceof Error && error.message.includes('401')

    if (isSessionExpired) {
        return (
            <Alert type="error" heading={error.message} className={className}>
                <span>{messages.expiredSession(count)}</span>
                {children}
            </Alert>
        )
    }

    return (
        <Alert
            type="error"
            heading={messages.default.heading}
            className={className}
        >
            <span>{messages.default.text}</span>
            {children}
        </Alert>
    )
}
