import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchLocationHierarchy } from 'queries/locationHierarchy/locationHierarchy'
import { LocationHierarchyFetchProps } from 'types/api'
import { ILocationHierarchyResponse } from 'utils/api'
import { TIME } from 'utils/misc'
import { useAnalytics } from '@praxis/component-analytics'

export function useLocationHierarchy({
    env,
    accessToken,
    location,
    enabled,
}: LocationHierarchyFetchProps): ILocationHierarchyResponse {
    const { trackEvent } = useAnalytics()
    const { tenMinutes } = TIME
    const { data, isLoading, isError, refetch, remove, error } = useQuery(
        [QueryKeys.LOCATION_HIERARCHY, location],
        () =>
            fetchLocationHierarchy({
                env,
                accessToken,
                location,
                trackEvent,
            }),
        {
            enabled,
            staleTime: tenMinutes,
            cacheTime: tenMinutes,
        },
    )

    return { data, isLoading, isError, refetch, remove, error }
}
