import { useEssentials } from 'hooks/utils/useEssentials'
import { useTrailerSchedule } from 'hooks/trailer/useTrailerSchedule'
import useCountdown from 'hooks/utils/useCountdown'
import useRefresh from 'hooks/utils/useRefresh'
import {
    GeneralizedComponent,
    GeneralizedComponentPayloadType,
} from 'components/generalized-component/generalized-component'
import { useState, useCallback, useEffect } from 'react'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { buildTrailerScheduleData } from '../helpers'
import { ErrorAlert } from 'components/error-fallback'
import { BasicPlaceholder } from 'components/placeholder'
import { Container } from 'components/container/Container'
import '../trailer.css'

export const TrailerScheduleTab = () => {
    const { currentLocation, env, t, logout, session } = useEssentials()
    const todayFilter = new Date()
    const { trailer } = TRANSLATION_KEYS
    const [payload, setPayload] = useState<GeneralizedComponentPayloadType>()
    const [timePeriod, setTimePeriod] = useState<string>('current_week')
    const [translatedTimePeriod, setTranslatedTimePeriod] = useState(
        t(trailer.schedule.timePeriods.currentWeek),
    )
    const [timePeriodIndex, setTimePeriodIndex] = useState<number>(0)
    const handleIndexUpdate = useCallback((index: number) => {
        const timePeriods = [
            'current_week',
            'last_week',
            'next_week',
            'three_weeks_out',
        ]
        setTimePeriod(timePeriods[index])
    }, [])
    const { data, isLoading, isError, refetch, remove, error } =
        useTrailerSchedule({
            env,
            accessToken: session?.accessToken ?? '',
            location: currentLocation,
            filters: {
                location_id: currentLocation,
                date: todayFilter.toISOString().split('T')[0],
            },
        })
    useRefresh(refetch, remove)
    const count = useCountdown(5, logout, error as Error)
    useEffect(() => {
        if (timePeriodIndex !== undefined) {
            const getTimePeriodValue = (index: number) => {
                const keys = Object.keys(trailer.schedule.timePeriods)
                return trailer.schedule.timePeriods[
                    keys[index] as keyof typeof trailer.schedule.timePeriods
                ]
            }
            setTranslatedTimePeriod(getTimePeriodValue(timePeriodIndex))
        }

        void refetch()
    }, [timePeriodIndex, trailer, refetch])

    useEffect(() => {
        if (data) {
            const generalizedComponentData = buildTrailerScheduleData(data)
            setPayload(generalizedComponentData)
        }
    }, [data, timePeriod])

    useEffect(() => {
        if (!isLoading && payload) {
            const observer = new MutationObserver(() => {
                let index = 0
                const rows = document.querySelectorAll('tbody tr')
                rows.forEach((row) => {
                    row.classList.remove('highlight-row', 'row')
                })
                document
                    .querySelectorAll('tbody td:nth-child(2)')
                    .forEach((item) => {
                        if (item.textContent === '1st') {
                            index++
                        }
                        if (item.parentNode) {
                            ;(item.parentNode as HTMLElement).classList.add(
                                index % 2 === 0 ? 'highlight-row' : 'row',
                            )
                        }
                    })
            })

            const tableBody = document.querySelector('tbody')
            if (tableBody) {
                observer.observe(tableBody, {
                    childList: true,
                    subtree: true,
                    characterData: true,
                })
            }

            return () => {
                observer.disconnect()
            }
        }
    }, [isLoading, payload])

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
                <BasicPlaceholder type="table" rows={8} columns={3} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    if (!payload || data === undefined) {
        return (
            <div
                className="placeholder-container"
                data-testid="placeholder-grid"
            >
                <BasicPlaceholder type="table" rows={5} />
            </div>
        )
    }

    return (
        <Container className="hc-pa-none" role="main">
            <GeneralizedComponent
                payload={payload}
                isLoading={isLoading}
                scrollableWidth={false}
                widths={[100, 90, 75, 75, 75]}
                expandededWidth={true}
                onIndexUpdate={handleIndexUpdate}
                parentHandleClick={(timePeriodIndex: number) => {
                    setTimePeriodIndex(timePeriodIndex)
                }}
                isError={isError}
                title={t(translatedTimePeriod)}
                titleClassName={'hc-fs-lg'}
                alternateRowColor={false}
            />
        </Container>
    )
}
