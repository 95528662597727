import { Cell, TableData } from 'utils/data'
import { isDistrict, isGroup, isRGD, isRegion, isStore } from 'utils/location'

export const getDynamicSubPath = (location: string, prefix: boolean = true) => {
    if (!isRGD(location)) {
        return ''
    }

    if (prefix) {
        if (isRegion(location)) {
            return `REGION/${location}`
        } else if (isGroup(location)) {
            return `GROUP/${location}`
        } else if (isDistrict(location)) {
            return `DISTRICT/${location}`
        }
    } else {
        return location
    }

    return ''
}

export const setFirstColumnInHeaders = (data: TableData, label: string) => {
    return data?.headings?.map((heading: Cell, index: number) => {
        if (index === 0) {
            heading.value = label
        }

        return heading
    })
}

export const addHyperLinkToFirstColumn = (
    data: TableData,
    pathname: string,
) => {
    if (data) {
        const { rows } = data
        if (rows) {
            data.rows = [...rows]?.map((row: Cell[], index: number) => {
                const locationCell = row[0]
                if (index > 0) {
                    locationCell.url =
                        pathname + '?locationId=' + (locationCell.value || '')
                }
                return row
            })
        }
    }
    return data
}

export const getLocationFilters = (location: string) => {
    if (isStore(location)) {
        return {
            filter_dimension: 'store',
            filter_value: location,
        }
    }

    return undefined
}
