import { Grid } from '@enterprise-ui/canvas-ui-react'
import { BasicPlaceholder } from 'components/placeholder'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { useEssentials } from 'hooks/utils/useEssentials'
import { Cell, formatNegative, getNegativeStyle } from 'utils/data'

interface IGridData {
    isLoading: boolean
    data: Cell[]
}

export const GridData = ({ isLoading, data }: IGridData) => {
    const { t } = useEssentials()
    const { payroll } = TRANSLATION_KEYS
    const { location } = payroll

    if (isLoading) {
        return (
            <div
                className="placeholder-container"
                data-testid="placeholder-grid"
            >
                <BasicPlaceholder type="text" rows={3} />
            </div>
        )
    }

    const [projectedFlexHoursVal, guaranteedFlexHoursVal, plannedFlexHoursVal] =
        data
    return (
        <>
            <Grid.Item xs={12} className="grid-item hc-pv-normal">
                <p className="hc-fs-sm hc-ma-none">
                    {t(location.projFlexHours)}
                </p>
                <p className={getNegativeStyle(projectedFlexHoursVal?.value)}>
                    {formatNegative(projectedFlexHoursVal?.value)}
                </p>
            </Grid.Item>
            <Grid.Item xs={12} className="grid-item hc-pb-normal">
                <p className="hc-fs-sm hc-ma-none">
                    {t(location.guranteedFlexHours)}
                </p>
                <p className={getNegativeStyle(guaranteedFlexHoursVal?.value)}>
                    {formatNegative(guaranteedFlexHoursVal?.value)}
                </p>
            </Grid.Item>
            <Grid.Item xs={12} className="grid-item hc-pb-expanded">
                <p className="hc-fs-sm hc-ma-none">
                    {t(location.bpFlextoplan)}
                </p>
                <p className={getNegativeStyle(plannedFlexHoursVal?.value)}>
                    {formatNegative(plannedFlexHoursVal?.value)}
                </p>
            </Grid.Item>
        </>
    )
}
