import { TableData } from 'utils/data'
import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'

export const fetchPayrollWorkgroupData = fetchGeneralData<TableData>(
    `${ApiSubUrls[Apis.AGGREGATIONS]}/payroll/workgroup`,
)
export const fetchPayrollWorkgroupDrillData = fetchGeneralData<TableData>(
    `${ApiSubUrls[Apis.AGGREGATIONS]}/payroll/workgroup`,
)
