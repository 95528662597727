import { RouteObject } from 'react-router-dom'
import { TrailerPage } from './trailer'
import { StoreTrailerDetails } from './store-trailer-details/store-trailer-details'

export const TrailerRoute: RouteObject = {
    path: '/trailer',
    element: <TrailerPage />,
}

export const TrailerDetailRoute: RouteObject = {
    path: '/trailer/detail',
    element: <StoreTrailerDetails />,
}
