import { useState } from 'react'
import { GeneralizedComponent } from 'components/generalized-component/generalized-component'
import { useTargetCircleCard } from 'hooks/loyalty/useTargetCircleCard'
import { useEssentials } from 'hooks/utils/useEssentials'
import {
    buildTableDataForTargetCircleCardStore,
    pickColumns,
    renameTargetCircleCardStoreColumns,
} from './helpers'
import { BasicPlaceholder } from 'components/placeholder'
import { ErrorAlert } from 'components/error-fallback'
import useCountdown from 'hooks/utils/useCountdown'
import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Bar,
    Tooltip,
    Cell,
} from 'recharts'
import { format, parseISO } from 'date-fns'
import { TRANSLATION_KEYS } from 'locales/translation-keys'

export const TargetCircleCardStore = () => {
    const [timePeriodIndex, setTimePeriodIndex] = useState<number>(0)
    const { t, currentLocation, logout, env, session } = useEssentials()
    const { trailer } = TRANSLATION_KEYS
    const { details } = trailer
    const { data, isLoading, isError, error } = useTargetCircleCard({
        env,
        accessToken: session?.accessToken ?? '',
        location: currentLocation,
    })
    const count = useCountdown(5, logout, error as Error)

    if (isLoading) {
        return (
            <div className="placeholder-container" data-testid="placeholder">
                <BasicPlaceholder type="text" rows={1} />
                <BasicPlaceholder type="table" rows={8} columns={3} />
            </div>
        )
    }

    if (isError || !data) {
        return <ErrorAlert error={error as Error} count={count} />
    }

    const { recentData, archiveData } = data
    const { current_week, previous_week } = recentData
    const { month_to_date, year_to_date } = archiveData

    const currentWeekErrorState = !current_week.metrics.all.goal
    const previousWeekErrorState = !previous_week.metrics.all.goal
    const isDataErrorState =
        (timePeriodIndex === 0 && currentWeekErrorState) ||
        (timePeriodIndex === 1 && previousWeekErrorState)

    let currentWeek = buildTableDataForTargetCircleCardStore(
        current_week.metrics,
    )
    let previousWeek = buildTableDataForTargetCircleCardStore(
        previous_week.metrics,
    )
    let monthToDateData = buildTableDataForTargetCircleCardStore(
        month_to_date.metrics,
    )
    let yearToDateData = buildTableDataForTargetCircleCardStore(
        year_to_date.metrics,
    )

    currentWeek = renameTargetCircleCardStoreColumns(
        currentWeek,
        currentLocation,
    )
    previousWeek = renameTargetCircleCardStoreColumns(
        previousWeek,
        currentLocation,
    )
    monthToDateData = renameTargetCircleCardStoreColumns(
        monthToDateData,
        currentLocation,
    )
    yearToDateData = renameTargetCircleCardStoreColumns(
        yearToDateData,
        currentLocation,
    )

    const dataByTimePeriod = [
        { title: '', data: currentWeek },
        { title: '', data: previousWeek },
        { title: '', data: monthToDateData },
        { title: '', data: yearToDateData },
    ]

    const viewInPercent = dataByTimePeriod.map(({ title, data }) => ({
        title,
        subtitle: '',
        data: {
            summary: pickColumns(data, [0, 1, 2, 3]),
        },
    }))
    const payload = {
        'view in %': viewInPercent,
    }

    const currentTimePeriodChartData = [
        current_week,
        previous_week,
        month_to_date,
        year_to_date,
    ][timePeriodIndex]
    const chartData = [
        {
            name: currentLocation,
            signups: currentTimePeriodChartData.metrics.all.variance_percentage,
        },
        {
            name: 'In Store',
            signups:
                currentTimePeriodChartData.metrics.store.variance_percentage,
        },
        {
            name: 'Online',
            signups:
                currentTimePeriodChartData.metrics.digital.variance_percentage,
        },
    ]
    const timePeriods = [
        'current week',
        'last week',
        'month to date',
        'year to date',
    ]
    const currentTimePeriod = timePeriods[timePeriodIndex]

    let lastApplication = ''
    if (timePeriodIndex === 0 && !isDataErrorState) {
        lastApplication = format(
            parseISO(current_week.last_updated),
            'MMM d, h:mma',
        )
    }

    return (
        <Grid.Container>
            <Grid.Item xs={12}>
                <GeneralizedComponent
                    payload={payload}
                    isLoading={isLoading}
                    scrollableWidth={false}
                    widths={[90, 90, 90, 100]}
                    expandededWidth={false}
                    parentHandleClick={(timePeriodIndex: number) => {
                        setTimePeriodIndex(timePeriodIndex)
                    }}
                    columnSize={12}
                    isError={isDataErrorState}
                    title={currentTimePeriod}
                    titleClassName={'hc-fs-lg flex-sb gc-title'}
                    subTitle={
                        !isDataErrorState && lastApplication
                            ? `${t(details.lastApplication)} ${lastApplication}`
                            : ''
                    }
                >
                    <Card>
                        <ResponsiveContainer
                            width="100%"
                            height={240}
                            className={'hc-mv-lg'}
                        >
                            <BarChart
                                data={chartData}
                                {...{
                                    overflow: 'visible',
                                    margin: {
                                        left: 20,
                                        right: 40,
                                        top: 40,
                                    },
                                }}
                                barSize={100}
                            >
                                <CartesianGrid />
                                <XAxis dataKey="name" />
                                <YAxis
                                    label={{
                                        value: '% to forecast',
                                        angle: -90,
                                        dx: -30,
                                    }}
                                    tickFormatter={(value) => `${value}%`}
                                />
                                <Tooltip
                                    contentStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                    }}
                                    itemStyle={{ color: 'black' }}
                                    labelStyle={{ color: 'black' }}
                                    formatter={(value, name) => [
                                        `${Number(value).toFixed(2)}%`,
                                        typeof name === 'string'
                                            ? name
                                                  .replace(
                                                      /([a-z])([A-Z])/g,
                                                      '$1 $2',
                                                  )
                                                  .toLowerCase()
                                            : name,
                                    ]}
                                />
                                <Bar dataKey={'signups'} barSize={30}>
                                    {chartData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                entry['signups'] >= 100
                                                    ? '#00a101'
                                                    : '#cc0100'
                                            }
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </GeneralizedComponent>
            </Grid.Item>
        </Grid.Container>
    )
}
