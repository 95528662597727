import {
    isDistrict,
    isGroup,
    isRegion,
    isStore,
    isStoreOrRGD,
} from 'utils/location'

export const getPercentClass = (percent: number) =>
    percent < 0 ? 'hc-clr-error' : 'hc-clr-success'
export const getPercentComplete = (percent: number) =>
    percent < 0 ? 100 + percent : 100

export const formatCurrency = (
    value: number,
    currencyCode = 'USD',
    locale = 'en-US',
) => {
    let formattedValue

    if (value >= 1_000_000_000) {
        formattedValue = '$' + (value / 1_000_000_000).toFixed(2) + 'B'
    } else if (value >= 1_000_000) {
        formattedValue = '$' + (value / 1_000_000).toFixed(2) + 'M'
    } else if (value >= 1_000) {
        formattedValue = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value)
    } else {
        formattedValue = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value)
    }

    return formattedValue
}

export const formatTransactions = (value: number, locale = 'en-US') => {
    let formattedValue

    if (value >= 1_000_000_000) {
        formattedValue = (value / 1_000_000_000).toFixed(2) + 'B'
    } else if (value >= 1_000_000) {
        formattedValue = (value / 1_000_000).toFixed(2) + 'M'
    } else {
        formattedValue = new Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value)
    }

    return formattedValue
}

export const formatPercent = (value: number) => {
    if (typeof value !== 'number') return value
    let formattedValue = '' + value.toFixed(2)
    if (formattedValue.includes('-')) {
        return `(${formattedValue.slice(1)}%)`
    }
    return formattedValue + '%'
}

export const formatPercentWithColor = (value: number, color: string) => {
    let formattedValue = '' + value.toFixed(2)
    if (color === '#cc0000') {
        return `(${formattedValue}%)`
    }
    return formattedValue + '%'
}

export const getRealPercent = (value: number, color: string) => {
    if (color === '#cc0000') {
        return Math.abs(value) * -1
    }
    return value
}

export const getDynamicSubPath = (location: string) => {
    if (isRegion(location)) {
        return 'regions'
    } else if (isGroup(location)) {
        return 'groups'
    } else if (isDistrict(location)) {
        return 'districts'
    } else if (isStore(location)) {
        return `stores`
    }

    return ''
}

export const getLocationFilter = (location: string) => {
    if (!isStoreOrRGD(location)) {
        return null
    }

    const locationObj: { [key: string]: string } = {}
    const locationId = location.slice(1)
    if (isRegion(location)) {
        locationObj['region_id'] = locationId
    } else if (isGroup(location)) {
        locationObj['group_id'] = locationId
    } else if (isDistrict(location)) {
        locationObj['district_id'] = locationId
    } else if (isStore(location)) {
        locationObj['store_id'] = locationId
    }

    return locationObj
}
