import { useEssentials } from 'hooks/utils/useEssentials'
import { TRANSLATION_KEYS } from 'locales/translation-keys'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { buildDataTableObject, Cell } from 'utils/data'
import {
    ITrailer,
    IDetailedCartonCount,
    IWorkAreaRow,
    IWorkAreaToggleProps,
} from './ITrailer'

export const TrailerWorkArea = ({ data }: { data: ITrailer }) => {
    const { t } = useEssentials()
    const {
        trailer: {
            details: { workArea },
        },
    } = TRANSLATION_KEYS
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())
    const { work_area_detail_counts, full_case, repack_case } = data

    const workAreaHeadings = [
        { value: t(workArea.workArea) },
        { value: t(workArea.caseCartons) },
        { value: t(workArea.caseUnit) },
        { value: t(workArea.repackCartons) },
        { value: t(workArea.repackUnits) },
    ]

    const processWorkAreaRows = (
        data: IDetailedCartonCount[] | IWorkAreaRow[],
        parentId: string | null = null,
    ): IWorkAreaRow[] => {
        return data?.flatMap((block) => {
            const {
                name,
                id,
                case_pack_counts,
                repack_counts,
                repack_unit_counts,
                case_pack_units_counts,
                children,
            } = block

            const row: IWorkAreaRow = {
                id,
                parentId,
                name,
                is_link: children.length > 0,
                case_pack_counts: String(case_pack_counts),
                case_pack_units_counts: String(case_pack_units_counts),
                repack_counts: String(repack_counts),
                repack_unit_counts: String(repack_unit_counts),
                children,
            }

            // Process children recursively
            const childRows =
                children?.length > 0
                    ? processWorkAreaRows(children as IWorkAreaRow[], id)
                    : []

            return [row, ...childRows]
        })
    }

    const firstRow = {
        id: '0',
        parentId: null,
        name: t(workArea.total),
        is_link: false,
        case_pack_counts: String(full_case?.cartons),
        case_pack_units_counts: String(full_case?.eaches),
        repack_counts: String(repack_case?.cartons),
        repack_unit_counts: String(repack_case?.eaches),
        children: [],
    }

    const workAreaRows = [
        firstRow,
        ...processWorkAreaRows(work_area_detail_counts),
    ]

    const toggleRow = (cell: IWorkAreaToggleProps) => {
        setExpandedRows((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(cell.id)) {
                newSet.delete(cell.id)
            } else {
                newSet.add(cell.id)
            }
            return newSet
        })
    }

    const getTableDataRows = (
        rows: IWorkAreaRow[],
        parentId: string | null = null,
    ): Cell[][] => {
        return rows
            .filter((row) => row.parentId === parentId)
            .flatMap((row) => {
                const currentRow: Cell[] = [
                    {
                        value: row.name,
                        is_link: row.is_link,
                        id: row.id,
                    },
                    { value: row.case_pack_counts },
                    { value: row.case_pack_units_counts },
                    { value: row.repack_counts },
                    { value: row.repack_unit_counts },
                ]
                let childRows = [] as Cell[][]
                if (row.id) {
                    childRows = expandedRows.has(row.id)
                        ? getTableDataRows(rows, row.id)
                        : []
                }

                return [currentRow, ...childRows]
            })
    }

    return (
        <Table
            data={buildDataTableObject({
                currentData: {
                    headings: workAreaHeadings,
                    rows: getTableDataRows(workAreaRows),
                },
                widths: [130, 80, 80, 80, 80],
                handleDataClick: (data: IWorkAreaToggleProps) => {
                    toggleRow(data)
                },
            })}
            alternateRowColor={true}
        />
    )
}
